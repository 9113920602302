declare global {
  interface Window {
    __settings: {
      authConfig: {
        appScope: string;
        tenantId: string;
        clientId: string;
        redirectUri: string;
      };
      authConfigUSS: {
        appScope: string;
        tenantId: string;
        apiKey: string;
      };
      endpoints: {
        approvals: {
          getApproval: string;
          getApprovalAttachmentUrl: string;
          getApprovalHistory: string;
          getMyApprovals: string;
          submitApprovalResponse: string;
          getRCUpdateApproval: string;
          submitRCUpdateApproval: string;
        };
        batchUpdates: {
          getBatchApproval: string;
          getBatchUpdateSWMetaData: string;
          submitApprovalResponse: string;
          submitForApproval: string;
        };
        masterData: {
          getCountries: string;
          getGeoUnits: string;
          getLanguages: string;
          getOrganizations: string;
          getSubBusinessLines: string;
          getSafetyCategories: string;
          getPlants: string;
          getHazardCategories: string;
          searchActivityWorkflows: string;
          searchCompetencyElements: string;
          searchEquipment: string;
          searchEpicEquipment: string;
          searchBusinessRoles: string;
          searchProductCenters: string;
          searchCustomers: string;
          searchEquivalentSWs: string;
          searchOperatingEnvironments: string;
          searchServiceTypes: string;
          searchTasks: string;
          searchMaterials: string;
          searchMeters: string;
          searchWorkCenters: string;
          searchEvents: string;
          getOperationTree: string;
          getGroups: string;
          getGroupCounter: string;
          getQTracOOS: string;
          getUoms: string;
          getMeters: string;
          getCustomizedEvents: string;
          getConfigParameters: string;
        };
        proxyApprovers: {
          getProxyApprover: string,
          postProxyApprover: string,
          deleteProxyApprover: string,
        },
        sw: {
          archiveSW: string;
          createSW: string;
          createSWDraft: string;
          createSWDraftFromVersion: string;
          createLCL: string;
          deleteSWDraft: string;
          bulkDeleteSWDraft: string;
          generateBlobUri: string;
          getPDFUrl: string;
          downloadZipFile: string;
          archiveRequestData: string;
          getSW: string;
          getSiteLocations: string;
          getTimeRCSW: string;
          getTimeRCProcessingRequestStatus: string;
          searchSW: string;
          searchTIMESW: string;
          getRCGuid: string;
          submitDraftForApproval: string;
          retractSwFromApproval: string;
          updateSW: string;
          resolveStepComment: string;
          unlockSWCurrentEditor: string;
          uploadAttachment: string;
          saveSWComment: string;
          getSWComments: string;
          getSWUserFeedbacks: string;
          saveSWUserFeedbackAcks: string;
          saveSWStepComment: string;
          getSWStepComments: string;
          getAllSWComments: string;
          resolveSWComment: string;
          publishRC: string;
          getRCMapping: string;
          copyRCAttachments: string;
          copySWToRCAttachments: string;
          breakRCLink: string;
          getSwRevisionHistories: string;
          searchTimeImage: string;
          uploadTimeImage: string;
          getBlob: string;
          downloadData: string;
          getTimeMediaFileMapping: string;
          getConfidentialEnabledUsers: string;
        };
        users: {
          createUser: string;
          getAADUsers: string;
          getUsers: string;
          getUser: string;
          updateUser: string;
          getUserInfo: string;
          editUser: string;
          deleteUserRole: string;
          confirmTCC: string;
          getFilteredUsers: string,
        };
        USS: {
          validate: string;
          launch: string;
        };
        ITHelp: {
          portal: string;
        };
        reports: {
          getSwRepoList: string;
          downloadSwRepoPdf: string;
        };
      };
    };
  }
}

const config = window.__settings || {};

validateObjNoPlaceholders(config, 'config');

function validateObjNoPlaceholders(obj: any, keyPath: string) {
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj)
      .forEach(key => {
        validateObjNoPlaceholders(obj[key], keyPath + `.${key}`);
      });
  } else if (obj !== null
    && typeof obj === "string"
    && obj.toString().indexOf('#{') === 0) {
    throw new Error(`The setting named '${keyPath}' in settings.json is a placeholder and must be overwritten by a similar entry in local.settings.json.`);
  }
}

export default config;