import { createReducer } from "@reduxjs/toolkit";
import { ISWFilterData, ISWListState } from "./swListTypes";
import { v4 as uuidv4 } from "uuid";
import {
    setData,
    setSearchText,
    showCreateDraftConfirmation,
    cancelCreateDraft,
    setCreateDraftData,
    showArchiveConfirmation,
    cancelArchive,
    setArchiveData,
    toggleFilterModal,
    applyFilter,
    clearFilter,
    addFilterItem,
    removeFilterItem,
    setStringFilter,
    resetAllExceptFilters,
    getPDF,
    setPDFData,
    showDeleteDraftConfirmation,
    cancelDeleteDraft,
    setDeleteDraftData,
    addFilterItems,
    setSWUserFeedbacks,
    OnChangeSWUserFeedback,
    OnChangeSWUserFeedbackAck,
    setSWUserFeedbackAcks,
    showCreateLCLConfirmation,
    cancelCreateLCL,
    setCreateLCLData,
    setSort,
    setRCWhereUsedItems,
    cancelRCMappingModal,
    addToZipSWList,
    removeFromZipSWList,
    getDownloadZip,
    addDownloadZipData,
    removeDownloadZipData,
    removeArchiveData,
    addArchiveData,
    getArchiveRequest,
    clearDownloadZip,
    clearArchiveRequest,
    clearZipSWList,
    setNewIndex,
    setBulkDeleteDraftData,
    setTimeRCRequestData,
    clearTimeRCRequestData,
    removeTimeRCRequestData,
    clearList,
    setRCType,
} from "./swListActions";
import { cloneDeep } from "lodash";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { SWSortFields, SWTypes } from "interfaces/sw/SWInterfaces";
import { SortDirection } from "interfaces/misc/miscInterfaces";

const initialState: ISWListState = {
    swListData: {
        swList: [],
        loadOperation: undefined,
        totalCount: 0,
        skipIndex: 0,
    },
    swListForZip: {
        swListZip: [],
    },
    getDownloadZip: {
        swListZip: [],
        requestID: "",
    },
    setDownloadZipData: [],
    timeRCRequestData: [],
    getArchiveRequest: {
        swListZip: [],
        requestID: "",
    },
    setArchiveRequestData: [],
    createDraftData: {
        guid: "",
        version: -1,
        duplicate: false,
        isWaitingForConfirm: false,
        createDraftOperation: undefined,
        thumbsDownCount: 0,
        thumbsUpCount: 0,
        swType: SWTypes.Unspecified,
        newOwningOrgId: undefined,
        newOwningPlantId: undefined,
    },
    createLCLData: {
        guid: "",
        version: -1,
        isWaitingForConfirm: false,
        createLCLOperation: undefined,
    },
    archiveData: {
        guid: "",
        isWaitingForConfirm: false,
        archiveOperation: undefined,
    },
    rcMappingData: {
        rcMapping: [],
        loadRCMappingData: undefined,
    },
    filterData: {
        isModalOpen: false,
        filterFields: {
            searchText: "",
            typesFilter: [],
            globalOrLocalTypesFilter: [],
            alignmentsFilter: [],
            languagesFilter: [],
            equivalentSWsFilter: [],
            tasksFilter: [],
            activityWorkflowsFilter: [],
            owningOrgsFilter: [],
            performingOrgsFilter: [],
            subBusinessLinesFilter: [],
            serviceTypesFilter: [],
            operatingEnvironmentsFilter: [],
            equipmentFilter: [],
            countriesFilter: [],
            competencyElementsFilter: [],
            safetyCategoriesFilter: [],
            maintenanceTaskIdsFilter: "",
            businessRolesFilter: [],
            epicEquipmentFilter: [],
            productCentersFilter: [],
            geoUnitsFilter: [],
            customersFilter: [],
            feedbackTypesFilter: [],
            owningPlantsFilter: [],
            performingPlantsFilter: [],
            materialsFilter: [],
            workCentersFilter: [],
            hazardCategoriesFilter: [],
            groupsFilter: [],
            groupCounterFilter: [],
            currentOwningPlantsFilter: []
            
        },
        sortBy: SWSortFields.ModifiedOn,
        sortDir: SortDirection.Desc,
    },
    getPDFData: {
        pdfUrl: "",
        getUrlOperation: undefined,
    },
    deleteDraftData: {
        guid: "",
        isWaitingForConfirm: false,
        deleteOperation: undefined,
        currentEditor: undefined,
        currentEditorLockedDate: undefined,
    },
    bulkDeleteDraftData: {
        bulkDeleteOperation: undefined,
    },
    userFeedbacksData: {
        loadUserFeedbacksOperation: undefined,
        swUserFeedbackDetails: {
            title: undefined,
            swGuid: undefined,
            currentVersion: undefined,
            userFeedbacks: [],
            userFeedbacksExport: [],
        },
    },
    userFeedbacksAckData: {
        swUserFeedbackAcks: undefined,
        saveUserFeedbacksOperation: undefined
    }
};

const swListReducer = createReducer(initialState, (builder) => {
    // Reset
    builder.addCase(clearList, state => {
        state.swListData = initialState.swListData;
    })
    builder.addCase(resetAllExceptFilters, state => {
        var filters = state.filterData;
        var setDownloadZipDatas = state.setDownloadZipData;
        var setArchiveRequestDatas = state.setArchiveRequestData;
        var swListForZipDatas = state.swListForZip;
        var timeRCRequestData = state.timeRCRequestData;
        Object.assign(state, cloneDeep(initialState));
        state.filterData = filters;
        state.setDownloadZipData = setDownloadZipDatas;
        state.setArchiveRequestData = setArchiveRequestDatas;
        state.swListForZip = swListForZipDatas;
        state.timeRCRequestData = timeRCRequestData;
    });

    //sort
    builder.addCase(setSort, (state, action) => {
        state.filterData.sortBy = action.payload.sortBy;
        state.filterData.sortDir = action.payload.sortDir;
    });

    // Set List Data
    builder.addCase(setData, (state, action) => {
        state.swListData = action.payload;
    });

    // Set SW List Search Text
    builder.addCase(setSearchText, (state, action) => {
        state.filterData.filterFields.searchText = action.payload;
        state.swListData.skipIndex = initialState.swListData.skipIndex;
    });

    //Set New Index for Loading more data
    builder.addCase(setNewIndex, (state, action) => {
        state.swListData.skipIndex = action.payload;
    });

    // Show CreateDraft Confirmation
    builder.addCase(showCreateDraftConfirmation, (state, action) => {
        state.createDraftData = {
            ...action.payload,
            isWaitingForConfirm: true,
        };
    });

    // set RC Mapping data
    builder.addCase(setRCWhereUsedItems, (state, action) => {
        state.rcMappingData = action.payload;
    })

    // Cancel CreateDraft
    builder.addCase(cancelCreateDraft, (state) => {
        state.createDraftData = Object.assign({}, initialState.createDraftData);
    });

    // Set CreateDraft Data
    builder.addCase(setCreateDraftData, (state, action) => {
        state.createDraftData = {
            ...action.payload,
        };
    });

    // Show CreateLCL Confirmation
    builder.addCase(showCreateLCLConfirmation, (state, action) => {
        state.createLCLData = {
            isWaitingForConfirm: true,
            guid: action.payload.swGuid,
            version: action.payload.swVersion,
        };
    });

    // Cancel CreateLCL
    builder.addCase(cancelCreateLCL, (state) => {
        state.createLCLData = Object.assign({}, initialState.createLCLData);
    });

    // Set CreateLCL Data
    builder.addCase(setCreateLCLData, (state, action) => {
        state.createLCLData = {
            ...action.payload,
        };
    });

    // Show Archive Confirmation
    builder.addCase(showArchiveConfirmation, (state, action) => {
        state.archiveData = {
            isWaitingForConfirm: true,
            guid: action.payload.swGuid,
        };
    });

    // Cancel Archive
    builder.addCase(cancelArchive, (state) => {
        state.archiveData = Object.assign({}, initialState.archiveData);
    });

    // Cancel RC Mapping Popup
    builder.addCase(cancelRCMappingModal, (state) => {
        state.rcMappingData = Object.assign({}, initialState.rcMappingData);
    })
    // Set Archive Data
    builder.addCase(setArchiveData, (state, action) => {
        state.archiveData = {
            ...action.payload,
        };
    });

    // Set List Data
    builder.addCase(setSWUserFeedbacks, (state, action) => {
        state.userFeedbacksData = action.payload;
    });

    // Set feedback ack Data
    builder.addCase(setSWUserFeedbackAcks, (state, action) => {
        state.userFeedbacksAckData = action.payload;
    });

    // Set acknowledge Data
    builder.addCase(OnChangeSWUserFeedback, (state, action) => {
        var swUserFeedbackDataChanges = action.payload;

        var swUserFeedback = state.userFeedbacksData.swUserFeedbackDetails.userFeedbacks
            .find(r => r.id === swUserFeedbackDataChanges.id);

        if (swUserFeedback) {
            swUserFeedback.response = swUserFeedbackDataChanges.response;
            swUserFeedback.isUpdated = true;
        }
    });

    // Set acknowledge Data
    builder.addCase(OnChangeSWUserFeedbackAck, (state, action) => {
        var swUserFeedbackDataChanges = action.payload;

        var swUserFeedback = state.userFeedbacksData.swUserFeedbackDetails.userFeedbacks
            .find(r => r.id === swUserFeedbackDataChanges.id);

        if (swUserFeedback) {
            swUserFeedback.acknowledged = swUserFeedbackDataChanges.acknowledge;
            swUserFeedback.isUpdated = swUserFeedbackDataChanges.acknowledge;
            if (!swUserFeedback.acknowledged) {
                swUserFeedback.response = null;
            }
        }
    });

    // Zip PDFs
    builder.addCase(addToZipSWList, (state, action) => {
        const swID = action.payload.swId;
        var swIdExists = state.swListForZip.swListZip.find(x => x.swid === swID);
        if (!swIdExists) {
            state.swListForZip.swListZip.push(
                {
                    swid: swID,
                }
            );
        }
    });

    builder.addCase(removeFromZipSWList, (state, action) => {
        const swID = action.payload.swId;
        var swIdExists = state.swListForZip.swListZip.find(x => x.swid === swID);
        if (swIdExists) {
            state.swListForZip.swListZip =
                state.swListForZip.swListZip
                    .filter(x => x.swid !== swID);
        }
    });

    // Clear ZipSWList
    builder.addCase(clearZipSWList, (state) => {
        state.swListForZip.swListZip = [];
    });

    // Add DownloadZip Request data
    builder.addCase(getDownloadZip, (state, action) => {
        state.getDownloadZip = {
            requestID: uuidv4(),
            swListZip: action.payload,
        }
    });

    // Clear DownloadZip Request data
    builder.addCase(clearDownloadZip, (state) => {
        state.getDownloadZip = {
            swListZip: [],
            requestID: "",
        }
    });

    // Add Zip Download data
    builder.addCase(addDownloadZipData, (state, action) => {
        var requestExists = state.setDownloadZipData.find(x => x.requestID === action.payload.requestID);
        if (!requestExists) {
            state.setDownloadZipData.push({
                requestID: action.payload.requestID,
                swListZip: action.payload.swListZip,
            });
        }
    });

    // Remove Zip Download data
    builder.addCase(removeDownloadZipData, (state, action) => {
        var requestExists = state.setDownloadZipData.find(x => x.requestID === action.payload.requestID);
        if (requestExists) {
            state.setDownloadZipData = state.setDownloadZipData.filter(x => x.requestID !== action.payload.requestID);
        }
    });

    // Add Archive Request data
    builder.addCase(getArchiveRequest, (state, action) => {
        state.getArchiveRequest = {
            requestID: uuidv4(),
            swListZip: action.payload,
        }
    });

    // Clear Archive Request data
    builder.addCase(clearArchiveRequest, (state) => {
        state.getArchiveRequest = {
            requestID: "",
            swListZip: []
        }
    });

    // Add Archive data
    builder.addCase(addArchiveData, (state, action) => {
        var requestExists = state.setArchiveRequestData.find(x => x.requestID === action.payload.requestID);
        if (!requestExists) {
            state.setArchiveRequestData.push({
                requestID: action.payload.requestID,
                swListZip: action.payload.swListZip,
            });
        }
    });

    // Remove Archive data
    builder.addCase(removeArchiveData, (state, action) => {
        var requestExists = state.setArchiveRequestData.find(x => x.requestID === action.payload.requestID);
        if (requestExists) {
            state.setArchiveRequestData = state.setArchiveRequestData.filter(x => x.requestID !== action.payload.requestID);
        }
    });

    // Download Field PDF
    builder.addCase(getPDF, (state) => {
        state.getPDFData = {
            pdfUrl: "",
            getUrlOperation: {
                isWorking: true,
            }
        }
    });

    // Set PDF Download Data
    builder.addCase(setPDFData, (state, action) => {
        state.getPDFData = {
            ...action.payload,
        };
    });

    // Show Delete Confirmation
    builder.addCase(showDeleteDraftConfirmation, (state, action) => {
        state.deleteDraftData = {
            isWaitingForConfirm: true,
            guid: action.payload.swGuid,
            currentEditor: action.payload.currentEditor,
            currentEditorLockedDate: action.payload.currentEditorLockedDate,
        };
    });

    // Cancel Delete
    builder.addCase(cancelDeleteDraft, (state) => {
        state.deleteDraftData = Object.assign({}, initialState.deleteDraftData);
    });

    // Set Delete Data
    builder.addCase(setDeleteDraftData, (state, action) => {
        state.deleteDraftData = {
            ...action.payload,
        };
    });

    // Set Bulk Delete Craft Data
    builder.addCase(setBulkDeleteDraftData, (state, action) => {
        state.bulkDeleteDraftData = { ...action.payload };
    });

    // Toggle Filter Modal Visibility
    builder.addCase(toggleFilterModal, (state, action) => {
        state.filterData.isModalOpen = action.payload;
    });

    // Apply Filter
    builder.addCase(applyFilter, state => {
        state.swListData.skipIndex = initialState.swListData.skipIndex;
        state.filterData.isModalOpen = false;
    });

    // Clear Filter
    builder.addCase(clearFilter, state => {
        state.filterData.filterFields = initialState.filterData.filterFields;
        state.filterData.isModalOpen = false;
        state.swListData.skipIndex = initialState.swListData.skipIndex;
    });

    // Add String Filter Item
    builder.addCase(setStringFilter, (state, action) => {
        switch (action.payload.attributeName) {
            case "maintenanceTaskIds": {
                state.filterData.filterFields.maintenanceTaskIdsFilter = action.payload.value;
                break;
            }
        }
    });

    // Add Filter Item
    builder.addCase(addFilterItem, (state, action) => {
        handleAddFilterItem(state.filterData,
            action.payload.metaDataName,
            action.payload.item);
    });

    // Add Filter Items
    builder.addCase(addFilterItems, (state, action) => {
        action.payload.items.forEach(item => {
            handleAddFilterItem(state.filterData,
                action.payload.metaDataName,
                item);
        });
    });

    // Remove filter item.
    builder.addCase(removeFilterItem, (state, action) => {
        state.swListData.skipIndex = initialState.swListData.skipIndex;
        removeFilterItemHandler(state.filterData,
            action.payload.metaDataName,
            action.payload.item);
    });

    // Set rcType filter variable
    builder.addCase(setRCType, (state, action) => {
        state.filterData.filterFields.rcType = action.payload;
    });

    // Set TIME RC Request Data
    builder.addCase(setTimeRCRequestData, (state, action) => {
        if (action.payload !== null && !state.timeRCRequestData.find(r => r.requestId === action.payload.requestId)) {
            state.timeRCRequestData.push(action.payload);
        }
    });

    // remove TIME RC Request Data
    builder.addCase(removeTimeRCRequestData, (state, action) => {
        let requestExists = state.timeRCRequestData.find(x => x.requestId === action.payload.requestId);
        if (requestExists) {
            state.timeRCRequestData = state.timeRCRequestData.filter(x => x.requestId !== action.payload.requestId);
        }
    });

    // Clear TIME RC Request Data
    builder.addCase(clearTimeRCRequestData, (state, action) => {
        state.timeRCRequestData = [];
    });
});

export default swListReducer;

export function removeFilterItemHandler(filterData: ISWFilterData,
    metaName: string,
    item: IMasterDataItem) {
    switch (metaName) {
        case "activityWorkflows": {
            filterData.filterFields.activityWorkflowsFilter =
                filterData.filterFields.activityWorkflowsFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "competencyElements": {
            filterData.filterFields.competencyElementsFilter =
                filterData.filterFields.competencyElementsFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "countries": {
            filterData.filterFields.countriesFilter =
                filterData.filterFields.countriesFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "equipment": {
            filterData.filterFields.equipmentFilter =
                filterData.filterFields.equipmentFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "equivalentSWs": {
            filterData.filterFields.equivalentSWsFilter =
                filterData.filterFields.equivalentSWsFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "languages": {
            filterData.filterFields.languagesFilter =
                filterData.filterFields.languagesFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "operatingEnvironments": {
            filterData.filterFields.operatingEnvironmentsFilter =
                filterData.filterFields.operatingEnvironmentsFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "owningOrgs": {
            filterData.filterFields.owningOrgsFilter =
                filterData.filterFields.owningOrgsFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "performingOrgs": {
            filterData.filterFields.performingOrgsFilter =
                filterData.filterFields.performingOrgsFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "subBusinessLines": {
            filterData.filterFields.subBusinessLinesFilter =
                filterData.filterFields.subBusinessLinesFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "safetyCategories": {
            filterData.filterFields.safetyCategoriesFilter =
                filterData.filterFields.safetyCategoriesFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "productCenters": {
            filterData.filterFields.productCentersFilter =
                filterData.filterFields.productCentersFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "businessRoles": {
            filterData.filterFields.businessRolesFilter =
                filterData.filterFields.businessRolesFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "epicEquipment": {
            filterData.filterFields.epicEquipmentFilter =
                filterData.filterFields.epicEquipmentFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "geoUnits": {
            filterData.filterFields.geoUnitsFilter =
                filterData.filterFields.geoUnitsFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "customers": {
            filterData.filterFields.customersFilter =
                filterData.filterFields.customersFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "serviceTypes": {
            filterData.filterFields.serviceTypesFilter =
                filterData.filterFields.serviceTypesFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "tasks": {
            filterData.filterFields.tasksFilter =
                filterData.filterFields.tasksFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "taskAlignments": {
            filterData.filterFields.alignmentsFilter =
                filterData.filterFields.alignmentsFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "types": {
            filterData.filterFields.typesFilter =
                filterData.filterFields.typesFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "globalOrLocalTypes": {
            filterData.filterFields.globalOrLocalTypesFilter =
                filterData.filterFields.globalOrLocalTypesFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "feedbackTypes": {
            filterData.filterFields.feedbackTypesFilter =
                filterData.filterFields.feedbackTypesFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "owningPlants": {
            filterData.filterFields.owningPlantsFilter =
                filterData.filterFields.owningPlantsFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "performingPlants": {
            filterData.filterFields.performingPlantsFilter =
                filterData.filterFields.performingPlantsFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "materials": {
            filterData.filterFields.materialsFilter =
                filterData.filterFields.materialsFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "workCenters": {
            filterData.filterFields.workCentersFilter =
                filterData.filterFields.workCentersFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "hazardCategories": {
            filterData.filterFields.hazardCategoriesFilter =
                filterData.filterFields.hazardCategoriesFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "groups": {
            filterData.filterFields.groupsFilter =
                filterData.filterFields.groupsFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
        case "groupCounter": {
            filterData.filterFields.groupCounterFilter =
                filterData.filterFields.groupCounterFilter
                    .filter(x => x.guid !== item.guid);
            break;
        }
    }
}

export function handleAddFilterItem(filterData: ISWFilterData,
    metaDataName: string,
    item: IMasterDataItem) {
    let arr: IMasterDataItem[] = [];

    switch (metaDataName) {
        case "activityWorkflows": {
            arr = filterData.filterFields.activityWorkflowsFilter;
            break;
        }
        case "competencyElements": {
            arr = filterData.filterFields.competencyElementsFilter;
            break;
        }
        case "countries": {
            arr = filterData.filterFields.countriesFilter;
            break;
        }
        case "equipment": {
            arr = filterData.filterFields.equipmentFilter;
            break;
        }
        case "equivalentSWs": {
            arr = filterData.filterFields.equivalentSWsFilter;
            break;
        }
        case "languages": {
            arr = filterData.filterFields.languagesFilter;
            break;
        }
        case "operatingEnvironments": {
            arr = filterData.filterFields.operatingEnvironmentsFilter;
            break;
        }
        case "owningOrgs": {
            arr = filterData.filterFields.owningOrgsFilter;
            break;
        }
        case "performingOrgs": {
            arr = filterData.filterFields.performingOrgsFilter;
            break;
        }
        case "subBusinessLines": {
            arr = filterData.filterFields.subBusinessLinesFilter;
            break;
        }
        case "safetyCategories": {
            arr = filterData.filterFields.safetyCategoriesFilter;
            break;
        }
        case "productCenters": {
            arr = filterData.filterFields.productCentersFilter;
            break;
        }
        case "businessRoles": {
            arr = filterData.filterFields.businessRolesFilter;
            break;
        }
        case "epicEquipment": {
            arr = filterData.filterFields.epicEquipmentFilter;
            break;
        }
        case "geoUnits": {
            arr = filterData.filterFields.geoUnitsFilter;
            break;
        }
        case "customers": {
            arr = filterData.filterFields.customersFilter;
            break;
        }
        case "serviceTypes": {
            arr = filterData.filterFields.serviceTypesFilter;
            break;
        }
        case "tasks": {
            arr = filterData.filterFields.tasksFilter;
            break;
        }
        case "taskAlignments": {
            arr = filterData.filterFields.alignmentsFilter;
            break;
        }
        case "types": {
            arr = filterData.filterFields.typesFilter;
            break;
        }
        case "globalOrLocalTypes": {
            arr = filterData.filterFields.globalOrLocalTypesFilter;
            break;
        }
        case "feedbackTypes": {
            arr = filterData.filterFields.feedbackTypesFilter;
            break;
        }
        case "owningPlants": {
            arr = filterData.filterFields.owningPlantsFilter;
            break;
        }
        case "performingPlants": {
            arr = filterData.filterFields.performingPlantsFilter;
            break;
        }
        case "materials": {
            arr = filterData.filterFields.materialsFilter;
            break;
        }
        case "workCenters": {
            arr = filterData.filterFields.workCentersFilter;
            break;
        }
        case "hazardCategories": {
            arr = filterData.filterFields.hazardCategoriesFilter;
            break;
        }
        case "groups": {
            arr = filterData.filterFields.groupsFilter;
            break;
        }
        case "groupCounter": {
            arr = filterData.filterFields.groupCounterFilter;
            break;
        }
        case "currentOwningPlants": {
            arr = filterData.filterFields.currentOwningPlantsFilter;
            break;
        }
    }

    if (!arr.find(x => x.guid === item.guid)) {
        arr.push({
            ...item,
        });
    }
}