import { formatSWType } from "apis/sw/swFormatters";
import { IConfigParameters, IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { SWTypes } from "interfaces/sw/SWInterfaces";

import {
  IOperationTreeLink,
  IOperationTreeModel,
  IOperationTreeNode,
  IQTracOOS,
} from "store/masterData/masterDataTypes";

export function formatActivityWorkflows(response: any): IMasterDataItem[] {
  if (!response.ActivityWorkFlows.length
    || !Array.isArray(response.ActivityWorkFlows)) {
    return [];
  }

  return response.ActivityWorkFlows.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.ActivityWorkflowId.toString(),
    code: item.Dimensions,
    active: item.Active,
  }));
}

export function formatOOS(response: any): IMasterDataItem[] {
  if (!response
    || !Array.isArray(response)) {
    return [];
  }
  return response.map((item: any): IMasterDataItem => ({
    value: "",
    guid: "",
    code: item,
  }));
}

export function formatCompetencyElements(response: any): IMasterDataItem[] {
  if (!response.CompetencyElements.length
    || !Array.isArray(response.CompetencyElements)) {
    return [];
  }

  return response.CompetencyElements.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.CompetencyElementId.toString(),
    active: item.Active,
  }));
}

export function formatCountries(response: any): IMasterDataItem[] {
  if (!response.Countries.length
    || !Array.isArray(response.Countries)) {
    return [];
  }

  return response.Countries.map((item: any): IMasterDataItem => ({
    value: item.Value,
    code: item.Code,
    guid: item.Guid,
    active: item.Active,
  }));
}

export function formatGeoUnits(response: any): IMasterDataItem[] {
  if (!response.GeoUnits.length
    || !Array.isArray(response.GeoUnits)) {
    return [];
  }

  return response.GeoUnits.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Code,
    active: item.Active,
  }));
}

export function formatEquipment(response: any): IMasterDataItem[] {
  if (!response.Equipment.length
    || !Array.isArray(response.Equipment)) {
    return [];
  }

  return response.Equipment.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Guid,
    active: item.Active,
  }));
}

export function formatEpicEquipment(response: any): IMasterDataItem[] {
  if (!response.EpicEquipment.length
    || !Array.isArray(response.EpicEquipment)) {
    return [];
  }

  return response.EpicEquipment.map((item: any): IMasterDataItem => ({
    value: item.Value,
    code: item.Code,
    guid: item.Id,
    active: item.Active,
  }));
}

export function formatBusinessRoles(response: any): IMasterDataItem[] {
  if (!response.BusinessRoles.length
    || !Array.isArray(response.BusinessRoles)) {
    return [];
  }

  return response.BusinessRoles.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Id,
    active: item.Active,
  }));
}

export function formatSubBusinessLines(response: any): IMasterDataItem[] {
  if (!response.SubBusinessLines.length
    || !Array.isArray(response.SubBusinessLines)) {
    return [];
  }

  return response.SubBusinessLines.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Code,
    code: item.Code,
    active: item.Active,
  }));
}

export function formatProductCenters(response: any): IMasterDataItem[] {
  if (!response.ProductCenters.length
    || !Array.isArray(response.ProductCenters)) {
    return [];
  }

  return response.ProductCenters.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Code,
    active: item.Active,
  }));
}

export function formatCustomers(response: any): IMasterDataItem[] {
  if (!response.Customers.length
    || !Array.isArray(response.Customers)) {
    return [];
  }

  return response.Customers.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Id,
    active: item.Active,
  }));
}

export function formatEquivalentStandardWorks(response: any, SWType: SWTypes): IMasterDataItem[] {
  if (!Array.isArray(response.StandardWorks)) {
    return [];
  }

  let sws: IMasterDataItem[] = response.StandardWorks.map(formatEquivalentStandardWork);

  if (SWType === SWTypes.TLMSWI || SWType === SWTypes.MFGSWI) {
    sws = sws.filter((sw) => (sw.type === SWTypes.TLMSWI || sw.type === SWTypes.MFGSWI));

    sws.push({
      value: "No equivalent available",
      guid: "No equivalent available",
      code: "",
      active: true,
    });
  }

  return sws;
}

export function formatEquivalentStandardWork(item: any): IMasterDataItem {
  return {
    value: item.Title,
    guid: item.Guid,
    code: item.Description,
    active: true,
    type: formatSWType(item.SWType),
  };
}

export function formatLanguages(response: any): IMasterDataItem[] {
  if (!response.Languages.length
    || !Array.isArray(response.Languages)) {
    return [];
  }

  return response.Languages.map(formatLanguage);
}

export function formatLanguage(item: any): IMasterDataItem {
  return {
    value: item.Value,
    guid: item.Guid,
    active: item.Active,
  };
}

export function formatOperatingEnvironments(response: any): IMasterDataItem[] {
  if (!response.OperatingEnvironments.length
    || !Array.isArray(response.OperatingEnvironments)) {
    return [];
  }

  return response.OperatingEnvironments.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Guid,
    active: item.Active,
  }));
}

export function formatOrganizations(response: any): IMasterDataItem[] {
  if (!response.Organizations.length
    || !Array.isArray(response.Organizations)) {
    return [];
  }

  return response.Organizations.map(formatOrganization);
}

export function formatOrganization(item: any): IMasterDataItem {
  return {
    value: item.Value,
    code: item.Code,
    guid: item.Guid,
    active: item.Active,
  };
}

export function formatPlant(item: any): IMasterDataItem {
  return {
    value: item.Value,
    code: item.Code,
    guid: item.Code,
    active: item.Active,
    source: item.Source,
  };
}

export function formatSafetyCategories(response: any): IMasterDataItem[] {
  if (!response.SafetyCategories.length
    || !Array.isArray(response.SafetyCategories)) {
    return [];
  }

  return response.SafetyCategories.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Guid,
    active: item.Active,
  }));
}

export function formatServiceTypes(response: any): IMasterDataItem[] {
  if (!response.ServiceTypes.length
    || !Array.isArray(response.ServiceTypes)) {
    return [];
  }

  return response.ServiceTypes.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Guid,
    active: item.Active,
  }));
}

export function formatTasks(response: any): IMasterDataItem[] {
  if (!response.Tasks.length
    || !Array.isArray(response.Tasks)) {
    return [];
  }

  return response.Tasks.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.TaskId,
    active: item.Active,
  }));
}

export function formatOwningPlants(response: any): IMasterDataItem[] {
  if (!response.Plants.length
    || !Array.isArray(response.Plants)) {
    return [];
  }

  return response.Plants.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Code,
    code: item.Code,
    active: item.Active,
    source: item.Source,
  }));
}

export function formatHazardCategories(response: any): IMasterDataItem[] {
  return response.HazardCategories.map((item: any) => ({
    value: item.Value,
    guid: item.Guid,
    active: item.Active,
  }));
}

export function formatPerformingPlants(response: any): IMasterDataItem[] {
  if (!response.Plants.length
    || !Array.isArray(response.Plants)) {
    return [];
  }

  return response.Plants.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Code,
    code: item.Code,
    active: item.Active,
  }));
}

export function formatMaterials(response: any): IMasterDataItem[] {
  if (!response.Materials.length
    || !Array.isArray(response.Materials)) {
    return [];
  }

  return response.Materials.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Id,
    code: item.Code,
    active: item.Active,
  }));
}

export function formatMeters(response: any): IMasterDataItem[] {
  if (!response.Meters.length
    || !Array.isArray(response.Meters)) {
    return [];
  }

  return response.Meters.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Guid,
    code: item.Code,
    active: item.Active,
  }));
}

export function formatWorkCenters(response: any): IMasterDataItem[] {
  if (!response.WorkCenters.length
    || !Array.isArray(response.WorkCenters)) {
    return [];
  }

  return response.WorkCenters.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Id,
    code: item.Code,
    active: item.Active,
  }));
}

export function formatOperationTree(response: any): IOperationTreeModel {
  return {
    nodes: formatOperationTreeNodes(response.Nodes),
    links: formatOperationTreeLinks(response.Links),
  };
}

export function formatOperationTreeNodes(response: any): IOperationTreeNode[] {
  if (!response.length || !Array.isArray(response)) {
    return [];
  }

  return response.map((item: any) => ({
    materialCode: item.MaterialCode,
    plantCode: item.PlantCode,
    group: item.Group,
    groupCounter: item.GroupCounter,
    sequence: item.Sequence,
    taskListNode: item.TaskListNode,
    startBranchOperation: item.StartBranchOperation,
    returnOperation: item.ReturnOperation,
    operation: item.Operation,
    operationText: item.OperationText,
    text: item.Text,
  }));
}

export function formatOperationTreeLinks(response: any): IOperationTreeLink[] {
  if (!response.length || !Array.isArray(response)) {
    return [];
  }

  return response.map((item: any) => ({
    from: item.From,
    to: item.To,
  }));
}

export function formatGroups(response: any): IMasterDataItem[] {
  if (!response.Groups.length
    || !Array.isArray(response.Groups)) {
    return [];
  }

  return response.Groups.map((item: any): IMasterDataItem => ({
    value: item,
    guid: item,
    code: item,
    active: true,
  }));
}

export function formatGroupCounter(response: any): IMasterDataItem[] {
  if (!response.GroupCounter.length
    || !Array.isArray(response.GroupCounter)) {
    return [];
  }

  return response.GroupCounter.map((item: any): IMasterDataItem => ({
    value: item,
    guid: item,
    code: item,
    active: true,
  }));
}

export function formatGroup(item: any): IMasterDataItem {
  return {
    value: item,
    guid: item,
    code: item,
    active: true,
  };
}

export function formatQTracOOSs(response: any): IMasterDataItem[] {
  if (!response.qTracOOS.length
    || !Array.isArray(response.qTracOOS)) {
    return [];
  }

  return response.qTracOOS.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Code,
    code: item.Code,
    active: item.Active,
  }));
}

export function formatUoms(response: any): IMasterDataItem[] {
  if (!response.Uoms.length
    || !Array.isArray(response.Uoms)) {
    return [];
  }

  return response.Uoms.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Id,
    code: item.Code,
    active: item.Active,
  }));
}


export function formatConfigParameters(response: any): IConfigParameters[] {

  let configParameters: IConfigParameters[] = [];
  response.configParameters.forEach((x: any) => {
    configParameters.push({ key: x.Key, value: x.Value })
  })
  return configParameters;
}

export function formatMetersRequest(equipmentCodes: IMasterDataItem[], location: string): any {
  return {
    EquipmentCodes: equipmentCodes.map(x => x.code),
    Location: location,
  }
}

export function formatMetersResponse(response: any): IMasterDataItem[] {
  if (!response.Meters.length
    || !Array.isArray(response.Meters)) {
    return [];
  }

  return response.Meters.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Guid,
    code: item.Code,
    active: item.Active,
    parentGuid: item.ParentGuid,
  }));
}

export function formatEvents(response: any): IMasterDataItem[] {
  if (!response.Events.length
    || !Array.isArray(response.Events)) {
    return [];
  }

  return response.Events.map((item: any): IMasterDataItem => ({
    value: item.Description,
    guid: item.Code,
    code: item.Code,
    active: true,
  }));
}
