import React, { useEffect, useRef, useState } from "react";
import Modal from "components/common/Modal";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { setAddUserSearchText, toggleAddUserModal, setAddUserModalData, createUser, resetAddUserModalData } from "store/userManagement/userManagementActions";
import BlockSpinner from "components/common/BlockSpinner";
import "./AddUserModal.scoped.scss";
import addIcon from "media/icons/dls/add.svg";
import { IAADUser } from "interfaces/user/UserInterfaces";
import useDebounce from "utilities/useDebounce";

const AddUserModal: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 750);
  const state = useSelector(store => store.userManagement.addUserModalData);
  const dispatch = useDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    dispatch(setAddUserModalData({
      isOpen: true,
      loadOperation: undefined,
      createOperation: undefined,
      searchResults: [],
      searchText: "",
    }));

    return () => {
      dispatch(resetAddUserModalData());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setAddUserSearchText(debouncedSearchTerm.trim()));
  }, [debouncedSearchTerm, dispatch]);

  const addUser = (user: IAADUser) => {
    dispatch(createUser({
      user,
      refreshManageUsersList: true,
    }));
  }

  let results: JSX.Element | undefined;

  if (state.loadOperation?.isWorking
    || state.createOperation?.isWorking) {
    results = <BlockSpinner />;
  } else if (state.loadOperation?.errorMessage) {
    results = <label className="error">{state.loadOperation?.errorMessage}</label>;
  } else if (!state.searchResults.length) {
    results = <label>No users found.</label>
  } else {
    results = (
      <>
        {state.searchResults.map(r =>
          <div
            className="user"
            key={r.email}
          >
            <span>
              {r.name} ({r.email})
            </span>
            <img
              src={addIcon}
              className="add-button icon-medium hover-gray-bg"
              onClick={() => addUser(r)}
              alt="Add"
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <Modal
        isOpen={true}
        header="Add User"
        controls={(
          <>
            <button
              className="primary-button"
              onClick={() => dispatch(toggleAddUserModal(false))}
              disabled={state.loadOperation?.isWorking
                || state.createOperation?.isWorking}
            >
              Done
          </button>
          </>
        )}
      >
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          disabled={state.loadOperation?.isWorking
            || state.createOperation?.isWorking}
          placeholder="Search by name or email..."
          ref={inputRef}
        />
        <div
          className="results"
        >
          {results}
        </div>
      </Modal>
    </>
  );
}

export default AddUserModal;