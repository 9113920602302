import React from "react";
import { ISW, RCTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import MetadataRow from "./MetadataRow";

interface IMetadataProps {
  sw: ISW,
  isRCUpdate: boolean,
}

const Metadata: React.FC<IMetadataProps> = ({ sw, isRCUpdate }) =>
  <>
    <MetadataRow
      visible={!!sw.dataClassification}
      label="Data Classification"
      value={sw.dataClassification}
    />
    <MetadataRow
      visible={!!sw.TLMSWIType}
      label="Global or Local?"
      value={sw.TLMSWIType}
    />
    <MetadataRow
      visible={!!sw.templateType}
      label="Template Type"
      value={sw.templateType}
    />
    <MetadataRow
      visible={!!sw.contentType}
      label="Content Type"
      value={sw.contentType}
    />
    <MetadataRow
      visible={!!sw.language}
      label="Language"
      value={sw.language?.value}
    />
    <MetadataRow
      visible={!!sw.equivalentSW}
      label="Equivalent SW"
      value={sw.equivalentSW
        ? `${sw.equivalentSW.value} - ${sw.equivalentSW.code}`
        : undefined}
    />
    <MetadataRow
      visible={sw.type === SWTypes.TLMSWI && !!sw.introduction}
      label="Introduction"
      value={sw.introduction}
    />
    <MetadataRow
      visible={!!sw.owningOrg}
      label="Owning Organization"
      value={sw.owningOrg
        ? `${sw.owningOrg.code} - ${sw.owningOrg.value}`
        : undefined}
    />
    <MetadataRow
      visible={!!sw.owningPlant}
      label="Owning Plant"
      value={sw.owningPlant
        ? `${sw.owningPlant.code} - ${sw.owningPlant.value}`
        : undefined}
    />
    <MetadataRow
      visible={sw.performingPlants.length > 0}
      label="Performing Plants"
      value={sw.performingPlants.map(x => `${x.code} - ${x.value}`)}
    />
    <MetadataRow
      visible={sw.workCenters.length > 0}
      label="Work Centers"
      value={sw.workCenters.map(x => `${x.code} - ${x.value}`)}
    />
    <MetadataRow
      visible={sw.materials.length > 0}
      label="Materials"
      value={sw.materials.map(x => `${x.code} - ${x.value}`)}
    />
    <MetadataRow
      visible={sw.subBusinessLines.length > 0}
      label="Sub BusinessLines"
      value={sw.subBusinessLines.map(x => `${x.code} - ${x.value}`)}
    />
    <MetadataRow
      visible={sw.performingOrgs.length > 0}
      label="Performing Organizations"
      value={sw.performingOrgs.map(x => `${x.code} - ${x.value}`)}
    />
    <MetadataRow
      visible={(sw.type === SWTypes.TLMSWI || sw.type === SWTypes.TLMRC || sw.type === SWTypes.MFGSWI || sw.type === SWTypes.MFGCL) && sw.productCenters.length > 0}
      label="Product Center"
      value={sw.productCenters.map(x => x.value)}
    />
    <MetadataRow
      visible={sw.type === SWTypes.TLMSWI && sw.geoUnits.length > 0}
      label="GeoUnit"
      value={sw.geoUnits.map(x => x.value)}
    />
    <MetadataRow
      visible={(sw.type === SWTypes.TLMSWI || sw.type === SWTypes.TLMRC || sw.type === SWTypes.MFGSWI || sw.type === SWTypes.MFGCL) && sw.epicEquipment.length > 0}
      label="EPIC Equipment"
      value={sw.epicEquipment.map(x => x.value)}
    />
    <MetadataRow
      visible={sw.type === SWTypes.TLMSWI && sw.events.length > 0}
      label="Event"
      value={sw.events.map(x => `${x.code} - ${x.value}`)}
    />
    <MetadataRow
      visible={sw.type === SWTypes.TLMSWI && !!sw.level}
      label="Level"
      value={sw.level}
    />
    <MetadataRow
      visible={(sw.type === SWTypes.TLMSWI || sw.type === SWTypes.MFGSWI || sw.type === SWTypes.MFGCL) && !!sw.maintenanceTaskIds}
      label="Maintenance Task"
      value={sw.maintenanceTaskIds}
    />
    <MetadataRow
      visible={sw.competencyElements.length > 0}
      label="Competency Elements"
      value={sw.competencyElements.map(x => x.value)}
    />
    <MetadataRow
      visible={sw.countries.length > 0}
      label="Geography: Country"
      value={sw.countries.map(x => x.value)}
    />
    <MetadataRow
      visible={sw.customers.length > 0}
      label="Customer"
      value={sw.customers.map(x => x.value)}
    />
    <MetadataRow
      visible={sw.type === SWTypes.TLMSWI && sw.businessRoles.length > 0}
      label="Business Role"
      value={sw.businessRoles.map(x => x.value)}
    />
    <MetadataRow
      visible={sw.serviceTypes.length > 0}
      label="Service Types"
      value={sw.serviceTypes.map(x => x.value)}
    />
    <MetadataRow
      visible={sw.equipment.length > 0}
      label="Equipment"
      value={sw.equipment.map(x => x.value)}
    />
    <MetadataRow
      visible={sw.operatingEnvironments.length > 0}
      label="Operating Environments"
      value={sw.operatingEnvironments.map(x => x.value)}
    />
    <MetadataRow
      visible={sw.safetyCategories.length > 0}
      label="Safety Categories"
      value={sw.safetyCategories.map(x => x.value)}
    />
    <MetadataRow
      visible={sw.tasks.length > 0}
      label="Task(s)"
      value={sw.tasks.map(x => x.value)}
    />
    <MetadataRow
      visible={sw.type === SWTypes.TLMSWI && !!sw.ceMarking}
      label="CE Marking"
      value={sw.ceMarking}
    />
    <MetadataRow
      visible={sw.type === SWTypes.TLMSWI && !!sw.foreward}
      label="Foreword"
      value={sw.foreward}
    />
    <MetadataRow
      visible={!!sw.versionChanges}
      label="Version Changes"
      value={sw.versionChanges}
    />
    <MetadataRow
      visible={sw.type === SWTypes.TLMSWI && !!sw.harc}
      label="HARC"
      value={sw.harc}
    />
    <MetadataRow
      visible={!!sw.guid}
      label="Guid"
      value={sw.guid}
    />
    <MetadataRow
      visible={!!sw.createdBy}
      label="Author"
      value={sw.createdBy}
    />
    {
      !(sw?.type === SWTypes?.TLMRC
        && sw?.rcType === RCTypes.Notice)
        &&
    <MetadataRow
          visible={!!sw?.hazardCategory}
      label="Hazard Category"
          value={sw?.hazardCategory?.value}
    />
    }

    <MetadataRow
      visible={(sw.type === SWTypes.CL || sw.type === SWTypes.LCL) && !!sw.showCLSignatureBlock}
      label="Show CL SignatureBlock"
      value={sw.showCLSignatureBlock}
    />
    <MetadataRow
      visible={!!sw.group}
      label="Group"
      value={sw.group?.value}
    />
    <MetadataRow
      visible={!!sw.groupCounter}
      label="Group Counter"
      value={sw.groupCounter?.value}
    />
  </>;

export default Metadata;