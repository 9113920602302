import React from "react";
import './ApprovalHistoryList.scss'
import useSelector from "store/useSelector";
import formatDate from "utilities/formatDate";
import { ApprovalLevels, ApprovalResponseTypes, BatchApprovalStatuses, IApprovalWorkFlowResponse, IHistoricalBatchApproval } from "interfaces/approvals/approvalInterfaces";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ApprovalHistoryModal from "./ApprovalHistoryModal";


const ApprovalBatchUpdateHistoryList: React.FC<IHistoricalBatchApproval> = (approval) => {
  const {
    activeApprovalLevel
  } = useSelector(store => store.manageSW.SW)

  var responses = approval.responses
    .slice()
    .sort((a, b) => a.createdOn < b.createdOn ? -1 : 1);

  const lastResponse = responses.length > 0
    ? responses[responses.length - 1]
    : null;

  const historicalResponsesAddList: IApprovalWorkFlowResponse[] = responses.map(response => {
    var optional: boolean = false;
    const level = ApprovalLevels.Author;
    const { responseType, createdBy, createdOn } = response;
    return { responseType, level, optional, createdBy, createdOn };
  });
  
  function combinedApprovalList() {
    const approvalList = historicalResponsesAddList;
    var updatedApprovalList: IApprovalWorkFlowResponse[] = [];
      const activeApproval: IApprovalWorkFlowResponse = {
        responseType: ApprovalResponseTypes.Active,
        level: ApprovalLevels.Author,
        optional: false,
        createdBy: "",
        createdOn: new Date(),
      };
      if (approval.status === BatchApprovalStatuses.Active) {
        approvalList.push(activeApproval);
      }
      updatedApprovalList = approvalList;
    return updatedApprovalList;
  }

  return (
    <div
      className={`approval ${approval.status}`}
    >
      <div
        className="header"
      >
        Submitted for review by {approval.createdBy} on {formatDate(approval.createdOn)}
      </div>
      
      <div className="approvalHistoryContainer">
        {combinedApprovalList().map((data, index) => (
          <div className='history-container'>
            <ApprovalHistoryModal key={index} {...data} />
            {(data.responseType === ApprovalResponseTypes.Approved ||
              data.responseType === ApprovalResponseTypes.AutoApproved ||
              data.responseType === ApprovalResponseTypes.Rejected
            ) &&
              <>
                  {data.responseType !== ApprovalResponseTypes.AutoApproved &&
                    <div className={`detail-container approval-history-text ${data.responseType}`}>{data.responseType} by ({data.createdBy})</div>
                  }
                  <div className={`date-container approval-history-text ${data.responseType}`}>
                  <CalendarMonthIcon className= {`approval-history-icon ${data.responseType}`} />
                    {formatDate(data.createdOn)}
                </div>
              </>
            }
            {data.responseType === ApprovalResponseTypes.Active &&
              <>
                <div className={`detail-container approval-history-text ${data.responseType}`}>Pending review at {data.level} </div>
              </>
            }
          </div>

        ))}
      </div>
    </div>
  );
}

export default ApprovalBatchUpdateHistoryList;