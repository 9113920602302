import React from 'react';
import './index.scss';
import App from './App';
import AppStartup from './components/startup/AppStartup';
import * as serviceWorker from './serviceWorker';
import { rootReducer } from "store/rootStore";
import rootSaga from "store/rootSaga";
import createSagaMiddleware from "redux-saga";
import { Provider } from 'react-redux';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { authRequest, msalPublicClient } from 'msalConfig';
import { InteractionType } from '@azure/msal-browser';
import { createRoot } from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: ['swList/setData', 'batchUpdates/setData', 'manageSW/finishLoading', 'manageSW/finishUpdating', 'manageSW/setComments'],
        ignoredPaths: ['swList.swListData.swList', 'batchUpdates.swListData.swList', 'manageSW.SW.currentEditorLockedDate', 'manageSW.commentData.comments'],
      },
    }),
    sagaMiddleware,
  ],
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
<Provider store={store}>
    <MsalProvider
      instance={msalPublicClient}
    >
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={(err) => (<p>Failed to authenticate: {err.error?.errorMessage}</p>)}
        loadingComponent={() => (<p>Logging In...</p>)}
      >
        <AppStartup>
          <App />
        </AppStartup>
      </MsalAuthenticationTemplate>
    </MsalProvider>
</Provider>);

serviceWorker.unregister();

export const portalRoot = (document.getElementById("portal") as HTMLElement);