import FlowLayout from "components/layout/FlowLayout";
import React from "react";
import { ManageSwReportsTabs } from "store/ManageSwReports/ManageSwReportsTypes";
import useSelector from "store/useSelector";
import SwReportsToolbar from "./SwReportsToolBar";
import SearchSwRepo from "./pdfs/SearchSwRepo";
import SWSearchPDF from "components/sw/reports/pdfs/SWSearchPDF";

const ManageSwReports: React.FC = () => {
    const {
        manageSwReports: {
            currentTab,
        }
    } = useSelector(store => store);
    let mainComponent: JSX.Element | undefined;

    if (currentTab === ManageSwReportsTabs.FullPdf) {
        mainComponent = (
            <SWSearchPDF />
        );
    }

    if (currentTab === ManageSwReportsTabs.qtracPdf) {
        mainComponent = (
            <SearchSwRepo />
        );
    }

    return (
        <FlowLayout
            header={(
                <>
                    <h2 className="page-title">
                        StandardWork Reports
                    </h2>
                    <SwReportsToolbar />
                </>
            )}
        >
            {mainComponent}
        </FlowLayout>);
}

export default ManageSwReports;