import React, { useEffect, useState } from "react";
import filterIcon from "media/icons/dls/filter.svg";
import searchIcon from "media/icons/dls/search.svg";
import clearFilterIcon from "media/icons/dls/clear-filter.svg";
import downArrowIcon from "media/icons/dls/arrow-down-2.svg";
import "./SearchBar.scoped.scss";
import { ISWFilterFields } from "store/swList/swListTypes";
import FilterModal from "./FilterModal";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { MetaDataNames } from "store/masterData/masterDataTypes";
import useDebounce from "utilities/useDebounce";
import { getSWFilterCount } from "utilities/sw/filterUtils";
import { clearSearchedMasterDataItems } from "store/masterData/masterDataActions";
import { useDispatch } from "react-redux";
import EllipsesDropdown, { IEllipsesDropdownItem } from "./EllipsesDropdown";
import { RCTypes } from "interfaces/sw/SWInterfaces";

export interface ISearchBarProps {
  extraFilterControls?: JSX.Element,
  extraFilterCount: number,
  swFilterFields: ISWFilterFields,
  setSearchText(value: string): void,
  onAddSWItem(item: IMasterDataItem, name: MetaDataNames): void,
  onRemoveSWItem(item: IMasterDataItem, name: MetaDataNames): void,
  applyFilter(): void,
  clearFilter(): void,
  setSWStringFilter(name: string, value: string): void,
  isDisabled: boolean,
  showStatusFilter?: boolean,
  placeholder?: string,
  downloadZipFile?(): void,
  getArchiveRequest?(): void,
  bulkDelete?(): void,
  showZipArchiveButton?: boolean,
  setRCType(rcType: RCTypes | undefined): void
}

const SearchBar: React.FC<ISearchBarProps> = (props) => {
  const {
    setSearchText,
    swFilterFields,
    extraFilterCount,
    setRCType,
  } = props;
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState(swFilterFields.searchText);
  const debouncedSearchTerm = useDebounce(searchTerm, 750);

  const clearSearchFilter = () => {
    props.clearFilter();
    if (searchTerm === "") {
      props.applyFilter();
    }
    else {
      setSearchTerm("");
    }
  }

  useEffect(() => {
    setSearchText(debouncedSearchTerm.trim());
  }, [debouncedSearchTerm, setSearchText]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const numFilters = getSWFilterCount(swFilterFields, extraFilterCount);
  const setIsModelOpenOnClick = () => {
    setIsModalOpen(true);
    dispatch(clearSearchedMasterDataItems());
  }

  let itemList: (IEllipsesDropdownItem | undefined)[] = [
    {
      key: "Zip PDFs",
      label: "Zip PDFs",
      icon: "",
      onClick: props.downloadZipFile,
    },
    {
      key: "Archive",
      label: "Archive",
      icon: "",
      onClick: props.getArchiveRequest,
    },
    {
      key: "Bulk Delete",
      label: "Bulk Delete",
      icon: "",
      onClick: props.bulkDelete,
    },
  ];

  return (
    <>
      <div className="search-bar">
        <div className="input-holder">
          <input
            type="text"
            id="sw-search"
            placeholder={props.placeholder || "Begin typing to search..."}
            disabled={props.isDisabled}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <img
            src={searchIcon}
            alt="Search"
            className="icon-small"
          />
        </div>
        {props.showZipArchiveButton &&
          <div className="zip-button">
            <EllipsesDropdown
              label="Zip/Archive/Delete"
              icon={downArrowIcon}
              itemTextAlign="left"
              items={itemList}
            />
          </div>
        }
        {numFilters > 0 &&
          <img
            src={clearFilterIcon}
            alt="Clear Filters"
            className="icon-medium filter-button"
            title="Clear Filters"
            onClick={() => {
              setIsModalOpen(false);
              props.clearFilter();
              props.applyFilter();
            }}
          />
        }
        <span
          onClick={props.isDisabled
            ? undefined
            : setIsModelOpenOnClick
          }
        >
          <img
            src={filterIcon}
            alt="Filters"
            className="icon-medium filter-button"
            title="Filters"
          />
          {numFilters > 0 &&
            <label className="filter-count-label">
              {numFilters}
            </label>
          }
        </span>
      </div>
      {isModalOpen &&
        <FilterModal
          extraFilterControls={props.extraFilterControls}
          currentSWFilterValues={swFilterFields}
          onAddItem={props.onAddSWItem}
          onRemoveItem={props.onRemoveSWItem}
          applyFilter={props.applyFilter}
          clearFilter={clearSearchFilter}
          setSWStringFilter={props.setSWStringFilter}
          setIsModalOpen={setIsModalOpen}
          showStatusFilter={props.showStatusFilter}
          setRCType={setRCType}
        />
      }
    </>
  );
}


export default SearchBar;