import React, { useEffect, useState } from "react";
import SWTypeIcon from "components/sw/search/SWTypeIcon";
import AddIcon from "media/icons/dls/add.svg";
import { ISWSummaryItem, RCTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import "./RCListTime.scoped.scss";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";

interface IRCListTime {
    swList: ISWSummaryItem[],
    AddRCTimeStepsToSw(guid: string, version: number, source: string, rcType: string): void,
    rcTypeButton: string
  }
  
const RCListTime : React.FC<IRCListTime> = (props) =>{
  const {
    isDirty,
} = useSelector(store => store.manageSW);
  const dispatch = useDispatch();
  const TimeSystem: string = "TIME";
  
  const isNotDirtySW = (swType: string) =>{
    if(isDirty)
    {
      dispatch(showErrorToast(`Please save the ${swType} first before starting this operation.`));
      return false;
    }
    return true;
  }
  return(
    <div>       
        <div className="row header">
          <div className="cell sw-name">
            Content Name
          </div>
          <div className="cell width20">
            UID 
          </div>
          <div className="cell width10">
            Version
          </div>
          <div className="cell buttons">
          </div>
          <div className="cell buttons">
          </div>
        </div>
        <div className="list-body">
          {
            props.swList.filter(x => x.type === SWTypes.TLMRC).map((row, index) => (
              <div className="sw-header" key={index}>
                <div id={"testttchc" + index} className="cell sw-name" key={"swname" + index}>
                  <div className="title-holder" key={"tholder" + index}>
                    <SWTypeIcon type={row.type} key={"swtype" + index} />
                    <div key={"swtitle" + index} className="sw-title">
                      {row.title}
                      <span key={"desc" + index} className="description">
                        {row.description}
                      </span>
                    </div>
                  </div>
                </div>
                <div id={row.guid} className="cell width20">
                  {row.guid}</div>
                {row.sourceVersion ?
                <div id={row.sourceVersion.toString() + index} className="cell width10">{row.sourceVersion}</div>: null}
                <div className="cell buttons icon-small">
                    <a
                    href={row.sourceXml}
                    rel="noopener noreferrer"
                    className="icon-small"
                    target="_blank"
                    >
                      XML
                    </a>
                </div>
                <div className="cell buttons icon-small">
                  <img
                    src={AddIcon}
                    alt="Add to SW"
                    title="Add to SW"
                    className="icon-small"
                    onClick={() => isNotDirtySW(row.type) && props.AddRCTimeStepsToSw(row.guid, row.version, TimeSystem, props.rcTypeButton)}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>);

}

export default RCListTime;