import {
  IUploadSWAttachmentResponse,
  ISW,
  SWTypes,
  TaskAlignments,
  INoticeComponent,
  NoticeTypes,
  IReferenceDoc,
  StepComponentTypes,
  IStep,
  ImageAlignments,
  ISWSummaryItem,
  SWSortFields,
  IUnlockSWCurrentEditor,
  StepComponentType,
  IComment,
  IStepComment,
  IStepsCommentInfo,
  ISWUserFeedback,
  ISWUserFeedbackDetails,
  IAllSWComments,
  ISWUserFeedbackExport,
  IGetRCMapping,
  ISWRevisionHistory,
  IArchiveResponse,
  IGetRCSW,
  ImageScale,
  IGetTimeMediaFileMapping,
  ITimeRCProcessing,
  ITimeRCProcessingRequestsStatus,
  RCTypes,
  IUserApprovalResponseHistory,
  StepTypes,
  ISiteLocationsData,
  ISiteLocation
} from "interfaces/sw/SWInterfaces";
import {
  formatLanguage,
  formatOrganization,
  formatTasks,
  formatActivityWorkflows,
  formatOrganizations,
  formatServiceTypes,
  formatOperatingEnvironments,
  formatEquipment,
  formatCountries,
  formatCompetencyElements,
  formatSafetyCategories,
  formatEquivalentStandardWork,
  formatEpicEquipment,
  formatBusinessRoles,
  formatProductCenters,
  formatGeoUnits,
  formatCustomers,
  formatSubBusinessLines,
  formatPlant,
  formatPerformingPlants,
  formatWorkCenters,
  formatMaterials,
  formatGroup,
  formatEvents,
  formatOOS
} from "apis/masterData/masterDataFormatters";
import { ISWFilterFields, IZipList, ISWListData, ITimeRCRequestData } from "store/swList/swListTypes";
import { formatApprovalLevel } from "apis/approval/approvalFormatters";
import { SortDirection } from "interfaces/misc/miscInterfaces";
import { cloneDeep } from "lodash";
import { ITimeImageData } from "store/manageSW/manageSWTypes";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { findNoticeImageType } from "utilities/fileUtilities";
import { IConfidentialSWUser } from "interfaces/user/UserInterfaces";


export function formatComponentRequest(comp: StepComponentType): any {
  let req: any = {
    Id: comp.id,
    Guid: comp.guid,
    ImmutableGuid: comp.immutableGuid,
    Type: comp.type,
    Label: comp.label,
    SortOrder: comp.sortOrder,
  };

  if (comp.type === StepComponentTypes.Notice) {
    req.RCID = comp.rcID;
    req.IsRCComponent = comp.isRCComponent;
    req.IsDisabled = comp.isDisabled;

    req.Data = JSON.stringify({
      type: comp.noticeType.toString(),
      filename: comp.image.filename,
      imageType: findNoticeImageType(comp.image.filename),
      hazardCategory : (comp.hazardCategory)
        ? {
          guid: comp.hazardCategory.guid,
          value: comp.hazardCategory.value
        }
        : null,
    });
  } else if (comp.type === StepComponentTypes.NumberInput) {
    req.Data = JSON.stringify(
      {
        uom: comp.uom || "",
        meters: (comp.meterCode)
          ? {
            Id: comp.meterID,
            Code: comp.meterCode,
          }
          : null,
        lowerLimit: comp.lowerLimit,
        expectedValue: comp.expectedValue,
        upperLimit: comp.upperLimit,
        nonConform: comp.nonConform || "",
        uomCode: comp.uomCode || "",
      });
  } else if (comp.type === StepComponentTypes.YesNoInput) {
    req.Data = JSON.stringify({
      expectedValue: comp.expectedValue,
      nonConform: comp.nonConform,
    })
  } else if (comp.type === StepComponentTypes.PassFailInput) {
    req.Data = JSON.stringify({
      meters: comp.meterCode
        ? {
          Id: comp.meterId,
          Code: comp.meterCode,
        }
        : null,
      expectedValue: comp.expectedValue,
      nonConform: comp.nonConform,
    })
  } else if (comp.type === StepComponentTypes.SelectInput) {
    req.Data = JSON.stringify(
      {
        options: comp.options,
        expectedValue: comp.expectedValue,
        nonConform: comp.nonConform,
        meters: (comp.meterCode)
          ? {
            Id: comp.meterId,
            Code: comp.meterCode,
          }
          : null,
      });
  } else if (comp.type === StepComponentTypes.MultiSelectInput) {
    req.Data = JSON.stringify(
      {
        options: comp.options,
        expectedValue: comp.expectedValue,
        nonConform: comp.nonConform,
        meters: (comp.meterCode)
          ? {
            Id: comp.meterId,
            Code: comp.meterCode,
          }
          : null,
      });
  } else if (comp.type === StepComponentTypes.Image || comp.type === StepComponentTypes.TimeImage) {
    req.Data = JSON.stringify({
      alignment: comp.alignment.toString(),
      filename: comp.filename,
      scale: comp.scale,
    });
  } else if (comp.type === StepComponentTypes.MicrosoftStreamVideo) {
    req.Data = JSON.stringify({
      videoGuid: comp.videoGuid,
    });
  } else if (comp.type === StepComponentTypes.Link) {
    req.Data = JSON.stringify({
      url: comp.url,
    });
  } else if (comp.type === StepComponentTypes.Table) {
    //rset the alt property to the server filename and src property to empty string.
    const clonedItems = cloneDeep(comp.cells);
    clonedItems.forEach(cell => {
      if (cell.imageFileName) {
        cell.value = cell.value
          .replace(/style=['"](?:[^"'/]*\/)*([^'"]+)['"]/g, "")
          .replace(/src=['"](?:[^"'/]*\/)*([^'"]+)['"]/g, "src=\"\"")
          .replace("alt=\"undefined\"", "alt=\"" + cell.imageFileName + "\"");
        cell.imageFileName = undefined;
      }
    });

    req.Data = JSON.stringify({
      rowCount: comp.rowCount,
      colCount: comp.colCount,
      cells: clonedItems,
      //html: formatTableHtml(comp),
    });
  } else if (comp.type === StepComponentTypes.PPE) {
    req.Data = JSON.stringify({ ppe: comp.ppe });
  } else if (comp.type === StepComponentTypes.Video) {
    req.Data = JSON.stringify({
      filename: comp.filename,
    });
  } else if (comp.type === StepComponentTypes.Audio) {
    req.Data = JSON.stringify({
      filename: comp.filename,
    });
  }
  else if (comp.type === StepComponentTypes.RichTextParagraph) {
    req.Data = JSON.stringify({
      html: comp.html,
    });
  }
  else if (comp.type === StepComponentTypes.QualityControlPoint) {
    req.Data = JSON.stringify({
      stopType: comp.stopType,
    });
  }
  else if (comp.type === StepComponentTypes.RoutingOperation) {
    req.Data = JSON.stringify({
      operation: comp.operation,
    });
  } else if (comp.type === StepComponentTypes.FormulaNumerical) {
    req.Data = JSON.stringify({
      uom: comp.uom || "",
      meters: comp.meterCode
        ? {
          Id: comp.meterId,
          Code: comp.meterCode,
        }
        : null,
      lowerLimit: comp.lowerLimit,
      upperLimit: comp.upperLimit,
      nonConform: comp.nonConform || "",
      uomCode: comp.uomCode || "",
      formula: comp.formula || "",
    });
  } else if (comp.type === StepComponentTypes.TextInput || comp.type === StepComponentTypes.DateInput) {
    req.Data = JSON.stringify({
      meters: comp.meterCode
        ? {
          Id: comp.meterId,
          Code: comp.meterCode,
        }
        : null,
    });
  }

  return req;
}

export function formatComponentResponse(comp: any): StepComponentType {

  const type = formatComponentType(comp.Type);

  let data: any;
  try {
    data = comp.Data
      ? JSON.parse(comp.Data)
      : undefined;
  } catch {
    throw new Error(`Failed to parse component data for component ${comp.Id}`);
  }

  if (type === StepComponentTypes.Notice) {
    let hazardCategory: IMasterDataItem = {
      guid: data?.hazardCategory?.guid,
      value: data?.hazardCategory?.value,
      active: data?.hazardCategory?.isActive,
    };

    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      label: comp.Label ? comp.Label : undefined,
      sortOrder: comp.SortOrder,
      noticeType: formatNoticeType(data?.type),
      image: {
        filename: data?.filename || "",
        blobData: undefined,
        imageType: findNoticeImageType(data?.filename),
      },
      isRCComponent: comp.IsRcComponent,
      rcTitle: comp.RCTitle,
      rcID: comp.RCID,
      isDisabled: comp.IsDisabled,
      hazardCategory: data?.hazardCategory?.guid ? hazardCategory : undefined,
    };
  } else if (type === StepComponentTypes.NumberInput) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      label: comp.Label ? comp.Label : undefined,
      sortOrder: comp.SortOrder,
      uom: data?.uom,
      meterID: data?.meters?.Id,
      meterCode: data?.meters?.Code,
      lowerLimit: data?.lowerLimit,
      expectedValue: data?.expectedValue,
      upperLimit: data?.upperLimit,
      nonConform: data?.nonConform,
      uomCode: data?.uomCode,
    };
  } else if (type === StepComponentTypes.SelectInput) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      label: comp.Label ? comp.Label : undefined,
      sortOrder: comp.SortOrder,
      options: data?.options || [],
      expectedValue: data?.expectedValue,
      nonConform: data?.nonConform,
      meterId: data?.meters?.Id,
      meterCode: data?.meters?.Code,
    };
  } else if (type === StepComponentTypes.MultiSelectInput) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      label: comp.Label ? comp.Label : undefined,
      sortOrder: comp.SortOrder,
      options: data?.options || [],
      expectedValue: data?.expectedValue,
      nonConform: data?.nonConform,
      meterId: data?.meters?.Id,
      meterCode: data?.meters?.Code,
    };
  } else if (type === StepComponentTypes.YesNoInput) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      label: comp.Label ? comp.Label : undefined,
      sortOrder: comp.SortOrder,
      expectedValue: data?.expectedValue,
      nonConform: data?.nonConform,
    };
  } else if (type === StepComponentTypes.PassFailInput) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      label: comp.Label ? comp.Label : undefined,
      sortOrder: comp.SortOrder,
      expectedValue: data?.expectedValue,
      nonConform: data?.nonConform,
      meterId: data?.meters?.Id,
      meterCode: data?.meters?.Code,
    };
  } else if (type === StepComponentTypes.Image || type === StepComponentTypes.TimeImage) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      label: comp.Label ? comp.Label : undefined,
      sortOrder: comp.SortOrder,
      alignment: formatImageAlignment(data?.alignment),
      filename: data?.filename || "",
      scale: formatImageScale(data.scale),
    };
  } else if (type === StepComponentTypes.MicrosoftStreamVideo) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      label: comp.Label ? comp.Label : undefined,
      sortOrder: comp.SortOrder,
      videoGuid: data?.videoGuid || "",
    };
  } else if (type === StepComponentTypes.Link) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      label: comp.Label ? comp.Label : undefined,
      sortOrder: comp.SortOrder,
      url: data?.url || "",
    };
  } else if (type === StepComponentTypes.Table) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      sortOrder: comp.SortOrder,
      rowCount: data?.rowCount,
      colCount: data?.colCount,
      cells: data?.cells,
      label: comp.Label,
    };
  } else if (type === StepComponentTypes.PPE) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      sortOrder: comp.SortOrder,
      ppe: data?.ppe,
    };
  }
  else if (type === StepComponentTypes.Video) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      sortOrder: comp.SortOrder,
      filename: data?.filename || "",
      blobData: undefined,
      label: comp.Label ? comp.Label : undefined,
    };
  }
  else if (type === StepComponentTypes.Audio) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      sortOrder: comp.SortOrder,
      filename: data?.filename || "",
      blobData: undefined,
      label: comp.Label ? comp.Label : undefined,
    };
  }
  else if (type === StepComponentTypes.RichTextParagraph) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      sortOrder: comp.SortOrder,
      html: data?.html,
    };
  }
  else if (type === StepComponentTypes.QualityControlPoint) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      sortOrder: comp.SortOrder,
      stopType: data?.stopType || "",
      label: comp.Label ? comp.Label : undefined,
    };
  } else if (type === StepComponentTypes.RoutingOperation) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      sortOrder: comp.SortOrder,
      operation: data?.operation,
    }
  } else if (type === StepComponentTypes.FormulaNumerical) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      label: comp.Label ? comp.Label : undefined,
      sortOrder: comp.SortOrder,
      uom: data?.uom,
      meterId: data?.meters?.Id,
      meterCode: data?.meters?.Code,
      lowerLimit: data?.lowerLimit,
      upperLimit: data?.upperLimit,
      nonConform: data?.nonConform,
      uomCode: data?.uomCode,
      formula: data?.formula,
    };
  } else if (type === StepComponentTypes.TextInput || type === StepComponentTypes.DateInput) {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      label: comp.Label ? comp.Label : undefined,
      sortOrder: comp.SortOrder,
      meterId: data?.meters?.Id,
      meterCode: data?.meters?.Code,
    };
  }
  else {
    return {
      id: comp.Id,
      guid: comp.Guid,
      immutableGuid: comp.ImmutableGuid,
      type,
      label: comp.Label ? comp.Label : undefined,
      sortOrder: comp.SortOrder,
    };
  }
}

export function formatComponentType(type: any): StepComponentTypes {
  if (type === "NumberInput") {
    return StepComponentTypes.NumberInput;
  } else if (type === "TextInput") {
    return StepComponentTypes.TextInput;
  } else if (type === "SelectInput") {
    return StepComponentTypes.SelectInput;
  } else if (type === "MultiSelectInput") {
    return StepComponentTypes.MultiSelectInput;
  } else if (type === "DateInput") {
    return StepComponentTypes.DateInput;
  } else if (type === "DateTimeInput") {
    return StepComponentTypes.DateTimeInput;
  } else if (type === "PhotoInput") {
    return StepComponentTypes.PhotoInput;
  } else if (type === "SignatureInput") {
    return StepComponentTypes.SignatureInput;
  } else if (type === "YesNoInput") {
    return StepComponentTypes.YesNoInput;
  } else if (type === "PassFailInput") {
    return StepComponentTypes.PassFailInput;
  } else if (type === "Image") {
    return StepComponentTypes.Image;
  } else if (type === "TimeImage") {
    return StepComponentTypes.TimeImage;
  } else if (type === "Notice") {
    return StepComponentTypes.Notice;
  } else if (type === "MicrosoftStreamVideo") {
    return StepComponentTypes.MicrosoftStreamVideo;
  } else if (type === "Link") {
    return StepComponentTypes.Link;
  } else if (type === "Table") {
    return StepComponentTypes.Table;
  } else if (type === "PPE") {
    return StepComponentTypes.PPE;
  } else if (type === "Video") {
    return StepComponentTypes.Video;
  } else if (type === "Audio") {
    return StepComponentTypes.Audio;
  } else if (type === "RichTextParagraph") {
    return StepComponentTypes.RichTextParagraph;
  } else if (type === "QualityControlPoint") {
    return StepComponentTypes.QualityControlPoint;
  } else if (type === "VideoInput") {
    return StepComponentTypes.VideoInput;
  } else if (type === "AudioInput") {
    return StepComponentTypes.AudioInput;
  } else if (type === "RoutingOperation") {
    return StepComponentTypes.RoutingOperation;
  } else if (type === "FormulaNumerical") {
    return StepComponentTypes.FormulaNumerical;
  } else {
    throw new Error("Invalid Step Component Type: " + type);
  }
}

export function formatCreateSWRequest(sw: ISW): any {
  return {
    Type: sw.type,
    Title: sw.title,
    Description: sw.description,
    TaskAlignment: sw.taskAlignment,
    LanguageId: sw.language?.guid,
    EquivalentSWId: sw.equivalentSW?.guid,
    OwningOrgId: sw.owningOrg?.guid,
    OwningPlantId: sw.owningPlant?.guid,
    Tasks: sw.tasks.map(x => x.guid),
    ActivityWorkFlows: sw.activityWorkflows.map(x => x.guid),
    PerformingOrgs: sw.performingOrgs.map(x => x.guid),
    ServiceTypes: sw.serviceTypes.map(x => x.guid),
    OperatingEnvironments: sw.operatingEnvironments.map(x => x.guid),
    Equipment: sw.equipment.map(x => x.guid),
    EpicEquipment: sw.epicEquipment.map(x => x.guid),
    BusinessRoles: sw.businessRoles.map(x => x.guid),
    ProductCenters: sw.productCenters.map(x => x.guid),
    SubBusinessLines: sw.subBusinessLines.map(x => x.guid),
    Customers: sw.customers.map(x => x.guid),
    GeoUnits: sw.geoUnits.map(x => x.guid),
    Countries: sw.countries.map(x => x.guid),
    CompetencyElements: sw.competencyElements.map(x => parseInt(x.guid, 10)),
    SafetyCategoryGuids: sw.safetyCategories.map(x => x.guid),
    GlobalOrLocal: sw.TLMSWIType,
    DataClassification: sw.dataClassification,
    TCC: sw.tcc,
    Introduction: sw.introduction,
    MaintenanceTaskIds: sw.maintenanceTaskIds,
    CEMarking: sw.ceMarking,
    Foreward: sw.foreward,
    VersionChanges: sw.versionChanges,
    HARC: sw.harc,
    PerformingPlants: sw.performingPlants.map(x => x.code),
    WorkCenters: sw.workCenters.map(x => x.guid),
    Materials: sw.materials.map(x => x.guid),
    ShowCLSignatureBlock: (sw.type === SWTypes.CL && (!sw.showCLSignatureBlock || sw.showCLSignatureBlock === undefined)) ? "No" : sw.showCLSignatureBlock,
    TemplateType: sw.templateType,
    ContentType: sw.contentType,
    Group: sw.group?.guid,
    GroupCounter: sw.groupCounter?.guid,
    Events: sw.events.map(x => x.code),
    Level: sw.level,
    PlantSource: sw.plantSource,
  };
}

export function formatTIMESearchSWRequest(searchTerm: string): any {
  return {
    SearchTerm: searchTerm
  }
}

export function formatTIMEImageSearchRequest(searchTerm: string): any {
  return {
    SearchTerm: searchTerm
  }
}

export function formatSearchSWRequest(filterFields: ISWFilterFields,
  excludeDrafts: boolean,
  limitResults: boolean,
  sortBy: SWSortFields,
  sortDir: SortDirection,
  newIndex: number,
  isSearchforRC: boolean,
  isSearchforRCNotice: boolean, 
  searchSource?: string): any {
  let mtTaskIds = (filterFields.maintenanceTaskIdsFilter
    && filterFields.maintenanceTaskIdsFilter?.trim() !== "") ?
    filterFields.maintenanceTaskIdsFilter?.split(",") : [];
  if (mtTaskIds.length > 0) {
    mtTaskIds = mtTaskIds.map(r => r.trim());
  }
  return {
    FilterOptions: {
      SearchTerm: filterFields.searchText,
      Types: filterFields.typesFilter.map(x => x.guid),
      GlobalOrLocalTypes: filterFields.globalOrLocalTypesFilter.map(x => x.guid),
      TaskAlignments: filterFields.alignmentsFilter.map(x => x.guid),
      LanguageIds: filterFields.languagesFilter.map(x => x.guid),
      EquivalentSWIds: filterFields.equivalentSWsFilter.map(x => x.guid),
      OwningOrgIds: filterFields.owningOrgsFilter.map(x => x.guid),
      TaskIds: filterFields.tasksFilter.map(x => x.guid),
      ActivityWorkFlowIds: filterFields.activityWorkflowsFilter.map(x => x.guid),
      PerformingOrgIds: filterFields.performingOrgsFilter.map(x => x.guid),
      SubBusinesslines: filterFields.subBusinessLinesFilter.map(x => x.guid),
      ServiceTypeIds: filterFields.serviceTypesFilter.map(x => x.guid),
      OperatingEnvironmentIds: filterFields.operatingEnvironmentsFilter.map(x => x.guid),
      EquipmentIds: filterFields.equipmentFilter.map(x => x.guid),
      CountryIds: filterFields.countriesFilter.map(x => x.guid),
      CompetencyElementIds: filterFields.competencyElementsFilter.map(x => parseInt(x.guid, 10)),
      SafetyCategoryIds: filterFields.safetyCategoriesFilter.map(x => x.guid),
      BusinessRoleIds: filterFields.businessRolesFilter.map(x => x.guid),
      ProductCenterIds: filterFields.productCentersFilter.map(x => x.guid),
      MaintenanceTaskIds: mtTaskIds,
      FeedbackTypes: filterFields.feedbackTypesFilter.map(x => x.guid),
      EpicEquipmentIds: filterFields.epicEquipmentFilter.map(x => x.guid),
      GeoUnitIds: filterFields.geoUnitsFilter.map(x => x.guid),
      CustomerIds: filterFields.customersFilter.map(x => x.guid),
      OwningPlantIds: filterFields.owningPlantsFilter.map(x => x.guid),
      PerformingPlantIds: filterFields.performingPlantsFilter.map(x => x.guid),
      MaterialIds: filterFields.materialsFilter.map(x => x.guid),
      WorkCenterIds: filterFields.workCentersFilter.map(x => x.guid),
      IsSearchForRc: isSearchforRC,
      IsSearchforRCNotice: isSearchforRCNotice,
      RCType: filterFields.rcType,
      HazardCategoryIds: filterFields.hazardCategoriesFilter.map(x => x.guid),
      Groups: filterFields.groupsFilter.map(x => x.guid),
      GroupCounters: filterFields.groupCounterFilter.map(x => x.guid),
      CurrentOwningPlantIds: filterFields.currentOwningPlantsFilter.map(x => x.guid),
    },
    ExcludeDrafts: excludeDrafts,
    LimitResults: limitResults,
    SortBy: sortBy,
    SortDir: sortDir,
    Index: newIndex,
    SearchSource: searchSource
  };
}
export function formatDownloadZipArchive(swids: IZipList[], requestId: string): any {
  return {
    SWIds: swids.map(x => x.swid),
    RequestId: requestId,
  }
}

export function formatBulkDeleteSWDraft(swIds: IZipList[]) {
  return { SWIDs: swIds.map(x => x.swid) };
}

export function formatArchiveResponse(response: any): IArchiveResponse {
  return {
    ErrorType: response.ErrorType,
    Message: response.Message,
    Success: response.Success,
  }
}

// function formatTableHtml(tableComponent: ITableComponent) : string {
//   let html: string = "<table>";

//   for (let r = 0; r < tableComponent.rowCount; r++) {
//     html = html.concat("<tr>");
//     for (let c = 0; c < tableComponent.colCount; c++) {
//       let cell = tableComponent.cells.filter(x => x.rowIndex === r && x.colIndex === c)[0];
//       html = html.concat("<td>");
//       if (cell !== undefined) {
//         html = html.concat("<p>", cell.value, "</p>");
//       }
//       html = html.concat("</td>");
//     }
//     html = html.concat("</tr>");
//   }

//   return html.concat("</table>");
// }

function formatImageAlignment(align: any): ImageAlignments {
  if (align?.toLowerCase() === ImageAlignments.Bottom.toLowerCase()) {
    return ImageAlignments.Bottom;
  } else if (align?.toLowerCase() === ImageAlignments.Right.toLowerCase()) {
    return ImageAlignments.Right;
  } else { // if no valid alignnent specified default to bottom.
    return ImageAlignments.Bottom;
  }
}

function formatImageScale(scale: any): ImageScale | undefined {
  if (scale?.toLowerCase() === ImageScale.Small.toLowerCase()) {
    return ImageScale.Small;
  } else if (scale?.toLowerCase() === ImageScale.Medium.toLowerCase()) {
    return ImageScale.Medium;
  } else if (scale?.toLowerCase() === ImageScale.Large.toLowerCase()) {
    return ImageScale.Large;
  } else { // if no valid scale specified default to undefined.
    return undefined;
  }
}

function formatNotice(notice: any): INoticeComponent {
  let data: any;
  try {
    data = notice.Data
      ? JSON.parse(notice.Data)
      : undefined;
  } catch {
    throw new Error(`Failed to parse notice ${notice.Id}`);
  }
  let hazardCategory: IMasterDataItem = {
    guid: data?.hazardCategory?.guid,
    value: data?.hazardCategory?.value,
    active: data?.hazardCategory?.isActive,
  };

  return {
    type: StepComponentTypes.Notice,
    noticeType: formatNoticeType(notice.Type),
    guid: notice.Guid,
    id: parseInt(notice.Id, 10),
    label: notice.Text,
    sortOrder: notice.SortOrder !== undefined
      ? notice.SortOrder
      : 0,
    image: {
      filename: notice.Filename,
      blobData: undefined,
      imageType: findNoticeImageType(notice.Filename),
    },
    isRCComponent: notice.IsRcComponent,
    rcTitle: notice.RCTitle,
    rcID: notice.RCID,
    isDisabled: notice.IsDisabled,
    hazardCategory: data?.hazardCategory?.guid ? hazardCategory : undefined,
  };
}

function formatNoticeType(noticeType: any): NoticeTypes {
  if (noticeType === NoticeTypes.Caution) {
    return NoticeTypes.Caution;
  } else if (noticeType === NoticeTypes.Info) {
    return NoticeTypes.Info;
  } else if (noticeType === NoticeTypes.Warning) {
    return NoticeTypes.Warning;
  }

  throw new Error("Invalid Notice Type: " + noticeType);
}

function formatReferenceFile(refFile: any): IReferenceDoc {
  let refDocData: IReferenceDoc;
  refDocData = {
    id: refFile.Id,
    guid: refFile.Guid,
    label: refFile.Label,
    refDocData: refFile.RefDocData,
    sortOrder: refFile.SortOrder,
    type: refFile.Type,
    lcl: refFile.LCL,
  };

  if (refDocData.type === 'Table') {
    refDocData.tableData = JSON.parse(refDocData.refDocData);
  }

  return refDocData;
}

function formatRefDocRequest(refDoc: IReferenceDoc): any {
  if (refDoc.type === 'File' || refDoc.type === 'Link') {
    return {
      Id: refDoc.id,
      Guid: refDoc.guid,
      Label: refDoc.label,
      RefDocData: refDoc.refDocData,
      SortOrder: refDoc.sortOrder,
      Type: refDoc.type,
      LCL: refDoc.lcl,
    }
  } else if (refDoc.tableData) {
    const clonedItems = cloneDeep(refDoc.tableData?.cells);

    clonedItems.forEach(cell => {
      if (cell.imageFileName) {
        cell.value = cell.value
          .replace(/style=['"](?:[^"'/]*\/)*([^'"]+)['"]/g, "")
          .replace(/src=['"](?:[^"'/]*\/)*([^'"]+)['"]/g, "src=\"\"")
          .replace("alt=\"undefined\"", "alt=\"" + cell.imageFileName + "\"");
        cell.imageFileName = undefined;
      }
    });

    return {
      Id: refDoc.id,
      Guid: refDoc.guid,
      Label: refDoc.label,
      RefDocData: JSON.stringify({
        rowCount: refDoc.tableData.rowCount,
        colCount: refDoc.tableData.colCount,
        cells: clonedItems,
      }),
      SortOrder: refDoc.sortOrder,
      Type: refDoc.type,
      LCL: refDoc.lcl,
    }
  }
}

function formatStepRequest(step: IStep): any {
  return {
    Id: step.id,
    ParentGuid: step.parentGuid,
    Guid: step.guid,
    ImmutableGuid: step.immutableGuid,
    Title: step.title,
    IsMasterStep: step.isMasterStep,
    MasterStepId: step.masterStepId,
    IsConditional: step.isConditional,
    ConditionalText: step.conditionalText,
    SortOrder: step.sortOrder,
    IsMemoryStep: step.isMemoryStep,
    IsComprehensionReq: step.isComprehensionRequired,
    IsCritComp: step.isCritComp,
    IsCritStep: step.isCritStep,
    IsTask: step.isTask,
    IsRCStep: step.isRCStep,
    RCContainer: step.rcContainer,
    RCID: step.rcID,
    CritCompText: step.critCompText,
    CritCompAnsNeedsImprov: step.critCompAnsNeedsImprov,
    CritCompAnsSufficient: step.critCompAnsSufficient,
    Children: step.children.length
      ? step.children.map(x => formatStepRequest(x))
      : [],
    Components: step.components.map(x => formatComponentRequest(x)),
    IsDisabled: step.isDisabled,
    StepType: step.stepType,
    serviceLevel: step.serviceLevel,
    OOS: step.OOS.map(x => x.code).join(","),
    usage: step.usage,
    SiteLocation: step.siteLocation,
    ClientReport: step.clientReport
  };
}

function formatStepResponse(step: any, parentGuid: string | null, depth: number): IStep {
  return {
    id: step.Id,
    parentGuid,
    depth,
    guid: step.Guid,
    immutableGuid: step.ImmutableGuid,
    title: step.Title,
    isMasterStep: step.IsMasterStep,
    masterStepId: step.MasterStepId,
    isConditional: step.IsConditional,
    conditionalText: step.ConditionalText,
    sortOrder: step.SortOrder,
    isMemoryStep: step.IsMemoryStep,
    isComprehensionRequired: step.IsComprehensionReq,
    isCritComp: step.IsCritComp,
    isCritStep: step.IsCritStep,
    critCompText: step.CritCompText,
    critCompAnsNeedsImprov: step.CritCompAnsNeedsImprov,
    critCompAnsSufficient: step.CritCompAnsSufficient,
    components: step.Components?.length
      ? step.Components.map((comp: any) => formatComponentResponse(comp))
      : [],
    children: step.Children?.length
      ? step.Children.map((child: any) => formatStepResponse(child, step.Guid, depth + 1))
      : [],
    stepComments: step.Comments?.length
      ? step.Comments
      : [],
    hasUnResolvedComments: step.HasUnResolvedComments,
    isTask: step.IsTask,
    isRCStep: step.IsRCStep,
    rcID: step.RCID,
    rcTitle: step.RCTitle,
    isDisabled: step.IsDisabled,
    stepType: formatStepType(step.StepType),
    serviceLevel: step.ServiceLevel,
    usage: step.Usage,
    siteLocation: step.SiteLocation,
    isStale: step.IsStale,
    OOS: (step.OOS) ? formatOOS(step.OOS.split(",")) : [],
    clientReport: step.ClientReport
  };
}

export function formatSWDto(sw: any): ISW {
  return {
    id: sw.Id,
    guid: sw.Guid,
    type: formatSWType(sw.Type),
    version: sw.Version,
    title: sw.Title || "",
    description: sw.Description || "",
    taskAlignment: formatTaskAlignment(sw.TaskAlignment),
    ppe: sw.PPE || [],
    notices: sw.Notices?.length
      ? sw.Notices.map((n: any) => formatNotice(n))
      : [],
    steps: sw.Steps?.length
      ? sw.Steps.map((step: any) => formatStepResponse(step, null, 0))
      : [],
    language: sw.Language
      ? formatLanguage(sw.Language)
      : undefined,
    serverLanguage: sw.Language
      ? formatLanguage(sw.Language)
      : undefined,
    equivalentSW: formatEquivalentSW(sw),
    owningOrg: sw.OwningOrg
      ? formatOrganization(sw.OwningOrg)
      : undefined,
    owningPlant: sw.OwningPlant
      ? formatPlant(sw.OwningPlant)
      : undefined,
    tasks: sw.Tasks?.length
      ? formatTasks({ Tasks: sw.Tasks })
      : [],
    activityWorkflows: sw.ActivityWorkFlows?.length
      ? formatActivityWorkflows({ ActivityWorkFlows: sw.ActivityWorkFlows })
      : [],
    performingOrgs: sw.PerformingOrgs?.length
      ? formatOrganizations({ Organizations: sw.PerformingOrgs })
      : [],
    subBusinessLines: sw.SubBusinessLines?.length
      ? formatSubBusinessLines(sw)
      : [],
    serviceTypes: sw.ServiceTypes?.length
      ? formatServiceTypes({ ServiceTypes: sw.ServiceTypes })
      : [],
    operatingEnvironments: sw.OperatingEnvironments?.length
      ? formatOperatingEnvironments({ OperatingEnvironments: sw.OperatingEnvironments })
      : [],
    equipment: sw.Equipment?.length
      ? formatEquipment({ Equipment: sw.Equipment })
      : [],
    countries: sw.Countries?.length
      ? formatCountries({ Countries: sw.Countries })
      : [],
    geoUnits: sw.GeoUnits?.length
      ? formatGeoUnits({ GeoUnits: sw.GeoUnits })
      : [],
    competencyElements: sw.CompetencyElements?.length
      ? formatCompetencyElements({ CompetencyElements: sw.CompetencyElements })
      : [],
    safetyCategories: sw.SafetyCategories?.length
      ? formatSafetyCategories({ SafetyCategories: sw.SafetyCategories })
      : [],
    referenceDocs: sw.ReferenceFiles?.length
      ? sw.ReferenceFiles.map((refFile: any) => formatReferenceFile(refFile))
      : [],
    createdBy: sw.CreatedBy,
    isLocked: false,
    currentEditor: sw.CurrentEditor,
    currentEditorLockedDate: sw.CurrentEditorLockedDate
      ? new Date(sw.CurrentEditorLockedDate)
      : undefined,
    hasComments: sw.HasComments,
    totalStepComments: sw.TotalStepComments,
    unResolvedStepComments: sw.UnResolvedStepComments,
    epicEquipment: sw.EpicEquipment?.length
      ? formatEpicEquipment({ EpicEquipment: sw.EpicEquipment })
      : [],
    businessRoles: sw.BusinessRoles?.length
      ? formatBusinessRoles({ BusinessRoles: sw.BusinessRoles })
      : [],
    productCenters: sw.ProductCenters?.length
      ? formatProductCenters({ ProductCenters: sw.ProductCenters })
      : [],
    customers: sw.Customers?.length
      ? formatCustomers({ Customers: sw.Customers })
      : [],
    TLMSWIType: sw.GlobalOrLocal,
    dataClassification: sw.DataClassification,
    tcc: sw.TCC,
    introduction: sw.Introduction,
    maintenanceTaskIds: sw.MaintenanceTaskIds,
    ceMarking: sw.CEMarking,
    foreward: sw.Foreward,
    versionChanges: sw.VersionChanges,
    harc: sw.HARC,
    performingPlants: sw.PerformingPlants?.length
      ? formatPerformingPlants({ Plants: sw.PerformingPlants })
      : [],
    workCenters: sw.WorkCenters?.length
      ? formatWorkCenters(sw)
      : [],
    materials: sw.Materials?.length
      ? formatMaterials(sw)
      : [],
    rcType: formatRCType(sw.RCType),
    hazardCategory: formatHazardCategory(sw.HazardCategory),
    showCLSignatureBlock: sw.showCLSignatureBlock,
    templateType: sw.TemplateType,
    contentType: sw.ContentType,
    group: sw.Group ? formatGroup(sw.Group) : undefined,
    groupCounter: sw.GroupCounter ? formatGroup(sw.GroupCounter) : undefined,
    events: sw.Events?.length
      ? formatEvents(sw)
      : [],
    level: sw.Level,
    hasPublishedVersion: sw.HasPublishedVersion,
    plantSource: sw.PlantSource,
  };
}

function formatHazardCategory(hazCat: any): IMasterDataItem | undefined {
  if (!hazCat) {
    return undefined;
  } else {
    return {
      guid: hazCat.Guid,
      value: hazCat.Value,
      active: true
    }
  }
}

export function formatRCType(type: string): RCTypes | undefined {
  if (type === RCTypes.Notice.toString()) {
    return RCTypes.Notice;
  }
  else if (type === RCTypes.SubStep.toString()) {
    return RCTypes.SubStep;
  }
  else if (type === RCTypes.Step.toString()) {
    return RCTypes.Step;
  }
  else if (type === RCTypes.Task.toString()) {
    return RCTypes.Task;
  }
  return undefined;
}

export function formatStepType(type: string): StepTypes | undefined {
  if (type === StepTypes.Step.toString()) {
    return StepTypes.Step;
  }
  else if (type === RCTypes.SubStep.toString()) {
    return StepTypes.SubStep;
  }
  else if (type === RCTypes.Task.toString()) {
    return StepTypes.Task;
  }
  return undefined;
}

export function formatUnlockSWCurrentEditorDto(swUnlockCurrentEditor: any): IUnlockSWCurrentEditor {
  return {
    guid: swUnlockCurrentEditor.Guid,
    currentEditor: swUnlockCurrentEditor.CurrentEditor,
    currentEditorLockedDate: new Date(swUnlockCurrentEditor.CurrentEditorLockedDate),
  };
}

export function formatSWType(type: string): SWTypes {
  if (type === SWTypes.CL.toString()) {
    return SWTypes.CL;
  } else if (type === SWTypes.ECL.toString()) {
    return SWTypes.ECL;
  } else if (type === SWTypes.SWI.toString()) {
    return SWTypes.SWI;
  } else if (type === SWTypes.TLMSWI.toString()) {
    return SWTypes.TLMSWI;
  } else if (type === SWTypes.Unspecified.toString()) {
    return SWTypes.Unspecified;
  } else if (type === SWTypes.LCL.toString()) {
    return SWTypes.LCL;
  } else if (type === SWTypes.TLMRC.toString()) {
    return SWTypes.TLMRC;
  } else if (type === SWTypes.MFGSWI.toString()) {
    return SWTypes.MFGSWI;
  } else if (type === SWTypes.MFGCL.toString()) {
    return SWTypes.MFGCL;
  } else if (type === SWTypes.MFGRC.toString()) {
    return SWTypes.MFGRC;
  } else if (type === SWTypes.TIMERC.toString()) {
    return SWTypes.TIMERC;
  }

  throw new Error("Invalid SW Type: " + type);
}

function formatTaskAlignment(align: string): TaskAlignments {
  if (align === TaskAlignments.After) {
    return TaskAlignments.After;
  } else if (align === TaskAlignments.At) {
    return TaskAlignments.At;
  } else if (align === TaskAlignments.Before) {
    return TaskAlignments.Before;
  } else if (align === TaskAlignments.Unspecified) {
    return TaskAlignments.Unspecified;
  }

  throw new Error("Invalid Task Alignment: " + align);
}

export function formatUpdateSWRequest(sw: ISW): any {
  return {
    Guid: sw.guid,
    Title: sw.title,
    LanguageGuid: sw.language?.guid,
    EquivalentSWGuid: sw.equivalentSW?.guid,
    Description: sw.description,
    TaskAlignment: sw.taskAlignment,
    OwningOrgId: sw.owningOrg?.guid,
    OwningPlantId: sw.owningPlant?.guid,
    Tasks: sw.tasks.map(x => x.guid),
    ActivityWorkFlows: sw.activityWorkflows.map(x => x.guid),
    PerformingOrgs: sw.performingOrgs.map(x => x.guid),
    ServiceTypes: sw.serviceTypes.map(x => x.guid),
    OperatingEnvironments: sw.operatingEnvironments.map(x => x.guid),
    Equipment: sw.equipment.map(x => x.guid),
    Countries: sw.countries.map(x => x.guid),
    GeoUnits: sw.geoUnits.map(x => x.guid),
    Customers: sw.customers.map(x => x.guid),
    CompetencyElements: sw.competencyElements.map(x => x.guid),
    SafetyCategoryGuids: sw.safetyCategories.map(x => x.guid),
    Notices: sw.notices.map(x => ({
      Id: x.id,
      Guid: x.guid,
      Text: x.label,
      Type: x.noticeType,
      SortOrder: x.sortOrder,
      Filename: x.image.filename,
      ImageType: findNoticeImageType(x.image.filename),
      IsRCComponent: x.isRCComponent,
      RCID: x.rcID,
      IsDisabled: x.isDisabled,
      Data:  JSON.stringify({hazardCategory : (x.hazardCategory)
      ? {
        guid: x.hazardCategory.guid,
        value: x.hazardCategory.value
      }
      : null}),
    })),
    PPE: sw.ppe,
    Steps: sw.steps.map(x => formatStepRequest(x)),
    ReferenceFiles: sw.referenceDocs.map(x => formatRefDocRequest(x)),
    EpicEquipment: sw.epicEquipment.map(x => x.guid),
    BusinessRoles: sw.businessRoles.map(x => x.guid),
    ProductCenters: sw.productCenters.map(x => x.guid),
    SubBusinessLines: sw.subBusinessLines.map(x => x.code),
    GlobalOrLocal: sw.TLMSWIType,
    DataClassification: sw.dataClassification,
    TCC: sw.tcc,
    TemplateType: sw.templateType,
    Introduction: sw.introduction,
    MaintenanceTaskIds: sw.maintenanceTaskIds,
    CEMarking: sw.ceMarking,
    Foreward: sw.foreward,
    VersionChanges: sw.versionChanges,
    HARC: sw.harc,
    PerformingPlants: sw.performingPlants.map(x => x.code),
    WorkCenters: sw.workCenters.map(x => x.guid),
    Materials: sw.materials.map(x => x.guid),
    RCType: sw.rcType,
    HazardCategoryId: sw.hazardCategory?.guid,
    ShowCLSignatureBlock: sw.showCLSignatureBlock,
    ContentType: sw.contentType,
    Group: sw.group?.guid,
    GroupCounter: sw.groupCounter?.guid,
    Events: sw.events.map(x => x.guid),
    Level: sw.level,
    PlantSource: sw.plantSource,
  };
}

export function formatUnlockSWCurrentEditorRequest(swGuid: string, owningOrgId?: string, owningPlantId?: string): any {
  return {
    Guid: swGuid,
    OwningOrgId: owningOrgId,
    OwningPlantId: owningPlantId,
  };
}

export function formatUpdateSWResponse(response: any): ISW {
  if (!response.StandardWork) {
    throw new Error("Update standard work response is missing \"StandardWork\".");
  }

  let sw = formatSWDto(response.StandardWork);

  if (response.ActiveApprovalRequestId !== undefined
    && response.ActiveApprovalRequestId !== null) {
    sw.activeApprovalRequestId = parseInt(response.ActiveApprovalRequestId, 10);
  } else {
    sw.activeApprovalRequestId = null;
  }

  if (response.ActiveApprovalRequestLevel !== undefined
    && response.ActiveApprovalRequestLevel !== null) {
    sw.activeApprovalLevel = formatApprovalLevel(response.ActiveApprovalRequestLevel);
  }

  sw.approvalType = response.ApprovalType;
  sw.isLocked = response.IsLocked === true;
  sw.lockReasonType = response.LockReasonType;
  sw.clGuid = response.CLGuid;
  sw.clVersion = response.CLVersion;
  sw.clTitle = response.CLTitle;
  sw.clDescription = response.CLDescription;

  return sw;
}

export function formatUnlockSWCurrentEditorResponse(response: any): IUnlockSWCurrentEditor {
  if (!response.Guid) {
    throw new Error("Unlock standard work response is missing \"StandardWork\".");
  }
  return formatUnlockSWCurrentEditorDto(response);
}

export function formatUploadAttachmentResponse(response: any): IUploadSWAttachmentResponse {
  return {
    swGuid: response.SWGuid,
    swVersion: response.SWVersion,
    filename: response.Filename,
  };
}

export function formatTimeImageResponse(response: any): ITimeImageData {
  return {
    imageNumber: response.TimeImage.Number,
    label: response.TimeImage.Label,
    link: response.TimeImage.Link,
  };
}

export function formatGetSWList(response: any): ISWListData {
  let sws = response?.StandardWorks;

  if (!Array.isArray(sws)) {
    return {
      swList: [],
      totalCount: 0,
    };
  }

  return {
    swList: sws.map((u: any): ISWSummaryItem => ({
      id: u.Id,
      guid: u.Guid,
      type: formatSWType(u.Type),
      version: parseInt(u.Version, 10),
      title: u.Title,
      description: u.Description,
      owningOrgId: u.OwningOrgId,
      owningPlantId: u.OwningPlantId,
      modifiedBy: u.ModifiedBy,
      modifiedOn: new Date(u.ModifiedOn),
      latestPublishedCreatedOn: u.LatestPublishedCreatedOn
        ? new Date(u.LatestPublishedCreatedOn)
        : undefined,
      latestPublishedVersion: u.LatestPublishedVersion
        ? parseInt(u.LatestPublishedVersion, 10)
        : undefined,
      inReview: u.InReview,
      currentEditor: u.CurrentEditor,
      currentEditorLockedDate: u.CurrentEditorLockedDate
        ? new Date(u.CurrentEditorLockedDate)
        : undefined,
      thumbsUpCount: u.ThumbsUpCount,
      thumbsDownCount: u.ThumbsDownCount,
      hasActiveComments: u.HasActiveComments,
      executionCount: u.ExecutionCount,
      approvalLevel: u.ApprovalLevel,
      smeApprover: u.SMEApprover,
      techContRev: u.TechContRev,
      isRCUsed: u.IsRcUsed,
      sourceVersion: u.SourceVersion,
      sourceXml: u.SourceXml,
      rcType: u.RCType,
      dataClassification: u.DataClassification,
    })),
    totalCount: response.TotalCount,
  }
}

export function formatSiteLocationsDto(response: any): ISiteLocationsData {
  let siteLocations = response?.SiteLocations;

  if (!Array.isArray(siteLocations)) {
    return {
      siteLocations: []
    };
  }

  return {
    siteLocations: siteLocations.map((u: any): ISiteLocation => ({
      id: u.Id,
      code: u.Code,
      description: u.Description
    }))
  }
}


export function formatGetSWResponse(response: any): ISW {
  if (!response.StandardWork) {
    throw new Error("Update standard work response is missing \"StandardWork\".");
  }

  let sw = formatSWDto(response.StandardWork);

  if (response.ActiveApprovalRequestId !== undefined
    && response.ActiveApprovalRequestId !== null) {
    sw.activeApprovalRequestId = parseInt(response.ActiveApprovalRequestId, 10);
  } else {
    sw.activeApprovalRequestId = null;
  }

  if (response.ActiveApprovalRequestLevel !== undefined
    && response.ActiveApprovalRequestLevel !== null) {
    sw.activeApprovalLevel = formatApprovalLevel(response.ActiveApprovalRequestLevel);
  }

  sw.isLocked = response.IsLocked === true;
  sw.lockReasonType = response.LockReasonType;
  sw.versions = response.Versions;
  sw.clGuid = response.CLGuid;
  sw.clVersion = response.CLVersion;
  sw.clTitle = response.CLTitle;
  sw.clDescription = response.CLDescription
  sw.activeApprovalUserName = response.ActiveApprovalUserName;
  sw.approvalType = response.ApprovalType;
  sw.hasPublishedVersion = response.HasPublishedVersion;
  return sw;
}

export function formatGetSiteLocationsResponse(response: any): ISiteLocationsData {
  if (!response.SiteLocations) {
    throw new Error("Get Site Locations response is missing \"SiteLocations\".");
  }
  return formatSiteLocationsDto(response);
}

export function formatTimeRCRequestDataResponse(response: any): ITimeRCRequestData {
  return ({
    requestId: response.RequestId,
    status: response.Status,
  });
}

export function formatGetSWCommentsResponse(response: any): IComment[] {
  if (!response.Comments.length
    || !Array.isArray(response.Comments)) {
    return [];
  }

  return response.Comments.map((item: any): IComment => ({
    id: item.Id,
    swId: item.SWId,
    createdBy: item.CreatedBy,
    createdOn: new Date(item.CreatedOn),
    comment: item.Comment,
    resolved: item.Resolved,
  }));
}

export function formatGetRCMappingResponse(response: any): IGetRCMapping[] {
  if (!response.GetRCMappingDtos.length
    || !Array.isArray(response.GetRCMappingDtos)) {
    return [];
  }

  return response.GetRCMappingDtos.map((item: any): IGetRCMapping => ({
    swId: item.SWId,
    title: item.Title,
    guid: item.Guid,
    version: item.Version,
    description: item.Description,
    active: item.Active,
    owningOrg: item.OwningOrg,
    owningPlant: item.OwningPlant,
    sourceSystem: item.SourceSystem,
    type: item.SWType,
  }));
}

export function formatGetTimeMediaFilesMappingResponse(response: any): IGetTimeMediaFileMapping[] {
  if (!response.GetTimeMediaFileMappingDtos.length
    || !Array.isArray(response.GetTimeMediaFileMappingDtos)) {
    return [];
  }

  return response.GetTimeMediaFileMappingDtos.map((item: any): IGetTimeMediaFileMapping => ({
    mediaId: item.MediaId,
    swId: item.SWId,
    title: item.Title,
    guid: item.Guid,
    version: item.Version,
    sourceSystem: item.SourceSystem,
    type: item.SWType,
  }));
}

export function formatGetRCSWResponse(response: any): IGetRCSW {
  if (!response.SW.Guid) {
    throw new Error("RC Guid is missing \"StandardWork\".");
  }

  return ({
    rcId: response.SW.RCId,
    guid: response.SW.Guid,
    version: response.SW.Version,
    draftExists: response.SW.DraftExists
  });
}

export function formatGetSWStepCommentsResponse(response: any): IStepComment[] {
  if (!response.Comments.length
    || !Array.isArray(response.Comments)) {
    return [];
  }

  return response.Comments.map((item: any): IStepComment => ({
    id: item.Id,
    stepId: item.StepID,
    createdBy: item.CreatedBy,
    createdOn: new Date(item.CreatedOn),
    resolved: item.Resolved,
    comment: item.Comment,
  }));
}

export function formatGetSWUserFeedbacksResponse(response: any): ISWUserFeedbackDetails {
  if (!response.SWGuid || !response.CurrentVersion) {
    throw new Error("Get standard work user feedback response is missing \"SwGuid\" or \"CurrentVersion\".");
  }

  let userFeedbackDetails = {
    title: response.Title,
    swGuid: response.SWGuid,
    currentVersion: response.CurrentVersion,
    userFeedbacks: response.UserFeedbacks.map((item: any): ISWUserFeedback => ({
      id: item.Id,
      swId: item.SWId,
      version: item.Version,
      feedback: item.Feedback,
      vote: item.Vote,
      createdBy: item.CreatedBy,
      createdOn: new Date(item.CreatedOn),
      acknowledged: item.Acknowledged,
      response: item.Response,
      responseBy: item.ResponseBy,
      responseOn: new Date(item.ResponseOn),
      isUpdated: false,
    })),
    userFeedbacksExport: response.UserFeedbacks.map((item: any): ISWUserFeedbackExport => ({
      Id: item.Id,
      SwId: item.SWId,
      Version: item.Version,
      Feedback: item.Feedback,
      Vote: item.Vote === true ? "ThumbsUp" : "ThumbsDown",
      CreatedBy: item.CreatedBy,
      CreatedOn: formatDateExport(new Date(item.CreatedOn), true, true),
      Acknowledged: item.Acknowledged,
      Response: item.Response,
      ResponseBy: item.ResponseBy,
      ResponseOn: item.ResponseOn ? formatDateExport(new Date(item.ResponseOn), true, true) : null,
    }))
  };

  return userFeedbackDetails;
}

export function formatGetTimeRCProcessingResponse(response: any): ITimeRCProcessing {
  if (!response.TimeRCProcessingRequestsStatus) {
    throw new Error("Get Time RC processing response is missing \"TimeRCProcessingRequestsStatus\".");
  }

  let timeRCProcessing = {
    timeRCProcessingRequestsStatus: response.TimeRCProcessingRequestsStatus.map((item: any): ITimeRCProcessingRequestsStatus => ({
      requestId: item.RequestId,
      status: item.Status,
      log: item.Log,
    })),
  }
  return timeRCProcessing;
}

export function formatStepInfo(response: any): IStepsCommentInfo {
  return {
    stepId: response.StepID,
    hasUnresolvedComments: response.HasUnresolvedComments,
    totalStepComments: response.TotalStepComments,
    unResolvedStepComments: response.UnResolvedStepComments,
  }
}

export function formatGetAllSWCommentsResponse(response: any): IAllSWComments {
  return {
    swComments: formatGetSWCommentsResponse({ Comments: response.SWComments }),
    swStepComments: formatGetSWStepCommentsResponse({ Comments: response.StepComments }),
  };
}

export function formatGetSWRevisionHistoriesResponse(response: any): ISWRevisionHistory[] {
  if (!response.SWRevisionHistories.length
    || !Array.isArray(response.SWRevisionHistories)) {
    return [];
  }

  return response.SWRevisionHistories.map((item: any): ISWRevisionHistory => ({
    id: item.SWId,
    version: item.Version,
    publishDate: item.PublishDate ? new Date(item.PublishDate) : null,
    description: item.Description,
    approvalResponses: formatUserApprovalResponse(item.ApprovalResponses),
  }));
}

export function formatUserApprovalResponse(response: any): IUserApprovalResponseHistory[] {
  if (!response.length
    || !Array.isArray(response)) {
    return [];
  }

  return response.map((item: any): IUserApprovalResponseHistory => ({
    level: formatApprovalResponseLevelName(item.Level),
    userEmail: item.CreatedBy,
    userName: item.Comment,
  }));
}

export function formatApprovalResponseLevelName(level: any): string {
  if (level === "Author") {
    return "Author";
  } else if (level === "SME") {
    return "SME Approver";
  } else if (level === "TechContent") {
    return "Technical Content Approver";
  } else if (level === "Owner") {
    return "Owner";
  }
  else {
    return "";
  }
}

export function formatDateExport(date: Date | undefined,
  showTime: boolean = true,
  showDate: boolean = true): string {
  let day = date?.getDate();
  let month = date?.toLocaleString('default', { month: "numeric" });
  let year = date?.getFullYear();
  let hour = date?.getHours();
  let minute = date?.getMinutes();
  let seconds = date?.getSeconds();

  let dateStr = '';
  if (showDate) {
    dateStr += date ? `${month?.toString().padStart(2, '0')}/${day?.toString().padStart(2, '0')}/${year}` : '';
  }
  if (showTime) {
    dateStr += date ? ` ${hour?.toString().padStart(2, '0')}:${minute?.toString().padStart(2, '0')}:${seconds?.toString().padStart(2, '0')}` : '';
  }

  return dateStr;
}

export function formatEquivalentSW(sw: any): IMasterDataItem | undefined {
  let equivalentSW = sw.EquivalentSW
    ? formatEquivalentStandardWork(sw.EquivalentSW)
    : undefined;
  return equivalentSW;

}

export function formatGetConfidentialEnabledUsersResponse(
  response: any
): IConfidentialSWUser[] {
  const results: IConfidentialSWUser[] = [];

  if (response && Array.isArray(response.EnabledUsers)) {
    response.EnabledUsers.forEach((user: any) => {
      results.push({
        name: user.Name,
        email: user.Email,
        role: user.Role,
      });
    });
  }

  return results;
}