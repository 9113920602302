import { takeLatest, put, all, call } from "redux-saga/effects";
import { getResponseErrorMessage } from "utilities/validationErrorHelpers";
import MasterDataApi from "apis/masterData/MasterDataApi";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import {
  loadLanguages,
  setSection,
  loadOrganizations,
  loadCountries,
  searchTasks,
  searchServiceTypes,
  clearSearchedMasterDataItems,
  searchCompetencyElements,
  searchActivityWorkflows,
  searchOperatingEnvironments,
  searchEquipment,
  loadSafetyCategories,
  searchEquivalentSWs,
  searchEpicEquipment,
  searchBusinessRoles,
  searchProductCenters,
  loadGeoUnits,
  searchCustomers,
  loadSubBusinessLines,
  loadOwningPlants,
  searchOwningPlants,
  searchPerformingPlants,
  loadPerformingPlants,
  searchMaterials,
  searchWorkCenters,
  loadHazardCategories,
  loadOperationTree,
  setOperationTree,
  loadGroups,
  loadGroupCounter,
  clearOperationTree,
  loadQTracOOS,
  loadUoms,
  searchEvents,
  loadMeters,
  loadCustomizedEvents,
  getConfiguredOwningPlants,
} from "./masterDataActions";
import { IOperationTreeModel, MasterDataSectionNames } from "./masterDataTypes";
import { Action } from "@reduxjs/toolkit";
import { PlantSource, SWTypes } from "interfaces/sw/SWInterfaces";
import { showErrorToast } from "store/toast/toastActions";

export default function* watchMasterDataSagas() {
  yield all([
    watchLoadCountries(),
    watchLoadGeoUnits(),
    watchLoadLanguages(),
    watchLoadOrganizations(),
    watchLoadSubBusinessLines(),
    watchLoadSafetyCategories(),
    watchLoadOwningPlants(),
    watchSearchOwningPlants(),
    watchSearchPerformingPlants(),
    watchLoadPerformingPlants(),
    watchSearchActivityWorkflows(),
    watchSearchEquivalentSWs(),
    watchSearchCompetencyElements(),
    watchSearchEquipment(),
    watchSearchEpicEquipment(),
    watchSearchBusinessRoles(),
    watchSearchProductCenters(),
    watchSearchCustomers(),
    watchSearchOperatingEnvironments(),
    watchSearchServiceTypes(),
    watchClearSearchedMasterDataItems(),
    watchSearchTasks(),
    watchSearchMaterials(),
    watchSearchWorkCenters(),
    watchLoadHazardCategories(),
    watchLoadOperationTree(),
    watchLoadGroups(),
    watchLoadGroupCounter(),
    watchLoadQTRacOOS(),
    watchLoadUoms(),
    watchSearchEvents(),
    watchLoadMeters(),
    watchloadCustomizedEvents(),
    watchGetConfiguredOwningPlants(),
  ]);
}

function* watchLoadCountries() {
  yield takeLatest(loadCountries, loadCountriesAsync);
}

function* watchLoadGeoUnits() {
  yield takeLatest(loadGeoUnits, loadGeoUnitsAsync);
}

function* watchLoadLanguages() {
  yield takeLatest(loadLanguages, loadLanguagesAsync);
}

function* watchLoadOrganizations() {
  yield takeLatest(loadOrganizations, loadOrganizationsAsync);
}

function* watchLoadSubBusinessLines() {
  yield takeLatest(loadSubBusinessLines, loadSubBusinessLinesAsync);
}

function* watchLoadSafetyCategories() {
  yield takeLatest(loadSafetyCategories, loadSafetyCategoriesAsync);
}

function* watchLoadOwningPlants() {
  yield takeLatest(loadOwningPlants, loadOwningPlantsAsync);
}

function* watchSearchPerformingPlants() {
  yield takeLatest(searchPerformingPlants, searchPerformingPlantsAsync);
}

function* watchSearchOwningPlants() {
  yield takeLatest(searchOwningPlants, searchOwningPlantsAsync);
}

function* watchGetConfiguredOwningPlants() {
  yield takeLatest(getConfiguredOwningPlants, getConfiguredOwningPlantsAsync);
}

function* watchLoadPerformingPlants() {
  yield takeLatest(loadPerformingPlants, loadPerformingPlantsAsync);
}

function* watchLoadHazardCategories() {
  yield takeLatest(loadHazardCategories, loadHazardCategoriesAsync);
}

function* watchSearchActivityWorkflows() {
  yield takeLatest(searchActivityWorkflows, searchActivityWorkflowsAsync);
}

function* watchSearchEquivalentSWs() {
  yield takeLatest(searchEquivalentSWs, searchEquivalentSWsAsync);
}

function* watchSearchCompetencyElements() {
  yield takeLatest(searchCompetencyElements, searchCompetencyElementsAsync);
}

function* watchSearchEquipment() {
  yield takeLatest(searchEquipment, searchEquipmentAsync);
}

function* watchSearchEpicEquipment() {
  yield takeLatest(searchEpicEquipment, searchEpicEquipmentAsync);
}

function* watchSearchBusinessRoles() {
  yield takeLatest(searchBusinessRoles, searchBusinessRolesAsync);
}

function* watchSearchProductCenters() {
  yield takeLatest(searchProductCenters, searchProductCentersAsync);
}

function* watchSearchCustomers() {
  yield takeLatest(searchCustomers, searchCustomersAsync);
}

function* watchSearchOperatingEnvironments() {
  yield takeLatest(
    searchOperatingEnvironments,
    searchOperatingEnvironmentsAsync
  );
}

function* watchSearchServiceTypes() {
  yield takeLatest(searchServiceTypes, searchServiceTypesAsync);
}

function* watchClearSearchedMasterDataItems() {
  yield takeLatest(
    clearSearchedMasterDataItems,
    clearSearchedMasterDataItemsAsync
  );
}

function* watchSearchTasks() {
  yield takeLatest(searchTasks, searchTasksAsync);
}

function* watchSearchMaterials() {
  yield takeLatest(searchMaterials, searchMaterialsAsync);
}

function* watchSearchWorkCenters() {
  yield takeLatest(searchWorkCenters, searchWorkCentersAsync);
}

function* watchLoadOperationTree() {
  yield takeLatest(loadOperationTree, loadOperationTreeAsync);
}

function* watchLoadGroups() {
  yield takeLatest(loadGroups, loadGroupsAsync);
}

function* watchLoadGroupCounter() {
  yield takeLatest(loadGroupCounter, loadGroupCounterAsync);
}

function* watchloadCustomizedEvents() {
  yield takeLatest(loadCustomizedEvents, loadCustomizedEventsAsync);
}

function* watchLoadQTRacOOS() {
  yield takeLatest(loadQTracOOS, loadQTracOOSAsync);
}

function* watchLoadUoms() {
  yield takeLatest(loadUoms, loadUomsAsync);
}

function* watchLoadMeters() {
  yield takeLatest(loadMeters, loadMetersAsync);
}

function* watchSearchEvents() {
  yield takeLatest(searchEvents, searchEventsAsync);
}

function* loadCountriesAsync(action: Action) {
  if (!loadCountries.match(action)) {
    return;
  }

  yield call(loadMasterDataActiveAsync,
    "countries",
    MasterDataApi.getCountries,
    action.payload.onlyActive);
}

function* loadGeoUnitsAsync(action: Action) {
  if (!loadGeoUnits.match(action)) {
    return;
  }

  yield call(loadMasterDataActiveAsync,
    "geoUnits",
    MasterDataApi.getGeoUnits,
    action.payload.onlyActive);
}

function* loadLanguagesAsync(action: Action) {
  if (!loadLanguages.match(action)) {
    return;
  }

  yield call(loadMasterDataActiveAsync,
    "languages",
    MasterDataApi.getLanguages,
    action.payload.onlyActive);
}

function* loadOrganizationsAsync(action: Action) {
  if (!loadOrganizations.match(action)) {
    return;
  }

  yield call(loadMasterDataActiveAsync,
    "organizations",
    MasterDataApi.getOrganizations,
    action.payload.onlyActive);
}

function* loadSubBusinessLinesAsync(action: Action) {
  if (!loadSubBusinessLines.match(action)) {
    return;
  }

  yield call(loadMasterDataActiveAsync,
    "subBusinessLines",
    MasterDataApi.getSubBusinessLines,
    action.payload.onlyActive);
}

function* loadSafetyCategoriesAsync(action: Action) {
  if (!loadSafetyCategories.match(action)) {
    return;
  }

  yield call(loadMasterDataActiveAsync,
    "safetyCategories",
    MasterDataApi.getSafetyCategories,
    action.payload.onlyActive);
}

function* loadOwningPlantsAsync(action: Action) {
  if (!loadOwningPlants.match(action)) {
    return;
  }

  yield call(loadMasterDataActiveAsync,
    "owningPlants",
    MasterDataApi.getOwningPlants,
    action.payload.onlyActive);
}

function* loadHazardCategoriesAsync(action: Action) {
  if (!loadHazardCategories.match(action)) {
    return;
  }

  yield call(
    loadMasterDataActiveAsync,
    "hazardCategories",
    MasterDataApi.getHazardCategories,
    action.payload.onlyActive
  );
}

function* loadPerformingPlantsAsync(action: Action) {
  if (!loadPerformingPlants.match(action)) {
    return;
  }

  yield call(loadMasterDataActiveAsync,
    "performingPlants",
    MasterDataApi.getPerformingPlants,
    action.payload.onlyActive);
}

function* loadQTracOOSAsync(action: Action) {
  if (!loadQTracOOS.match(action)) {
    return;
  }

  yield call(loadMasterDataActiveAsync,
    "qTracOOS",
    MasterDataApi.getQTracOOS,
    action.payload.onlyActive);
}

function* searchActivityWorkflowsAsync(action: Action) {
  if (!searchActivityWorkflows.match(action)) {
    return;
  }

  yield call(searchMasterDataWithActiveFilterAsync,
    "activityWorkflows",
    MasterDataApi.searchActivityWorkflows,
    action.payload.searchTerm,
    action.payload.onlyActive);
}

function* searchEquivalentSWsAsync(action: Action) {
  if (!searchEquivalentSWs.match(action)) {
    return;
  }

  yield call(searchMasterDataAsync,
    "equivalentSWs",
    MasterDataApi.searchEquivalentSWs,
    action.payload.searchTerm,
    action.payload.SWType);
}

function* searchCompetencyElementsAsync(action: Action) {
  if (!searchCompetencyElements.match(action)) {
    return;
  }

  yield call(searchMasterDataWithActiveFilterAsync,
    "competencyElements",
    MasterDataApi.searchCompetencyElements,
    action.payload.searchTerm,
    action.payload.onlyActive);
}

function* searchEquipmentAsync(action: Action) {
  if (!searchEquipment.match(action)) {
    return;
  }

  yield call(searchMasterDataWithActiveFilterAsync,
    "equipment",
    MasterDataApi.searchEquipment,
    action.payload.searchTerm,
    action.payload.onlyActive);
}

function* searchEpicEquipmentAsync(action: Action) {
  if (!searchEpicEquipment.match(action)) {
    return;
  }

  yield call(searchMasterDataWithActiveFilterAsync,
    "epicEquipment",
    MasterDataApi.searchEpicEquipment,
    action.payload.searchTerm,
    action.payload.onlyActive);
}

function* searchBusinessRolesAsync(action: Action) {
  if (!searchBusinessRoles.match(action)) {
    return;
  }

  yield call(searchMasterDataWithActiveFilterAsync,
    "businessRoles",
    MasterDataApi.searchBusinessRoles,
    action.payload.searchTerm,
    action.payload.onlyActive);
}

function* searchProductCentersAsync(action: Action) {
  if (!searchProductCenters.match(action)) {
    return;
  }

  yield call(searchMasterDataWithActiveFilterAsync,
    "productCenters",
    MasterDataApi.searchProductCenters,
    action.payload.searchTerm,
    action.payload.onlyActive);
}

function* searchCustomersAsync(action: Action) {
  if (!searchCustomers.match(action)) {
    return;
  }

  yield call(searchMasterDataWithActiveFilterAsync,
    "customers",
    MasterDataApi.searchCustomers,
    action.payload.searchTerm,
    action.payload.onlyActive);
}

function* searchOperatingEnvironmentsAsync(action: Action) {
  if (!searchOperatingEnvironments.match(action)) {
    return;
  }

  yield call(searchMasterDataWithActiveFilterAsync,
    "operatingEnvironments",
    MasterDataApi.searchOperatingEnvironments,
    action.payload.searchTerm,
    action.payload.onlyActive);
}

function* searchServiceTypesAsync(action: Action) {
  if (!searchServiceTypes.match(action)) {
    return;
  }

  yield call(searchMasterDataWithActiveFilterAsync,
    "serviceTypes",
    MasterDataApi.searchServiceTypes,
    action.payload.searchTerm,
    action.payload.onlyActive
  );
}

function* searchTasksAsync(action: Action) {
  if (!searchTasks.match(action)) {
    return;
  }

  yield call(searchMasterDataWithActiveFilterAsync,
    "tasks",
    MasterDataApi.searchTasks,
    action.payload.searchTerm,
    action.payload.onlyActive);
}

function* searchMaterialsAsync(action: Action) {
  if (!searchMaterials.match(action)) {
    return;
  }

  yield call(searchPlantsDataWithPlantCodesAsync,
    "materials",
    MasterDataApi.searchMaterials,
    action.payload.searchTerm,
    action.payload.plantCode,
    action.payload.onlyActive,
    action.payload.plantSource,
  );
}

function* searchOwningPlantsAsync(action: Action) {
  if (!searchOwningPlants.match(action)) {
    return;
  }

  yield call(searchMasterDataWithActiveFilterAsync,
    "owningPlants",
    MasterDataApi.searchOwningPlants,
    action.payload.searchTerm,
    action.payload.onlyActive);
}

function* getConfiguredOwningPlantsAsync(action: Action) {
  if (!getConfiguredOwningPlants.match(action)) {
    return;
  }

  yield call(loadMasterDataConfiguredPlantsAsync,
    "owningPlants",
    MasterDataApi.getConfiguredOwningPlants,
    action.payload.onlyConfiguredPlants,
    action.payload.onlyActive);
}



function* searchPerformingPlantsAsync(action: Action) {
  if (!searchPerformingPlants.match(action)) {
    return;
  }

  yield call(searchMasterDataWithActiveFilterPlantSourceAsync,
    "performingPlants",
    MasterDataApi.searchPerformingPlants,
    action.payload.searchTerm,
    action.payload.onlyActive,
    action.payload.plantSource);
}



function* searchWorkCentersAsync(action: Action) {
  if (!searchWorkCenters.match(action)) {
    return;
  }

  yield call(searchPlantsDataWithPlantCodesAsync,
    "workCenters",
    MasterDataApi.searchWorkCenters,
    action.payload.searchTerm,
    action.payload.plantCode,
    action.payload.onlyActive,
    action.payload.plantSource);
}

function* clearSearchedMasterDataItemsAsync(action: Action) {
  if (!clearSearchedMasterDataItems.match(action)) {
    return;
  }

  yield put(setSection({
    name: "serviceTypes",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "operatingEnvironments",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "equipment",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "competencyElements",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "tasks",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "activityWorkflows",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "productCenters",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "businessRoles",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "epicEquipment",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "geoUnits",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "customers",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "owningPlants",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "performingPlants",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "materials",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "workCenters",
    section: {
      items: [],
    },
  }));

  yield put(setSection({
    name: "events",
    section: {
      items: [],
    },
  }));
}

function* searchMasterDataAsync(sectionName: MasterDataSectionNames,
  apiMethod: (searchTerm: string, SWType: SWTypes) => Promise<IMasterDataItem[]>,
  searchTerm: string,
  SWType: SWTypes) {

  yield put(setSection({
    name: sectionName,
    section: {
      items: [],
      loadOperation: {
        isWorking: true,
      },
    },
  }));

  try {
    const items: IMasterDataItem[] = yield call([MasterDataApi, apiMethod], searchTerm, SWType);

    yield put(setSection({
      name: sectionName,
      section: {
        items,
      },
    }));
  } catch (err: any) {
    yield put(setSection({
      name: sectionName,
      section: {
        items: [],
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      },
    }));
    return;
  }
}

function* searchMasterDataWithActiveFilterAsync(sectionName: MasterDataSectionNames,
  apiMethod: (searchTerm: string, onlyActive: boolean) => Promise<IMasterDataItem[]>,
  searchTerm: string,
  onlyActive: boolean) {

  if (searchTerm.trim() === '') {
    yield put(setSection({
      name: sectionName,
      section: {
        items: [],
      },
    }));
    return;
  }

  yield put(setSection({
    name: sectionName,
    section: {
      items: [],
      loadOperation: {
        isWorking: true,
      },
    },
  }));

  try {
    const items: IMasterDataItem[] = yield call([MasterDataApi, apiMethod], searchTerm, onlyActive);

    yield put(setSection({
      name: sectionName,
      section: {
        items,
      },
    }));
  } catch (err: any) {
    yield put(setSection({
      name: sectionName,
      section: {
        items: [],
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      },
    }));
    return;
  }
}

function* searchMasterDataWithActiveFilterPlantSourceAsync(sectionName: MasterDataSectionNames,
  apiMethod: (searchTerm: string, onlyActive: boolean, plantSource?: PlantSource) => Promise<IMasterDataItem[]>,
  searchTerm: string,
  onlyActive: boolean,
  plantSource?: PlantSource) {

  if (searchTerm.trim() === '') {
    yield put(setSection({
      name: sectionName,
      section: {
        items: [],
      },
    }));
    return;
  }

  yield put(setSection({
    name: sectionName,
    section: {
      items: [],
      loadOperation: {
        isWorking: true,
      },
    },
  }));

  try {
    const items: IMasterDataItem[] = yield call([MasterDataApi, apiMethod], searchTerm, onlyActive, plantSource);

    yield put(setSection({
      name: sectionName,
      section: {
        items,
      },
    }));
  } catch (err: any) {
    yield put(setSection({
      name: sectionName,
      section: {
        items: [],
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      },
    }));
    return;
  }
}


function* loadMasterDataActiveAsync(sectionName: MasterDataSectionNames,
  apiMethod: (onlyActive: boolean) => Promise<IMasterDataItem[]>,
  onlyActive: boolean) {

  yield put(setSection({
    name: sectionName,
    section: {
      items: [],
      loadOperation: {
        isWorking: true,
      },
    },
  }));

  try {
    const items: IMasterDataItem[] = yield call([MasterDataApi, apiMethod], onlyActive);

    yield put(setSection({
      name: sectionName,
      section: {
        items,
      },
    }));
  } catch (err: any) {
    yield put(setSection({
      name: sectionName,
      section: {
        items: [],
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      },
    }));
    return;
  }
}

function* loadMasterDataConfiguredPlantsAsync(sectionName: MasterDataSectionNames,
  apiMethod: (onlyConfiguredPlants: boolean, onlyActive: boolean) => Promise<IMasterDataItem[]>,
  onlyConfiguredPlants: boolean,
  onlyActive: boolean) {

  yield put(setSection({
    name: sectionName,
    section: {
      items: [],
      loadOperation: {
        isWorking: true,
      },
    },
  }));

  try {
    const items: IMasterDataItem[] = yield call([MasterDataApi, apiMethod], onlyConfiguredPlants, onlyActive);

    yield put(setSection({
      name: sectionName,
      section: {
        items,
      },
    }));
  } catch (err: any) {
    yield put(setSection({
      name: sectionName,
      section: {
        items: [],
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      },
    }));
    return;
  }
}

function* loadMasterDataCustomizedEventsAsync(sectionName: MasterDataSectionNames,
  apiMethod: (onlyCustomizedEvents: boolean) => Promise<IMasterDataItem[]>,
  onlyCustomizedEvents: boolean) {

  yield put(setSection({
    name: sectionName,
    section: {
      items: [],
      loadOperation: {
        isWorking: true,
      },
    },
  }));

  try {
    const items: IMasterDataItem[] = yield call([MasterDataApi, apiMethod], onlyCustomizedEvents);

    yield put(setSection({
      name: sectionName,
      section: {
        items,
      },
    }));
  } catch (err: any) {
    yield put(setSection({
      name: sectionName,
      section: {
        items: [],
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      },
    }));
    return;
  }
}

function* searchPlantsDataWithPlantCodesAsync(sectionName: MasterDataSectionNames,
  apiMethod: (searchTerm: string, plantCode: string, onlyActive: boolean, plantSource?: PlantSource) => Promise<IMasterDataItem[]>,
  searchTerm: string,
  plantCode: string,
  onlyActive: boolean,
  plantSource?: PlantSource) {

  if (searchTerm.trim() === '') {
    yield put(setSection({
      name: sectionName,
      section: {
        items: [],
      },
    }));
    return;
  }

  yield put(setSection({
    name: sectionName,
    section: {
      items: [],
      loadOperation: {
        isWorking: true,
      },
    },
  }));

  try {
    const items: IMasterDataItem[] = yield call([MasterDataApi, apiMethod], searchTerm, plantCode, onlyActive, plantSource);

    yield put(setSection({
      name: sectionName,
      section: {
        items,
      },
    }));
  } catch (err: any) {
    yield put(setSection({
      name: sectionName,
      section: {
        items: [],
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      },
    }));
    return;
  }
}

function* loadOperationTreeAsync(action: Action) {
  if (!loadOperationTree.match(action)) {
    return;
  }

  yield put(clearOperationTree());

  try {
    const treeModel: IOperationTreeModel = yield call(
      [MasterDataApi, MasterDataApi.getOperationTree],
      action.payload.plantCode,
      action.payload.materialCode,
      action.payload.group,
      action.payload.groupCounter,
      action.payload.plantSource,
    );

    yield put(setOperationTree(treeModel));
  } catch (err: any) {
    const errorMessage = getResponseErrorMessage(err);
    yield put(showErrorToast(errorMessage));
  }
}

function* loadGroupsAsync(action: Action) {
  if (!loadGroups.match(action)) {
    return;
  }

  if (!!!action.payload.plantCode ||
    !!!action.payload.materialCode) {
    yield put(setSection({
      name: "groups",
      section: {
        items: [],
      },
    }));
    return;
  }

  yield put(setSection({
    name: "groups",
    section: {
      items: [],
      loadOperation: {
        isWorking: true,
      },
    },
  }));

  try {
    const items: IMasterDataItem[] = yield call([MasterDataApi, MasterDataApi.getGroups], action.payload.plantCode,
      action.payload.materialCode, action.payload.plantSource);

    yield put(setSection({
      name: "groups",
      section: {
        items,
      },
    }));
  } catch (err: any) {
    yield put(setSection({
      name: "groups",
      section: {
        items: [],
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      },
    }));
    return;
  }
}

function* loadGroupCounterAsync(action: Action) {
  if (!loadGroupCounter.match(action)) {
    return;
  }

  if (
    !action.payload.plantCode ||
    !action.payload.materialCode ||
    !action.payload.group
  ) {
    yield put(
      setSection({
        name: "groupCounter",
        section: {
          items: [],
        },
      })
    );

    return;
  }

  yield put(setSection({
    name: "groupCounter",
    section: {
      items: [],
      loadOperation: {
        isWorking: true,
      },
    },
  }));

  try {
    const items: IMasterDataItem[] = yield call(
      [MasterDataApi, MasterDataApi.getGroupCounter],
      action.payload.plantCode,
      action.payload.materialCode,
      action.payload.group,
      action.payload.plantSource,
    );

    yield put(setSection({
      name: "groupCounter",
      section: {
        items,
      },
    }));
  } catch (err: any) {
    yield put(setSection({
      name: "groupCounter",
      section: {
        items: [],
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      },
    }));
    return;
  }
}

function* loadUomsAsync(action: Action) {
  if (!loadUoms.match(action)) {
    return;
  }

  yield call(loadMasterDataActiveAsync,
    "uoms",
    MasterDataApi.getUoms,
    action.payload.onlyActive);
}

function getDistinctValues(array: IMasterDataItem[], keyExtractor: (item: IMasterDataItem) => string): IMasterDataItem[] {
  const seen = new Set<string>();
  return array.filter((item) => {
    const key = keyExtractor(item);
    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }
    return false;
  });
}

function* loadMetersAsync(action: Action) {
  if (!loadMeters.match(action)) {
    return;
  }

  try {
    const items: IMasterDataItem[] = yield call(MasterDataApi.getMeters,
      action.payload.equipmentCodes,
      action.payload.location);

    const distinctItems: IMasterDataItem[] = getDistinctValues(items, (item) => `${item.guid}-${item.code}`);

    yield put(setSection({
      name: "meters",
      section: {
        items: distinctItems
      },
    }));
  }
  catch (err: any) {
    yield put(setSection({
      name: "meters",
      section: {
        items: [],
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      },
    }));
    return;
  }
}

function* searchEventsAsync(action: Action) {
  if (!searchEvents.match(action)) {
    return;
  }

  yield call(searchMasterDataWithActiveFilterAsync,
    "events",
    MasterDataApi.searchEvents,
    action.payload.searchTerm,
    action.payload.onlyActive
  );
}



function* loadCustomizedEventsAsync(action: Action) {
  if (!loadCustomizedEvents.match(action)) {
    return;
  }

  yield call(loadMasterDataCustomizedEventsAsync,
    "customizedEvents",
    MasterDataApi.getCustomizedEvents,
    action.payload.onlyCusomizedEvents);
}