import { IManageUserUser, Roles, IAADUser, IManageUserDetail, ITCCInfo, TCCComplianceStatuses, ITCCCountry, IGroupAssignment, UserGroups, IConfidentialSWAllocation } from "interfaces/user/UserInterfaces"
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { IEditUserData, IUserDetail, IUserFilterFields } from "store/userManagement/userManagementTypes";

export function formatGetManageUsersList(response: any): IManageUserUser[] {
  let users = response?.Users;

  if (!Array.isArray(users)) {
    return [];
  }
  
  return users.map((u: any): IManageUserUser => ({
    name: u.Name,
    email: u.Email,
    roles: u.Roles.map((r: any): Roles => r as Roles),
    orgs: u.Orgs ? u.Orgs.map((o: any): IMasterDataItem => ({
      guid: o.Guid,
      code: o.Code,
      value: o.Value,
    }))
      : undefined,
    plants: u.Plants ? u.Plants.map((o: any): IMasterDataItem => ({
      guid: o.Guid,
      code: o.Code,
      value: o.Value,
    }))
      : undefined,
    confidentialSWAllocations: u.ConfidentialSWAllocations
      ? u.ConfidentialSWAllocations.map(
          (c: any): IConfidentialSWAllocation => ({
            role: c.Role,
            swId: c.SWId,
          })
        )
      : [],
  }));
}

export function formatGetUserResponse(response: any): IManageUserUser {
  return {
    name: response.Name,
    email: response.Email,
    roles: response.Roles.map((r: any): Roles => r as Roles),
    groupAssignments: response.GroupAssignments.map((r: any): IGroupAssignment => ({
      group: r.Group as UserGroups,
      roles: r.Roles.map((r: any): Roles => r as Roles)
    })),
    tccInfo: formatTCCInfo(response.TCCInfo),
    orgs: response.Orgs ? response.Orgs.map((o: any): IMasterDataItem => ({
      guid: o.Guid,
      code: o.Code,
      value: o.Value,
    })) : [],
    plants: response.Plants ? response.Plants.map((o: any): IMasterDataItem => ({
      guid: o.Guid,
      code: o.Code,
      value: o.Value,
    })) : [],
    confidentialSWAllocations: response.ConfidentialSWAllocations
      ? response.ConfidentialSWAllocations.map(
          (c: any): IConfidentialSWAllocation => ({
            role: c.Role,
            swId: c.SWId,
          })
        )
      : [],
  };
}

export function formatTCCInfo(tcc: any): ITCCInfo {
  return {
    status: formatTCCComplianceStatus(tcc.Status),
    tccId: parseInt(tcc.TCCId, 10),
    countries: tcc.EmbargoedCountries?.length
      ? tcc.EmbargoedCountries.map((x: any) => formatTCCCountry(x))
      : [],
  }
}

function formatTCCComplianceStatus(status: string): TCCComplianceStatuses {
  if (status === TCCComplianceStatuses.NotCompliant) {
    return TCCComplianceStatuses.NotCompliant;
  } else if (status === TCCComplianceStatuses.Compliant) {
    return TCCComplianceStatuses.Compliant;
  }

  throw new Error("Invalid TCC Compliance Status: " + status);
}

function formatTCCCountry(item: any): ITCCCountry {
  return {
    name: item.Country,
    desc: item.SanctionDesc,
  };
}

export function formatGetEditUserDetail(response: any): IEditUserData {
  return {

    email: response.Email,
    name: response.Name,
    user: formatManageUserDetail(response.GetUserDetailDtos),
    isDirty: false,
  }
}

export function formatConfirmTCCRequest(tccId: number, status: TCCComplianceStatuses): any {
  return {
    TCCId: tccId,
    Response: status,
  };
}

export function formatEditUserDetail(ud: any): IManageUserDetail {

  return {
    role: ud.Role,
    orgs: ud.Organizations.map((md: any) => formatUserDetailMetadata(md)),
    equipment: ud.Equipment.map((md: any) => formatUserDetailMetadata(md)),
    serviceType: ud.ServiceTypes.map((md: any) => formatUserDetailMetadata(md)),
    epicEquipment: ud.EpicEquipments.map((md: any) => formatUserDetailMetadata(md)),
    geoUnit: ud.GeoUnits.map((md: any) => formatUserDetailMetadata(md)),
    productCenter: ud.ProductCenters.map((md: any) => formatUserDetailMetadata(md)),
    country: ud.Countries.map((md: any) => formatUserDetailMetadata(md)),
    isTechUser: ud.IsTechUser,
    isPsdUser: ud.IsPsdUser,
    isMfgUser: ud.IsMfgUser,
    isRCEditor: ud.IsRCEditor,
    proxiedUsers: ud.ProxiedUsers.map((pu: any) => formatProxyApprover(pu))
  }
}

function formatProxyApprover(pu: any) {
  return {
    userEmail: pu.UserEmail,
    proxyUserEmail: pu.ProxyUserEmail,
    validUntil: new Date(pu.ValidUntil),
  };
}

export function formatGetUserDetailResponse(response: any): IUserDetail {

  return {
    email: response.Email,
    name: response.Name,
    userDetails: response.GetUserDetailDtos.length
      ? response.GetUserDetailDtos.map((ud: any) => formatManageUserDetail(ud))
      : []
  }
}

export function formatManageUserDetail(ud: any): IManageUserDetail {
  return {
    role: ud.Role,
    orgs: ud.Organizations.map((md: any) => formatUserDetailMetadata(md)),
    equipment: ud.Equipment.map((md: any) => formatUserDetailMetadata(md)),
    serviceType: ud.ServiceTypes.map((md: any) => formatUserDetailMetadata(md)),
    epicEquipment: ud.EpicEquipments.map((md: any) => formatUserDetailMetadata(md)),
    geoUnit: ud.GeoUnits.map((md: any) => formatUserDetailMetadata(md)),
    productCenter: ud.ProductCenters.map((md: any) => formatUserDetailMetadata(md)),
    country: ud.Countries.map((md: any) => formatUserDetailMetadata(md)),
    isTechUser: ud.IsTechUser,
    isPsdUser: ud.IsPsdUser,
    isMfgUser: ud.IsMfgUser,
    isRCEditor: ud.IsRCEditor,
    proxiedUsers: ud.ProxiedUsers.map((pu: any) => formatProxyApprover(pu))
  }
}

export function formatUserDetailMetadata(md: any): IMasterDataItem {
  return {
    guid: md.Guid,
    code: md.Code,
    value: md.Value,
    active: md.Active,
  }
}

export function formatUpdateUserRequest(user: IManageUserDetail, email: string, name: string): any {
  return {
    User: {
      Name: name,
      Email: email,
      Role: user.role,
      OrgGuids: user.orgs.map(x => x.guid),
      ServiceTypeGuids: user.serviceType.map(x => x.guid),
      EquipmentGuids: user.equipment.map(x => x.guid),
      EpicEquipmentGuids: user.epicEquipment.map(x => x.guid),
      GeoUnitGuids: user.geoUnit.map(x => x.guid),
      ProductCenterGuids: user.productCenter.map(x => x.guid),
      CountryGuids: user.country.map(x => x.guid),
      IsTechUser: user.isTechUser,
      IsPsdUser: user.isPsdUser,
      IsMfgUser: user.isMfgUser,
      IsRCEditor: user.isRCEditor,
    }
  };
}

export function formatCreateUserRequest(user: IAADUser): any {
  return {
    Email: user.email,
    Name: user.name,
  };
}

export function formatSearchAADUsersResponse(response: any): IAADUser[] {
  let users = response?.Users;

  if (!Array.isArray(users)) {
    return [];
  }

  return users.map((u: any): IAADUser => ({
    name: u.Name,
    email: u.Email,
  }));
}

export function formatGetFilteredUsersList(searchText: string, filterFields: IUserFilterFields): any {
  return {
    SearchTerm: searchText,
    FilterOptions: {
      OwningPlants: filterFields.owningPlantsFilter.map(x => x.guid),
      UserRoles: filterFields.userRolesFilter.map(x => x.guid),
      OwningOrgs: filterFields.owningOrgsFilter.map(x => x.guid),
      ServiceTypes: filterFields.serviceTypesFilter.map(x => x.guid),
      Equipments: filterFields.equipmentsFilter.map(x => x.guid),
      EpicEquipments: filterFields.epicEquipmentsFilter.map(x => x.guid),
      Countries: filterFields.countriesFilter.map(x => x.guid),
      GeoUnits: filterFields.geoUnitsFilter.map(x => x.guid),
      ProductCenters: filterFields.productCentersFilter.map(x => x.guid),
      IsPsd: filterFields.IsPsd,
      IsTech: filterFields.IsTech,
      IsMfg: filterFields.IsMfg,
    },
  };
}