import React from "react";
import "./ManageSWAttributes.scoped.scss";
import useSelector from "store/useSelector";
import { SWTypes } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import { save } from "store/manageSW/manageSWActions";
import { showErrorToast } from "store/toast/toastActions";
import validateSW from "utilities/sw/validateSW";
import FlowLayout from "components/layout/FlowLayout";
import SWTypeIcon from "components/sw/search/SWTypeIcon";
import RCAttributes from "./RCAttributes";
import SWIAttributes from "./SWIAttributes";
import CLAttributes from "./CLAttributes";
import ECLAttributes from "./ECLAttributes";
import TLMSWIAttributes from "./TLMSWIAttributes";
import LCLAttributes from "./LCLAttributes";
import MFGAttributes from "./MFGAttributes";
import Banner, { BannerType } from "components/common/Banner";
import MFGCLAttributes from "./MFGCLAttributes";
import MFGRCAttributes from "./MFGRCAttributes";

interface IManageSWAttributesProps {
  isDisabled: boolean,
  enableGlobalLocal?: boolean,
  availableSwTypes: SWTypes[],
  onSWTypeChange: (type: SWTypes) => void,
}

const ManageSWAttributes: React.FC<IManageSWAttributesProps> = ({ isDisabled, enableGlobalLocal, availableSwTypes, onSWTypeChange }) => {
  const {
    manageSW: {
      SW,
    },
  } = useSelector(store => store);
  
  const dispatch = useDispatch();

  const onSaveClick = () => {
    const validationResult = validateSW(SW);
    if (!validationResult.isValid) {
      dispatch(showErrorToast(validationResult.errorMessage));
      return;
    }

    dispatch(save({ isVersionChange: false }));
  }

  const swTypesDescriptions: Record<string, string> = {
    [SWTypes.CL]: "PSD Checklist",
    [SWTypes.ECL]: "PSD Emergency Checklist",
    [SWTypes.SWI]: "PSD Standard Work Instruction",
    [SWTypes.TLMSWI]: "TLM Standard Work Instruction",
    [SWTypes.TLMRC]: "TLM Reusable Content",
    [SWTypes.MFGSWI]: "Manufacturing SWI",
    [SWTypes.MFGCL]: "Manufacturing Checklist",
    [SWTypes.MFGRC]: "Manufacturing Reusable Content",
  };

  if (!SW.guid && availableSwTypes.length === 0) {
    return (
      <Banner type={BannerType.Error}>
        You do not have permission to author a new Standard Work of any type.
      </Banner>
    );
  }

  return (
    <FlowLayout
      footer={!SW.guid
        ? (
          <div className="buttons">
            <button
              id="next-button"
              className="primary-button"
              onClick={onSaveClick}
            >
              Create &amp; Continue
            </button>
          </div>
        ) : undefined
      }
    >
      {
        <>
          <fieldset>
            <legend>Standard Work Type</legend>
            <div className="swType-container">
              {!!!SW.guid &&
                availableSwTypes.map((t) => (
                  <div
                    key={"div-" + t}
                    className={`icon ${SW.type === t ? "selected" : ""}`}
                    onClick={() => onSWTypeChange(t)}
                    title={swTypesDescriptions[t]}>
                    <SWTypeIcon
                      key={t}
                      type={t}
                    />
                  </div>))
              }
              {!!SW.guid &&
                <div
                  key={"div-" + SW.type}
                  className={"icon selected"}
                  title={swTypesDescriptions[SW.type]}>
                  <SWTypeIcon
                    type={SW.type}
                  />
                </div>
              }
            </div>
          </fieldset>
          <br />
        </>
      }
      {SW.type === SWTypes.TLMRC
        &&
        <>
          <RCAttributes
            isDisabled={isDisabled}
          />
        </>
      }
      {SW.type === SWTypes.SWI
        &&
        <>
          <SWIAttributes
            isDisabled={isDisabled}
          />
        </>
      }
      {SW.type === SWTypes.CL
        &&
        <>
          <CLAttributes
            isDisabled={isDisabled}
          />
        </>
      }
      {SW.type === SWTypes.ECL
        &&
        <>
          <ECLAttributes
            isDisabled={isDisabled}
          />
        </>
      }
      {SW.type === SWTypes.TLMSWI
        &&
        <>
          <TLMSWIAttributes
            isDisabled={isDisabled}
            enableGlobalLocal={enableGlobalLocal}
          />
        </>
      }
      {SW.type === SWTypes.LCL
        &&
        <>
          <LCLAttributes
            isDisabled={isDisabled}
          />
        </>
      }
      {SW.type === SWTypes.MFGSWI
        &&
        <>
          <MFGAttributes
            isDisabled={isDisabled}
          />
        </>
      }
      {SW.type === SWTypes.MFGCL &&
        <MFGCLAttributes
          isDisabled={isDisabled}
        />
      }
      {SW.type === SWTypes.MFGRC &&
        <MFGRCAttributes
          isDisabled={isDisabled}
        />
      }
    </FlowLayout>
  );
}

export default ManageSWAttributes;