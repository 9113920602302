import { combineReducers } from "redux";
import toastReducer from "./toast/toastReducer";
import manageSWReducer from "./manageSW/manageSWReducer";
import userManagementReducer from "./userManagement/userManagementReducer";
import masterDataReducer from "./masterData/masterDataReducer";
import swListReducer from "./swList/swListReducer";
import approvalsReducer from "./approvals/approvalsReducer";
import authReducer from "./auth/authReducer";
import batchUpdatesReducer from "./batchUpdates/batchUpdatesReducer";
import proxyApproverReducer from "./proxyApprovers/proxyApproversReducer";
import manageSwReportsReducer from "./ManageSwReports/ManageSwReportsReducer";

export const rootReducer = combineReducers({
  approvals: approvalsReducer,
  auth: authReducer,
  manageSW: manageSWReducer,
  masterData: masterDataReducer,
  swList: swListReducer,
  toasts: toastReducer,
  userManagement: userManagementReducer,
  batchUpdates: batchUpdatesReducer,
  proxyApprovers: proxyApproverReducer,
  manageSwReports: manageSwReportsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;