import { IManageUserUser, ITCCInfo, TCCComplianceStatuses } from "interfaces/user/UserInterfaces";
import { createAction } from "@reduxjs/toolkit";
import { IOperation } from "interfaces/operations/Operations";

export const setLoadOperation = createAction<IOperation | undefined>("auth/setLoadOperation");
export const loadCurrentUserInfo = createAction<{ email: string }>("auth/loadCurrentUserInfo");
export const setCurrentUser = createAction<IManageUserUser>("auth/setCurrentUser");

export const confirmTCC = createAction<{
    tccId: number,
    status: TCCComplianceStatuses,
  }>("auth/confirmTCC");
export const setTCCInfo = createAction<ITCCInfo>("auth/setTCCInfo");
export const setTCCInfoOperation = createAction<IOperation | undefined>("auth/setTCCInfoOperation");

export const setEnableReportsTabFlag = createAction<boolean>("auth/setEnableReportsTabFlag");