import { takeLatest, put, select, call, all } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import {
  setAddUserModalData,
  setAddUserSearchText,
  getManageUsersList,
  setManageUsersData,
  setSearchManageUsersText,
  setEditUserData,
  createUser,
  updateUser,
  getUserForEditWithRole,
  getUserDetails,
  setUserDetails,
  toggleRole,
  deleteUserRole,
  setSearchManageTechContentUsersText,
  getManageTechContentUsersList,
  setManageTechContentUsersData,
  getFilteredUsersList,
  setSearchLDAPManageUsersText,
  setSearchLDAPManageTechContentUsersText
} from './userManagementActions';
import { RootState } from "../rootStore";
import { IAddUserModalData, IManageUsersData, IEditUserData, IUserManagementState, IUserDetail, IUserFilterFields } from './userManagementTypes';
import UserApi from 'apis/user/UserApi';
import { IManageUserUser, IAADUser, Roles } from 'interfaces/user/UserInterfaces';
import { getResponseErrorMessage } from 'utilities/validationErrorHelpers';
import { showSuccessToast, showErrorToast } from 'store/toast/toastActions';
import { Routes } from 'components/routing/Routing';
import { cloneDeep } from 'lodash';

export default function* watchUserManagementSagas() {
  yield all([
    watchSetAddUserSearchText(),
    watchGetManageUsersList(),
    watchGetLDAPManageUsersList(),
    watchGetTechContentManageUsersList(),
    watchGetLDAPTechContentManageUsersList(),
    watchGetUserForEdit(),
    watchUpdateUser(),
    watchCreateUser(),
    watchGetUserDetails(),
    watchToggleRole(),
    watchDeleteUserRole(),
    watchGetFilteredUserList(),
  ]);
}

function* watchSetAddUserSearchText() {
  yield takeLatest(setAddUserSearchText, setAddUserSearchTextAsync);
}

function* watchGetManageUsersList() {
  yield takeLatest([
    getManageUsersList,
    setSearchManageUsersText,
  ], refreshManageUsersListAsync);
}

function* watchGetLDAPManageUsersList() {
  yield takeLatest(setSearchLDAPManageUsersText, refreshLDAPManageUsersListAsync);
}

function* watchGetTechContentManageUsersList() {
  yield takeLatest([
    getManageTechContentUsersList,
    setSearchManageTechContentUsersText,
  ], refreshManageTechContentUsersListAsync);
}

function* watchGetLDAPTechContentManageUsersList() {
  yield takeLatest(setSearchLDAPManageTechContentUsersText, refreshLDAPManageTechContentUsersListAsync);
}

function* watchGetUserForEdit() {
  yield takeLatest(getUserForEditWithRole, getUserForEditAsync);
}

function* watchDeleteUserRole() {
  yield takeLatest(deleteUserRole, deleteUserRoleAsync);
}

function* watchToggleRole() {
  yield takeLatest(toggleRole, getUserForEditAsync);
}

function* watchGetUserDetails() {
  yield takeLatest(getUserDetails, getUserDetailsAsync)
}

function* watchCreateUser() {
  yield takeLatest(createUser, createUserAsync);
}

function* watchUpdateUser() {
  yield takeLatest(updateUser, updateUserAsync);
}

function* watchGetFilteredUserList() {
  yield takeLatest(getFilteredUsersList, getFilteredUsersListAsync);
}

function* setAddUserSearchTextAsync(action: Action) {
  if (!setAddUserSearchText.match(action)) {
    return;
  }

  let modalData: IAddUserModalData =
    yield select((store: RootState) => store.userManagement.addUserModalData);

  // If modal is closed, quit.
  if (!modalData.isOpen) {
    return;
  }

  // If no search text, clear modal results.
  if (!modalData.searchText.trim()) {
    yield put(setAddUserModalData({
      ...modalData,
      searchResults: [],
      loadOperation: undefined,
    }));
    return;
  }

  yield put(setAddUserModalData({
    ...modalData,
    loadOperation: {
      isWorking: true,
      errorMessage: "",
      wasSuccessful: false,
    },
  }));

  try {
    const searchResults: IAADUser[] = yield call(UserApi.searchAADUsers, action.payload);

    yield put(setAddUserModalData({
      ...modalData,
      searchResults,
      loadOperation: undefined,
    }));
  } catch (err: any) {
    yield put(setAddUserModalData({
      ...modalData,
      loadOperation: {
        isWorking: false,
        wasSuccessful: false,
        errorMessage: getResponseErrorMessage(err),
      },
    }));
  }
}

function* refreshLDAPManageUsersListAsync(action: Action) {
  let searchText = "";

  if (setSearchLDAPManageUsersText.match(action)) {
    searchText = action.payload;
  }

  const manageUsersData: IManageUsersData = yield select((store: RootState) => store
    .userManagement
    .manageUsersData);

  if (getManageUsersList.match(action)) {
    searchText = manageUsersData.searchText;
  }

  yield put(setManageUsersData({
    ...manageUsersData,
    getUsersOperation: {
      isWorking: true,
      errorMessage: "",
      wasSuccessful: false,
    },
  }));

  let ldapUserList: IAADUser[] = [];

  try {
    ldapUserList = yield call(UserApi.searchAADUsers, searchText);
  } catch (err: any) {
    yield put(setManageUsersData({
      ...manageUsersData,
      getUsersOperation: {
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
        wasSuccessful: false,
      },
    }));
    return;
  }

  yield put(setManageUsersData({
    ...manageUsersData,
    ldapUserList,
    getUsersOperation: undefined,
  }));
}

function* refreshManageUsersListAsync(action: Action) {
  let searchText = "";

  if (setSearchManageUsersText.match(action)) {
    searchText = action.payload;
  }

  const manageUsersData: IManageUsersData = yield select((store: RootState) => store
    .userManagement
    .manageUsersData);

  if (getManageUsersList.match(action)) {
    searchText = manageUsersData.searchText;
  }

  yield put(setManageUsersData({
    ...manageUsersData,
    getUsersOperation: {
      isWorking: true,
      errorMessage: "",
      wasSuccessful: false,
    },
  }));

  let userList: IManageUserUser[] = [];

  try {
    userList = yield call(UserApi.getManageUsersList, searchText);
  } catch (err: any) {
    yield put(setManageUsersData({
      ...manageUsersData,
      getUsersOperation: {
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
        wasSuccessful: false,
      },
    }));
    return;
  }

  yield put(setManageUsersData({
    ...manageUsersData,
    userList,
    getUsersOperation: undefined,
  }));
}

function* refreshLDAPManageTechContentUsersListAsync(action: Action) {
  let searchText = "";

  if (setSearchLDAPManageTechContentUsersText.match(action)) {
    searchText = action.payload;
  }

  const manageTechContentUsersData: IManageUsersData = yield select((store: RootState) => store
    .userManagement
    .manageTechContentUsersData);

  if (getManageTechContentUsersList.match(action)) {
    searchText = manageTechContentUsersData.searchText;
  }

  yield put(setManageTechContentUsersData({
    ...manageTechContentUsersData,
    getUsersOperation: {
      isWorking: true,
      errorMessage: "",
      wasSuccessful: false,
    },
  }));

  let ldapUserList: IAADUser[] = [];

  try {
    ldapUserList = yield call(UserApi.searchAADUsers, searchText);
  } catch (err: any) {
    yield put(setManageTechContentUsersData({
      ...manageTechContentUsersData,
      getUsersOperation: {
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
        wasSuccessful: false,
      },
    }));
    return;
  }

  yield put(setManageTechContentUsersData({
    ...manageTechContentUsersData,
    ldapUserList,
    getUsersOperation: undefined,
  }));
}

function* refreshManageTechContentUsersListAsync(action: Action) {
  let searchText = "";

  if (setSearchManageTechContentUsersText.match(action)) {
    searchText = action.payload;
  }

  const manageTechContentUsersData: IManageUsersData = yield select((store: RootState) => store
    .userManagement
    .manageTechContentUsersData);

  if (getManageTechContentUsersList.match(action)) {
    searchText = manageTechContentUsersData.searchText;
  }

  yield put(setManageTechContentUsersData({
    ...manageTechContentUsersData,
    getUsersOperation: {
      isWorking: true,
      errorMessage: "",
      wasSuccessful: false,
    },
  }));

  let userList: IManageUserUser[] = [];

  try {
    userList = yield call(UserApi.getManageUsersList, searchText);
  } catch (err: any) {
    yield put(setManageTechContentUsersData({
      ...manageTechContentUsersData,
      getUsersOperation: {
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
        wasSuccessful: false,
      },
    }));
    return;
  }

  yield put(setManageTechContentUsersData({
    ...manageTechContentUsersData,
    userList,
    getUsersOperation: undefined,
  }));
}

function* deleteUserRoleAsync(action: Action) {
  if (!deleteUserRole.match(action)) {
    return;
  }

  yield put(setUserDetails({
    email: "",
    name: "",
    getUserDetailOperation: {
      isWorking: true,
      wasSuccessful: false,
      errorMessage: "",
    }
  }));

  const userDet: IUserDetail = yield select((store: RootState) => store
    .userManagement
    .userDetail);
  let user: IUserDetail | null;

  try {
    user = yield call(UserApi.deleteUserRole,
      action.payload.email,
      action.payload.role,
      action.payload.isTechUser,
      action.payload.isPsdUser,
      action.payload.isMfgUser,
      action.payload.isRCEditor);
  } catch (err: any) {
    yield put(setUserDetails({
      ...userDet,
      getUserDetailOperation: {
        isWorking: false,
        wasSuccessful: false,
        errorMessage: getResponseErrorMessage(err),
      },
    }));
    return;
  }

  if (user) {
    yield put(setUserDetails({
      email: user.email,
      name: user.name,
      userDetails: user.userDetails,

      getUserDetailOperation: undefined,
    }));

    yield put(showSuccessToast(`Role deleted successfully.`));
  } else {
    yield put(setUserDetails({
      ...userDet,
      getUserDetailOperation: {
        isWorking: false,
        wasSuccessful: false,
        errorMessage: `The user ${action.payload} was not found.`,
      },
    }));
  }
}

function* getUserDetailsAsync(action: Action) {
  if (!getUserDetails.match(action)) {
    return;
  }

  yield put(setUserDetails({
    email: "",
    name: "",
    getUserDetailOperation: {
      isWorking: true,
      wasSuccessful: false,
      errorMessage: "",
    }
  }));

  const userDet: IUserDetail = yield select((store: RootState) => store
    .userManagement
    .userDetail);
  let user: IUserDetail | null;

  try {
    user = yield call(UserApi.getUserDetail, action.payload.email);

    if (user) {
      yield put(setUserDetails({
        email: user.email,
        name: user.name,
        userDetails: user.userDetails,
        getUserDetailOperation: {
          isWorking: false,
          wasSuccessful: true,
          errorMessage: "",
        }
      }));
    } else {
      yield put(setUserDetails({
        ...userDet,
        getUserDetailOperation: {
          isWorking: false,
          wasSuccessful: false,
          errorMessage: `The user ${action.payload} was not found.`,
        },
      }));
    }

  } catch (err: any) {
    yield put(setUserDetails({
      ...userDet,
      getUserDetailOperation: {
        isWorking: false,
        wasSuccessful: false,
        errorMessage: getResponseErrorMessage(err),
      },
    }));
    return;
  }

}

function* getUserForEditAsync(action: Action) {
  if (!getUserForEditWithRole.match(action)
    && !toggleRole.match(action)) {
    return;
  }

  const editUserData: IEditUserData = yield select((store: RootState) => store
    .userManagement
    .editUserData);

  yield put(setEditUserData({
    ...editUserData,
    loadOperation: {
      isWorking: true,
      errorMessage: "",
      wasSuccessful: false,
    },
  }));

  let user: IEditUserData | null;

  try {

    user = yield call(UserApi.getUserEditInfo,
      action.payload.email,
      action.payload.role,
      action.payload.isTechUser,
      action.payload.isPsdUser,
      action.payload.isMfgUser,
      action.payload.isRCEditor);

  } catch (err: any) {
    yield put(setEditUserData({
      ...editUserData,
      loadOperation: {
        isWorking: false,
        wasSuccessful: false,
        errorMessage: getResponseErrorMessage(err),
      },
    }));
    return;
  }

  if (user) {
    let enableisDirty = false;

    enableisDirty = user.user?.role === Roles.ApproverCOE
      || user.user?.role === Roles.SysAdmin
      || user.user?.role === Roles.TechComm;

    yield put(setEditUserData({
      ...user,
      originalUser: cloneDeep(user.user),
      isDirty: enableisDirty,
      loadOperation: undefined,
    }));
  } else {
    yield put(setEditUserData({
      ...editUserData,
      loadOperation: {
        isWorking: false,
        wasSuccessful: false,
        errorMessage: `The user ${action.payload} was not found.`,
      },
    }));
  }
}

function* createUserAsync(action: Action) {
  if (!createUser.match(action)) {
    return;
  }

  const {
    addUserModalData,
  }: IUserManagementState = yield select((store: RootState) => store.userManagement);

  yield put(setAddUserModalData({
    ...addUserModalData,
    createOperation: {
      isWorking: true,
    },
  }));

  try {
    yield call(UserApi.createUser, action.payload.user);
  } catch (err: any) {
    yield put(setAddUserModalData({
      ...addUserModalData,
      createOperation: undefined,
    }));
    yield put(showErrorToast(`Failed to add ${action.payload.user.email}. ${getResponseErrorMessage(err)}`));
    return;
  }

  yield put(setAddUserModalData({
    ...addUserModalData,
    createOperation: undefined,
  }));

  yield put(showSuccessToast(`${action.payload.user.email} was added successfully.`));
  let url: string = Routes.UserDetail.replace(":email", window.encodeURIComponent(action.payload.user.email));
  window.open(url, "_self");
}

function* updateUserAsync(action: Action) {
  if (!updateUser.match(action)) {
    return;
  }

  const editUserData: IEditUserData = yield select((store: RootState) => store
    .userManagement
    .editUserData);

  yield put(setEditUserData({
    ...editUserData,
    saveOperation: {
      isWorking: true,
    },
  }));

  try {
    yield call(UserApi.updateUser, action.payload.user, action.payload.email, action.payload.name);
  } catch (err: any) {
    yield put(setEditUserData({
      ...editUserData,
      saveOperation: undefined,
    }));
    yield put(showErrorToast(`Failed to update ${action.payload.email}. ${getResponseErrorMessage(err)}`));
    return;
  }

  yield put(setEditUserData({
    ...editUserData,
    originalUser: cloneDeep(action.payload.user),
    isDirty: false,
    saveOperation: undefined,
  }));

  yield put(showSuccessToast(`${action.payload.email} was updated successfully.`));
}

function* getFilteredUsersListAsync(action: Action) {
  if (!getFilteredUsersList.match(action)) {
    return;
  }

  const manageTechContentUsersData: IManageUsersData = yield select((store: RootState) => store
    .userManagement
    .manageTechContentUsersData);
  const manageUsersData: IManageUsersData = yield select((store: RootState) => store
    .userManagement
    .manageUsersData);
  const userFilteredFields: IUserFilterFields = yield select((store: RootState) => store
    .userManagement
    .filterData);

  if (action.payload.isListUserSearch) {
    yield put(setManageUsersData({
      ...manageUsersData,
      getUsersOperation: {
        isWorking: true,
        errorMessage: "",
        wasSuccessful: false,
      },
    }));
  } else {
    yield put(setManageTechContentUsersData({
      ...manageTechContentUsersData,
      getUsersOperation: {
        isWorking: true,
        errorMessage: "",
        wasSuccessful: false,
      },
    }));
  }

  let userList: IManageUserUser[] = [];
  
  let filtersLength = userFilteredFields.owningPlantsFilter.length +
      userFilteredFields.userRolesFilter.length +
      userFilteredFields.owningOrgsFilter.length +
      userFilteredFields.serviceTypesFilter.length +
      userFilteredFields.equipmentsFilter.length +
      userFilteredFields.epicEquipmentsFilter.length +
      userFilteredFields.countriesFilter.length +
      userFilteredFields.geoUnitsFilter.length +
      userFilteredFields.productCentersFilter.length;
  try {
    if(action.payload.isListUserSearch && 
        filtersLength == 0 && 
        action.payload.searchTerm == ""){
      userList = [];
    }
    else{
      userList = yield call(UserApi.getFilteredUsersList, action.payload.searchTerm, userFilteredFields);
    }
  } catch (err: any) {
    if (action.payload.isListUserSearch) {
      yield put(setManageUsersData({
        ...manageUsersData,
        getUsersOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
          wasSuccessful: false,
        },
      }));
    } else {
      yield put(setManageTechContentUsersData({
        ...manageTechContentUsersData,
        getUsersOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
          wasSuccessful: false,
        },
      }));
    }
    return;
  }

  if (action.payload.isListUserSearch) {
    yield put(setManageUsersData({
      ...manageUsersData,
      userList,
      getUsersOperation: undefined,
    }));
  } else {
    yield put(setManageTechContentUsersData({
      ...manageTechContentUsersData,
      userList,
      getUsersOperation: undefined,
    }));
  }
}