import { createReducer } from "@reduxjs/toolkit";
import { IMasterDataState } from "./masterDataTypes";

import {
  clearOperationTree,
  setOperationTree,
  setSection,
} from "./masterDataActions";

const initialState: IMasterDataState = {
  activityWorkflows: {
    items: [],
  },
  competencyElements: {
    items: [],
  },
  countries: {
    items: [],
  },
  geoUnits: {
    items: [],
  },
  equipment: {
    items: [],
  },
  epicEquipment: {
    items: [],
  },
  equivalentSWs: {
    items: [],
  },
  languages: {
    items: [],
  },
  operatingEnvironments: {
    items: [],
  },
  organizations: {
    items: [],
  },
  plants: {
    items: [],
  },
  customers: {
    items: [],
  },
  safetyCategories: {
    items: [],
  },
  businessRoles: {
    items: [],
  },
  productCenters: {
    items: [],
  },
  subBusinessLines: {
    items: [],
  },
  serviceTypes: {
    items: [],
  },
  tasks: {
    items: [],
  },
  types: {
    items: [
      {
        guid: "CL",
        value: "CL",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "ECL",
        value: "ECL",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "LCL",
        value: "LCL",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "SWI",
        value: "SWI",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "TLMSWI",
        value: "TLMSWI",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "TLMRC",
        value: "TLMRC",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "MFGSWI",
        value: "MFGSWI",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "MFGCL",
        value: "MFGCL",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "MFGRC",
        value: "MFGRC",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
    ],
  },
  globalOrLocalTypes: {
    items: [
      {
        guid: "Global",
        value: "Global",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "Local",
        value: "Local",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
    ],
  },
  dataClassificationTypes: {
    items: [
      {
        guid: "Private",
        value: "Private",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "Confidential",
        value: "Confidential",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
    ],
  },
  feedbackTypes: {
    items: [
      {
        guid: "WithFeedback",
        value: "WithFeedback",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "WithoutFeedback",
        value: "WithoutFeedback",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
    ],
  },
  taskAlignments: {
    items: [
      {
        guid: "After",
        value: "After",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "At",
        value: "At",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "Before",
        value: "Before",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
    ],
  },
  approvalLevels: {
    items: [
      {
        guid: "COE",
        value: "COE",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "Author",
        value: "Author",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "TechContent",
        value: "TechContent",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "Owner",
        value: "Owner",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "TechComm",
        value: "TechComm",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "TechConRev",
        value: "TechConRev",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "LocalTechConApp",
        value: "LocalTechConApp",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
      {
        guid: "SME",
        value: "SME",
        code: undefined,
        parentGuid: undefined,
        active: true,
      },
    ],
  },
  owningPlants: {
    items: [],
  },
  performingPlants: {
    items: [],
  },
  materials: {
    items: [],
  },
  workCenters: {
    items: [],
  },
  hazardCategories: {
    items: [],
  },
  operationTree: undefined,
  groups: {
    items: [],
  },
  groupCounter: {
    items: [],
  },
  qTracOOS: {
    items: [],
  },
  uoms: {
    items: [],
  },
  meters: {
    items: [],
  },
  events: {
    items: [],
    },
  customizedEvents: {
    items: [],
    },
  currentOwningPlants: {
    items: [],
    },
};

const manageSWReducer = createReducer(initialState, (builder) => {
  // Set Section
  builder.addCase(setSection, (state, action) => {
    switch (action.payload.name) {
      case "activityWorkflows": {
        state.activityWorkflows = {
          ...action.payload.section,
        };
        break;
      }
      case "equivalentSWs": {
        state.equivalentSWs = {
          ...action.payload.section,
        };
        break;
      }
      case "competencyElements": {
        state.competencyElements = {
          ...action.payload.section,
        };
        break;
      }
      case "countries": {
        state.countries = {
          ...action.payload.section,
        };
        break;
      }
      case "geoUnits": {
        state.geoUnits = {
          ...action.payload.section,
        };
        break;
      }
      case "equipment": {
        state.equipment = {
          ...action.payload.section,
        };
        break;
      }
      case "epicEquipment": {
        state.epicEquipment = {
          ...action.payload.section,
        };
        break;
      }
      case "businessRoles": {
        state.businessRoles = {
          ...action.payload.section,
        };
        break;
      }
      case "productCenters": {
        state.productCenters = {
          ...action.payload.section,
        };
        break;
      }
      case "subBusinessLines": {
        state.subBusinessLines = {
          ...action.payload.section,
        };
        break;
      }
      case "languages": {
        state.languages = {
          ...action.payload.section,
        };
        break;
      }
      case "operatingEnvironments": {
        state.operatingEnvironments = {
          ...action.payload.section,
        };
        break;
      }
      case "organizations": {
        state.organizations = {
          ...action.payload.section,
        };
        break;
      }
      case "customers": {
        state.customers = {
          ...action.payload.section,
        };
        break;
      }
      case "safetyCategories": {
        state.safetyCategories = {
          ...action.payload.section,
        };
        break;
      }
      case "serviceTypes": {
        state.serviceTypes = {
          ...action.payload.section,
        };
        break;
      }
      case "tasks": {
        state.tasks = {
          ...action.payload.section,
        };
        break;
      }
      case "owningPlants": {
        state.owningPlants = {
          ...action.payload.section,
        };
        break;
      }
      case "performingPlants": {
        state.performingPlants = {
          ...action.payload.section,
        };
        break;
      }
      case "materials": {
        state.materials = {
          ...action.payload.section,
        };
        break;
      }
      case "workCenters": {
        state.workCenters = {
          ...action.payload.section,
        };
        break;
      }
      case "hazardCategories": {
        state.hazardCategories = {
          ...action.payload.section,
        };
        break;
      }
      case "groups": {
        state.groups = {
          ...action.payload.section,
        };
        break;
      }
      case "groupCounter": {
        state.groupCounter = {
          ...action.payload.section,
        };
        break;
      }
      case "qTracOOS": {
        state.qTracOOS = {
          ...action.payload.section,
        };
        break;
      }
      case "uoms": {
        state.uoms = {
          ...action.payload.section,
        };
        break;
      }
      case "meters": {
        state.meters = {
          ...action.payload.section,
        };
        break;
      }
      case "events": {
        state.events = {
          ...action.payload.section,
        };
        break;
        }
        case "currentOwningPlants": {
            state.currentOwningPlants = {
           ...action.payload.section,
        };
        break;
      }
      case "customizedEvents": {
      state.customizedEvents = {
        ...action.payload.section,
      };
        break;
      }
      case "meters": {
        state.meters = {
          ...action.payload.section,
        };
        break;
      }
    }
  });

  builder.addCase(setOperationTree, (state, action) => {
    state.operationTree = action.payload;
  });

  builder.addCase(clearOperationTree, (state) => {
    state.operationTree = undefined;
  });
});

export default manageSWReducer;