import SWMetadata from "components/common/SWMetadata";
import "./RCAttributes.scoped.scss";
import React from "react";

interface IRCAttributesProps {
    isDisabled: boolean,
}

const RCAttributes: React.FC<IRCAttributesProps> = ({ isDisabled }) => {
    return (
        <div className="inputs">
            <div className="type-row" >
                <SWMetadata
                    metadataName="dataClassification"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="languages"
                    isDisabled={isDisabled}
                />
            </div>
            <SWMetadata
                metadataName="title"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="description"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="organizations"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="productCenters"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="epicEquipment"
                isDisabled={isDisabled}
            />
            <SWMetadata
                metadataName="versionChanges"
                isDisabled={isDisabled}
            />
            {/* <SWMetadata
                metadataName="hazardCategory"
                isDisabled={isDisabled} /> */}
        </div>
    );
}

export default RCAttributes;