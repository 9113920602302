import React, { useState } from "react";
import Modal from "components/common/Modal";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import "./FilterModal.scoped.scss";
import MasterDataInput from "../sw/manage/attributes/MasterDataInput";
import {
  loadLanguages,
  searchEquivalentSWs,
  searchTasks,
  searchActivityWorkflows,
  loadOrganizations,
  searchServiceTypes,
  searchOperatingEnvironments,
  searchEquipment,
  loadCountries,
  searchCompetencyElements,
  loadSafetyCategories,
  searchProductCenters,
  searchBusinessRoles,
  searchEpicEquipment,
  loadGeoUnits,
  searchCustomers,
  loadSubBusinessLines,
  searchOwningPlants,
  searchPerformingPlants,
  searchMaterials,
  searchWorkCenters,
  loadHazardCategories,
  loadGroups,
  loadGroupCounter,
  getConfiguredOwningPlants,
} from "store/masterData/masterDataActions";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { IMasterDataSection, MetaDataNames } from "store/masterData/masterDataTypes";
import { ISWFilterFields } from "store/swList/swListTypes";
import { RCTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import { Roles, UserGroups } from "interfaces/user/UserInterfaces";
import { getUserGroupFilters, getUserGroupSWTypes } from "utilities/sw/filterUtils";

export interface IFilterModalProps {
  extraFilterControls?: React.JSX.Element,
  currentSWFilterValues: ISWFilterFields,
  onAddItem(item: IMasterDataItem, name: MetaDataNames): void,
  onRemoveItem(item: IMasterDataItem, name: MetaDataNames): void,
  applyFilter(): void,
  clearFilter(): void,
  setSWStringFilter(name: string, value: string): void,
  setIsModalOpen(value: boolean): void,
  showStatusFilter?: boolean,
  isRCFilters?: boolean,
  setRCType(rcType: RCTypes | undefined): void,
  rcTypeButton?: RCTypes,
}

const FilterModal: React.FC<IFilterModalProps> = (props) => {
  const {
    masterData: {
      activityWorkflows,
      competencyElements,
      countries,
      equipment,
      equivalentSWs,
      languages,
      operatingEnvironments,
      organizations,
      subBusinessLines,
      safetyCategories,
      productCenters,
      businessRoles,
      serviceTypes,
      tasks,
      taskAlignments,
      types,
      globalOrLocalTypes,
      epicEquipment,
      geoUnits,
      customers,
      feedbackTypes,
      owningPlants,
      performingPlants,
      materials,
      workCenters,
      hazardCategories,
      groups,
      groupCounter,
    },
    swList: {
      filterData,
    },
  } = useSelector((store) => store);

  const { setRCType, rcTypeButton } = props;

  const dispatch = useDispatch();
  const [onlyActiveStatusOwningOrg, setOnlyActiveStatusOwningOrg] = useState(true);
  const [onlyActiveStatusPerformingOrg, setOnlyActiveStatusPerformingOrg] = useState(true);
  const [onlyActiveStatusST, setOnlyActiveStatusST] = useState(true);
  const [onlyActiveStatusEquipment, setOnlyActiveStatusEquipment] = useState(true);
  const [onlyActiveStatusOE, setOnlyActiveStatusOE] = useState(true);
  const [onlyActiveStatusTask, setOnlyActiveStatusTask] = useState(true);
  const [onlyActiveStatusAwf, setOnlyActiveStatusAwf] = useState(true);
  const [onlyActiveStatusCompetencyElements, setOnlyActiveStatusCompetencyElements] = useState(true);
  const [onlyActiveCountries, setOnlyActiveCountries] = useState(true);
  const [onlyActiveLanguages, setOnlyActiveLanguages] = useState(true);
  const [onlyActiveSafetyCategories, setOnlyActiveSafetyCategories] = useState(true);
  const [onlyActiveProductCenters, setOnlyActiveProductCenters] = useState(true);
  const [onlyActiveSubBusinessLines, setOnlyActiveSubBusinessLines] = useState(true);
  const [onlyActiveBusinessRoles, setOnlyActiveBusinessRoles] = useState(true);
  const [onlyActiveEpicEquipment, setOnlyActiveEpicEquipment] = useState(true);
  const [onlyActiveGeoUnits, setOnlyActiveGeoUnits] = useState(true);
  const [onlyActiveCustomers, setOnlyActiveCustomers] = useState(true);
  const [onlyActiveStatusOwningPlants, setOnlyActiveStatusOwningPlants] = useState(true);
  const [onlyActiveStatusPerformingPlants, setOnlyActiveStatusPerformingPlants] = useState(true);
  const [onlyActiveStatusMaterials, setOnlyActiveStatusMaterials] = useState(true);
  const [onlyActiveStatusWorkCenters, setOnlyActiveStatusWorkCenters] = useState(true);
  const [onlyActiveStatusHazardCategories, setOnlyActiveStatusHazardCategories] = useState(true);
  const currentUser = useSelector(store => store.auth.currentUser);
  const { currentSWFilterValues: { typesFilter, rcType } } = props;

  const getUserAllowedFilters = (): string[] => {
    let userFilters: string[] = [];
    let userGroupsFilters = getUserGroupFilters();
    currentUser.groupAssignments?.forEach(e => {
      if (e.group in userGroupsFilters)
        userFilters = [...userFilters, ...userGroupsFilters[e.group]];
      if (e.group != UserGroups.PSD) {
        e.roles?.forEach(role => {
          if (role === Roles.Author || role === Roles.OrgAdmin) {
            if (e.group === UserGroups.TLM) {
              userFilters = [...userFilters, ...userGroupsFilters[UserGroups.MFG]]
            } else {
              userFilters = [...userFilters, ...userGroupsFilters[UserGroups.TLM]]
            }
          }
        })
      }
    });
    return userFilters;
  }

  const getUserAllowedSWTypes = (): string[] => {
    let userSWTypes: string[] = [];
    let userGroupSWTypes = getUserGroupSWTypes();

    currentUser.groupAssignments?.forEach(e => {
      if (e.group in userGroupSWTypes) {
        userSWTypes = [...userSWTypes, ...userGroupSWTypes[e.group]];
        if (e.group != UserGroups.PSD) {
          e.roles?.forEach(role => {
            if (role === Roles.Author || role === Roles.OrgAdmin) {
              if (e.group === UserGroups.TLM) {
                userSWTypes = [...userSWTypes, ...userGroupSWTypes[UserGroups.MFG]]
              } else {
                userSWTypes = [...userSWTypes, ...userGroupSWTypes[UserGroups.TLM]]
              }
            }
          })
        }
      }
    });

    return userSWTypes;
  }

  let allowedFilters = getUserAllowedFilters();
  let allowedSWTypes = getUserAllowedSWTypes();

  const shouldDisplayFilter = (filterName: string): boolean => {
    if (currentUser.roles.indexOf(Roles.SysAdmin) > -1) {
      return true;
    }
    else if (allowedFilters.indexOf(filterName) > -1) {
      return true;
    }
    else {
      return false;
    }
  }

  const filterUserAllowedSWTypes = (masterData: IMasterDataSection): IMasterDataSection => {
    if (currentUser.roles.indexOf(Roles.SysAdmin) > -1) {
      return masterData;
    }

    let filteredMasterData: IMasterDataSection = {
      loadOperation: masterData.loadOperation,
      items: []
    }
    masterData.items.forEach(masterDataItem => {
      if (masterDataItem.guid !== undefined &&
        allowedSWTypes.includes(masterDataItem.guid)) {
        filteredMasterData.items.push(masterDataItem);
      }
    });
    return filteredMasterData;
  }


  const shouldDisplayFilterOnLevel = (): boolean => {
    if (rcTypeButton !== undefined &&
      (rcTypeButton === RCTypes.Task ||
        rcTypeButton === RCTypes.Notice)) {
      return false;
    }
    else {
      return true;
    }
  }

  const rednerTlmRcFilters = () => {
    return (
      <>
        {shouldDisplayFilter("owningOrgsFilter") &&
          <div>
            <MasterDataInput
              label="Owning Organizations"
              isMandatory={false}
              selectedItems={props.currentSWFilterValues.owningOrgsFilter}
              allowMultiple={true}
              onAddItem={item => props.onAddItem(item, "owningOrgs")}
              onRemoveItem={item => props.onRemoveItem(item, "owningOrgs")}
              loadItems={(_: undefined, onlyActive: boolean) => dispatch(loadOrganizations({ onlyActive }))}
              masterDataSection={organizations}
              itemFormatter={item =>
                `${item.code} - ${item.value}`
              }
              showStatusFilter={props.showStatusFilter}
              isAsyncSearch={false}
              onlyActiveState={onlyActiveStatusOwningOrg}
              onlyActiveStateChange={val => setOnlyActiveStatusOwningOrg(val)}
            />
          </div>
        }
        {shouldDisplayFilter("productCentersFilter") &&
          <div>
            <MasterDataInput
              label={"Product Centers"}
              isMandatory={false}
              isAsyncSearch={true}
              selectedItems={props.currentSWFilterValues.productCentersFilter}
              allowMultiple={true}
              onAddItem={item => props.onAddItem(item, "productCenters")}
              onRemoveItem={item => props.onRemoveItem(item, "productCenters")}
              loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchProductCenters({ searchTerm, onlyActive }))}
              masterDataSection={productCenters}
              itemFormatter={item => item.value}
              showStatusFilter={props.showStatusFilter}
              onlyActiveState={onlyActiveProductCenters}
              onlyActiveStateChange={val => setOnlyActiveProductCenters(val)}
            />
          </div>
        }
        {shouldDisplayFilter("epicEquipmentFilter") &&
          <div>
            <MasterDataInput
              label={"EPIC Equipment"}
              isMandatory={false}
              isAsyncSearch={true}
              selectedItems={props.currentSWFilterValues.epicEquipmentFilter}
              allowMultiple={true}
              onAddItem={item => props.onAddItem(item, "epicEquipment")}
              onRemoveItem={item => props.onRemoveItem(item, "epicEquipment")}
              loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchEpicEquipment({ searchTerm, onlyActive }))}
              masterDataSection={epicEquipment}
              itemFormatter={item => item.value}
              showStatusFilter={props.showStatusFilter}
              onlyActiveState={onlyActiveEpicEquipment}
              onlyActiveStateChange={val => setOnlyActiveEpicEquipment(val)}
            />
          </div>
        }
        {shouldDisplayFilter("hazardCategoriesFilter") && (
          <div>
            <MasterDataInput
              label="Hazard Category"
              selectedItems={props.currentSWFilterValues.hazardCategoriesFilter}
              masterDataSection={hazardCategories}
              loadItems={(_: undefined, onlyActive: boolean) => dispatch(loadHazardCategories({ onlyActive }))}
              onAddItem={(item) => props.onAddItem(item, "hazardCategories")}
              onRemoveItem={(item) =>
                props.onRemoveItem(item, "hazardCategories")
              }
              itemFormatter={(item) => item.value}
              allowMultiple={true}
              showStatusFilter={props.showStatusFilter}
              disabled={false}
              onlyActiveState={onlyActiveStatusHazardCategories}
              onlyActiveStateChange={val => setOnlyActiveStatusHazardCategories(val)}
            />
          </div>
        )}

        {shouldDisplayFilter("owningPlantsFilter") &&
          shouldDisplayFilterOnLevel() &&
          <div>
            <MasterDataInput
              label="Owning Plant"
              isMandatory={false}
              selectedItems={props.currentSWFilterValues.owningPlantsFilter}
              allowMultiple={true}
              onAddItem={item => props.onAddItem(item, "owningPlants")}
              onRemoveItem={item => props.onRemoveItem(item, "owningPlants")}
              loadItems={(_: undefined, onlyActive: boolean) => dispatch(getConfiguredOwningPlants({ onlyConfiguredPlants: true, onlyActive }))}
              masterDataSection={owningPlants}
              itemFormatter={item =>
                `${item.guid} - ${item.value}`
              }
              showStatusFilter={props.showStatusFilter}
              isAsyncSearch={false}
              onlyActiveState={onlyActiveStatusOwningPlants}
              onlyActiveStateChange={val => setOnlyActiveStatusOwningPlants(val)}
            />
          </div>
        }
      </>
    );
  }

  const rednerMfgRcFilters = () => {
    return (
      <>
        {shouldDisplayFilter("owningPlantsFilter") &&
          <div>
            <MasterDataInput
              label="Owning Plant"
              isMandatory={false}
              selectedItems={props.currentSWFilterValues.owningPlantsFilter}
              allowMultiple={false}
              onAddItem={item => props.onAddItem(item, "owningPlants")}
              onRemoveItem={item => props.onRemoveItem(item, "owningPlants")}
              loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchOwningPlants({ searchTerm, onlyActive }))}
              masterDataSection={owningPlants}
              itemFormatter={item =>
                `${item.guid} - ${item.value}`
              }
              showStatusFilter={props.showStatusFilter}
              isAsyncSearch={true}
              onlyActiveState={onlyActiveStatusOwningPlants}
              onlyActiveStateChange={val => setOnlyActiveStatusOwningPlants(val)}
            />
          </div>
        }
        {shouldDisplayFilter("performingPlantsFilter") &&
          <div>
            <MasterDataInput
              label="Performing Plants"
              isMandatory={false}
              selectedItems={props.currentSWFilterValues.performingPlantsFilter}
              allowMultiple={true}
              onAddItem={item => props.onAddItem(item, "performingPlants")}
              onRemoveItem={item => props.onRemoveItem(item, "performingPlants")}
              loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchPerformingPlants({
                searchTerm: searchTerm,
                onlyActive: onlyActive,
                plantSource: props.currentSWFilterValues.owningPlantsFilter.map(x => x.source)[0]
              }))}
              masterDataSection={performingPlants}
              itemFormatter={item =>
                `${item.guid} - ${item.value}`
              }
              showStatusFilter={props.showStatusFilter}
              isAsyncSearch={true}
              onlyActiveState={onlyActiveStatusPerformingPlants}
              onlyActiveStateChange={val => setOnlyActiveStatusPerformingPlants(val)}
            />
          </div>
        }
        {props.currentSWFilterValues.owningPlantsFilter?.length > 0 &&
          shouldDisplayFilter("materialsFilter") && (
            <div>
              <MasterDataInput
                label={"Material"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.materialsFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "materials")}
                onRemoveItem={item => props.onRemoveItem(item, "materials")}
                loadItems={
                  (searchTerm: string, onlyActive: boolean) =>
                    dispatch(searchMaterials({
                      searchTerm: searchTerm,
                      plantCode: props.currentSWFilterValues.owningPlantsFilter.map(x => x.guid)[0],
                      plantSource: props.currentSWFilterValues.owningPlantsFilter.map(x => x.source)[0],
                      onlyActive: onlyActive
                    }))
                }
                masterDataSection={materials}
                itemFormatter={item =>
                  <span>
                    {item.code}
                    <br />
                    {item.value}
                  </span>
                }
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveStatusMaterials}
                onlyActiveStateChange={val => setOnlyActiveStatusMaterials(val)}
              />
            </div>
          )}
        {props.currentSWFilterValues.owningPlantsFilter?.length > 0 &&
          shouldDisplayFilter("workCentersFilter") && (
            <div>
              <MasterDataInput
                label={"Work Centers"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.workCentersFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "workCenters")}
                onRemoveItem={item => props.onRemoveItem(item, "workCenters")}
                loadItems={
                  (searchTerm: string, onlyActive: boolean) =>
                    dispatch(searchWorkCenters({
                      searchTerm: searchTerm,
                      plantCode: props.currentSWFilterValues.owningPlantsFilter.map(x => x.guid)[0],
                      plantSource: props.currentSWFilterValues.owningPlantsFilter.map(x => x.source)[0],
                      onlyActive: onlyActive,
                    }))
                }
                masterDataSection={workCenters}
                itemFormatter={item =>
                  <span>
                    {item.code}
                    <br />
                    {item.value}
                  </span>
                }
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveStatusWorkCenters}
                onlyActiveStateChange={val => setOnlyActiveStatusWorkCenters(val)}
              />
            </div>
          )}
      </>
    );
  }

  let filterControls: React.JSX.Element | undefined;
  filterControls = (
    <div className="inputs">
      {props.extraFilterControls &&
        <div>
          {props.extraFilterControls}
        </div>
      }
      {!props.isRCFilters &&
        <>
          {shouldDisplayFilter("typesFilter") &&
            <div>
              <MasterDataInput
                label="Type"
                isMandatory={false}
                selectedItems={props.currentSWFilterValues.typesFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "types")}
                onRemoveItem={item => props.onRemoveItem(item, "types")}
                loadItems={() => void 0}
                masterDataSection={filterUserAllowedSWTypes(types)}
                itemFormatter={item => item.value}
              />
            </div>
          }
          {shouldDisplayFilter("rcTypesFilter") &&
            typesFilter.find(({ value }) => value === "TLMRC" || value === "MFGRC") && (
              <div>
                <label>RC Type</label>
                <select
                  value={props.currentSWFilterValues.rcType}
                  onChange={(e) => setRCType(e.target.value as RCTypes)}
                >
                  <option value={undefined} />
                  {Object.keys(RCTypes).map((rcType) => (
                    <option value={rcType} key={rcType}>{rcType}</option>
                  ))}
                </select>
              </div>
            )}
          {shouldDisplayFilter("globalOrLocalTypesFilter") &&
            <div>
              <MasterDataInput
                label="GlobalOrLocal"
                isMandatory={false}
                selectedItems={props.currentSWFilterValues.globalOrLocalTypesFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "globalOrLocalTypes")}
                onRemoveItem={item => props.onRemoveItem(item, "globalOrLocalTypes")}
                loadItems={() => void 0}
                masterDataSection={globalOrLocalTypes}
                itemFormatter={item => item.value}
              />
            </div>
          }
          {shouldDisplayFilter("feedbackTypesFilter") &&
            <div>
              <MasterDataInput
                label="FeedbackTypes"
                isMandatory={false}
                selectedItems={props.currentSWFilterValues.feedbackTypesFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "feedbackTypes")}
                onRemoveItem={item => props.onRemoveItem(item, "feedbackTypes")}
                loadItems={() => void 0}
                masterDataSection={feedbackTypes}
                itemFormatter={item => item.value}
              />
            </div>
          }
          {shouldDisplayFilter("alignmentsFilter") &&
            <div>
              <MasterDataInput
                label="Task Alignment"
                isMandatory={false}
                selectedItems={props.currentSWFilterValues.alignmentsFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "taskAlignments")}
                onRemoveItem={item => props.onRemoveItem(item, "taskAlignments")}
                loadItems={() => void 0}
                masterDataSection={taskAlignments}
                itemFormatter={item => item.value}
              />
            </div>
          }
          {shouldDisplayFilter("languagesFilter") &&
            <div>
              <MasterDataInput
                label="Language"
                isMandatory={false}
                selectedItems={props.currentSWFilterValues.languagesFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "languages")}
                onRemoveItem={item => props.onRemoveItem(item, "languages")}
                loadItems={(_: string, onlyActive: boolean) => dispatch(loadLanguages({ onlyActive }))}
                masterDataSection={languages}
                itemFormatter={item => item.value}
                showStatusFilter={false}
                onlyActiveState={onlyActiveLanguages}
                onlyActiveStateChange={val => setOnlyActiveLanguages(val)}
              />
            </div>
          }
          {shouldDisplayFilter("equivalentSWsFilter") &&
            <div>
              <MasterDataInput
                label={"Equivalent SW"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.equivalentSWsFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "equivalentSWs")}
                onRemoveItem={item => props.onRemoveItem(item, "equivalentSWs")}
                loadItems={(searchTerm: string) => dispatch(searchEquivalentSWs({ searchTerm, SWType: SWTypes.Unspecified }))}
                masterDataSection={equivalentSWs}
                itemFormatter={item => (
                  <span>
                    {item.value}
                    <br />
                    {item.code}
                  </span>
                )}
              />
            </div>
          }
          {shouldDisplayFilter("tasksFilter") &&
            <div>
              <MasterDataInput
                label={"Tasks"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.tasksFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "tasks")}
                onRemoveItem={item => props.onRemoveItem(item, "tasks")}
                loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchTasks({ searchTerm, onlyActive }))}
                masterDataSection={tasks}
                itemFormatter={item => item.value}
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveStatusTask}
                onlyActiveStateChange={val => setOnlyActiveStatusTask(val)}
              />
            </div>
          }
          {shouldDisplayFilter("activityWorkflowsFilter") &&
            props.currentSWFilterValues.typesFilter.find((x) => x.value === "ECL") && (
              <div>
                <MasterDataInput
                  label={"Activity Workflows"}
                  isMandatory={false}
                  isAsyncSearch={true}
                  selectedItems={props.currentSWFilterValues.activityWorkflowsFilter}
                  allowMultiple={true}
                  onAddItem={item => props.onAddItem(item, "activityWorkflows")}
                  onRemoveItem={item => props.onRemoveItem(item, "activityWorkflows")}
                  loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchActivityWorkflows({ searchTerm, onlyActive }))}
                  masterDataSection={activityWorkflows}
                  itemFormatter={item => (
                    <span>
                      {item.value}
                      <br />
                      {item.code}
                    </span>
                  )}
                  showStatusFilter={props.showStatusFilter}
                  onlyActiveState={onlyActiveStatusAwf}
                  onlyActiveStateChange={val => setOnlyActiveStatusAwf(val)}
                />
              </div>
            )}
          {shouldDisplayFilter("customersFilter") &&
            <div>
              <MasterDataInput
                label={"Customers"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.customersFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "customers")}
                onRemoveItem={item => props.onRemoveItem(item, "customers")}
                loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchCustomers({ searchTerm, onlyActive }))}
                masterDataSection={customers}
                itemFormatter={item => item.value}
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveCustomers}
                onlyActiveStateChange={val => setOnlyActiveCustomers(val)}
              />
            </div>
          }
          {shouldDisplayFilter("owningOrgsFilter") &&
            <div>
              <MasterDataInput
                label="Owning Organizations"
                isMandatory={false}
                selectedItems={props.currentSWFilterValues.owningOrgsFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "owningOrgs")}
                onRemoveItem={item => props.onRemoveItem(item, "owningOrgs")}
                loadItems={(_: undefined, onlyActive: boolean) => dispatch(loadOrganizations({ onlyActive }))}
                masterDataSection={organizations}
                itemFormatter={item =>
                  `${item.code} - ${item.value}`
                }
                showStatusFilter={props.showStatusFilter}
                isAsyncSearch={false}
                onlyActiveState={onlyActiveStatusOwningOrg}
                onlyActiveStateChange={val => setOnlyActiveStatusOwningOrg(val)}
              />
            </div>
          }
          {shouldDisplayFilter("subBusinessLinesFilter") &&
            <div>
              <MasterDataInput
                label="Sub BusinessLines"
                isMandatory={false}
                selectedItems={props.currentSWFilterValues.subBusinessLinesFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "subBusinessLines")}
                onRemoveItem={item => props.onRemoveItem(item, "subBusinessLines")}
                loadItems={(_: undefined, onlyActive: boolean) => dispatch(loadSubBusinessLines({ onlyActive }))}
                masterDataSection={subBusinessLines}
                itemFormatter={item =>
                  `${item.code} - ${item.value}`
                }
                showStatusFilter={props.showStatusFilter}
                isAsyncSearch={false}
                onlyActiveState={onlyActiveSubBusinessLines}
                onlyActiveStateChange={val => setOnlyActiveSubBusinessLines(val)}
              />
            </div>
          }
          {shouldDisplayFilter("performingOrgsFilter") &&
            <div>
              <MasterDataInput
                label="Performing Organizations"
                isMandatory={false}
                selectedItems={props.currentSWFilterValues.performingOrgsFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "performingOrgs")}
                onRemoveItem={item => props.onRemoveItem(item, "performingOrgs")}
                loadItems={(_: undefined, onlyActive: boolean) => dispatch(loadOrganizations({ onlyActive }))}
                masterDataSection={organizations}
                itemFormatter={item =>
                  `${item.code} - ${item.value}`
                }
                showStatusFilter={props.showStatusFilter}
                isAsyncSearch={false}
                onlyActiveState={onlyActiveStatusPerformingOrg}
                onlyActiveStateChange={val => setOnlyActiveStatusPerformingOrg(val)}
              />
            </div>
          }
          {shouldDisplayFilter("serviceTypesFilter") &&
            <div>
              <MasterDataInput
                label={"Service Type"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.serviceTypesFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "serviceTypes")}
                onRemoveItem={item => props.onRemoveItem(item, "serviceTypes")}
                loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchServiceTypes({ searchTerm, onlyActive }))}
                masterDataSection={serviceTypes}
                itemFormatter={item => item.value}
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveStatusST}
                onlyActiveStateChange={val => setOnlyActiveStatusST(val)}
              />
            </div>
          }
          {shouldDisplayFilter("operatingEnvironmentsFilter") &&
            <div>
              <MasterDataInput
                label={"Operating Environments"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.operatingEnvironmentsFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "operatingEnvironments")}
                onRemoveItem={item => props.onRemoveItem(item, "operatingEnvironments")}
                loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchOperatingEnvironments({ searchTerm, onlyActive }))}
                masterDataSection={operatingEnvironments}
                itemFormatter={item => item.value}
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveStatusOE}
                onlyActiveStateChange={val => setOnlyActiveStatusOE(val)}
              />
            </div>
          }
          {shouldDisplayFilter("equipmentFilter") &&
            <div>
              <MasterDataInput
                label={"Equipment"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.equipmentFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "equipment")}
                onRemoveItem={item => props.onRemoveItem(item, "equipment")}
                loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchEquipment({ searchTerm, onlyActive }))}
                masterDataSection={equipment}
                itemFormatter={item => item.value}
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveStatusEquipment}
                onlyActiveStateChange={val => setOnlyActiveStatusEquipment(val)}
              />
            </div>
          }
          {shouldDisplayFilter("countriesFilter") &&
            <div>
              <MasterDataInput
                label={"Countries"}
                isMandatory={false}
                selectedItems={props.currentSWFilterValues.countriesFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "countries")}
                onRemoveItem={item => props.onRemoveItem(item, "countries")}
                loadItems={(_: string, onlyActive: boolean) => dispatch(loadCountries({ onlyActive: onlyActive }))}
                masterDataSection={countries}
                itemFormatter={item => item.value}
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveCountries}
                onlyActiveStateChange={val => setOnlyActiveCountries(val)}
              />
            </div>
          }
          {shouldDisplayFilter("competencyElementsFilter") &&
            <div>
              <MasterDataInput
                label={"Competency Elements"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.competencyElementsFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "competencyElements")}
                onRemoveItem={item => props.onRemoveItem(item, "competencyElements")}
                loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchCompetencyElements({ searchTerm, onlyActive }))}
                masterDataSection={competencyElements}
                itemFormatter={item => item.value}
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveStatusCompetencyElements}
                onlyActiveStateChange={val => setOnlyActiveStatusCompetencyElements(val)}
              />
            </div>
          }
          {shouldDisplayFilter("safetyCategoriesFilter") &&
            <div>
              <MasterDataInput
                label={"Safety Categories"}
                isMandatory={false}
                selectedItems={props.currentSWFilterValues.safetyCategoriesFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "safetyCategories")}
                onRemoveItem={item => props.onRemoveItem(item, "safetyCategories")}
                loadItems={(_: string, onlyActive: boolean) => dispatch(loadSafetyCategories({ onlyActive: onlyActive }))}
                masterDataSection={safetyCategories}
                itemFormatter={item => item.value}
                showStatusFilter={false}
                onlyActiveState={onlyActiveSafetyCategories}
                onlyActiveStateChange={val => setOnlyActiveSafetyCategories(val)}
              />
            </div>
          }
          {shouldDisplayFilter("productCentersFilter") &&
            <div>
              <MasterDataInput
                label={"Product Centers"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.productCentersFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "productCenters")}
                onRemoveItem={item => props.onRemoveItem(item, "productCenters")}
                loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchProductCenters({ searchTerm, onlyActive }))}
                masterDataSection={productCenters}
                itemFormatter={item => item.value}
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveProductCenters}
                onlyActiveStateChange={val => setOnlyActiveProductCenters(val)}
              />
            </div>
          }
          {shouldDisplayFilter("businessRolesFilter") &&
            <div>
              <MasterDataInput
                label={"Business Roles"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.businessRolesFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "businessRoles")}
                onRemoveItem={item => props.onRemoveItem(item, "businessRoles")}
                loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchBusinessRoles({ searchTerm, onlyActive }))}
                masterDataSection={businessRoles}
                itemFormatter={item => item.value}
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveBusinessRoles}
                onlyActiveStateChange={val => setOnlyActiveBusinessRoles(val)}
              />
            </div>
          }
          {shouldDisplayFilter("maintenanceTaskIdsFilter") &&
            <div>
              <label>Maintenance Task</label>
              <input
                type="text"
                value={props.currentSWFilterValues.maintenanceTaskIdsFilter}
                onChange={e => props.setSWStringFilter("maintenanceTaskIds", e.target.value)}
              />
            </div>
          }
          {shouldDisplayFilter("epicEquipmentFilter") &&
            <div>
              <MasterDataInput
                label={"EPIC Equipment"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.epicEquipmentFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "epicEquipment")}
                onRemoveItem={item => props.onRemoveItem(item, "epicEquipment")}
                loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchEpicEquipment({ searchTerm, onlyActive }))}
                masterDataSection={epicEquipment}
                itemFormatter={item => item.value}
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveEpicEquipment}
                onlyActiveStateChange={val => setOnlyActiveEpicEquipment(val)}
              />
            </div>
          }
          {shouldDisplayFilter("geoUnitsFilter") &&
            <div>
              <MasterDataInput
                label={"GeoUnit"}
                isMandatory={false}
                selectedItems={props.currentSWFilterValues.geoUnitsFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "geoUnits")}
                onRemoveItem={item => props.onRemoveItem(item, "geoUnits")}
                loadItems={(_: string, onlyActive: boolean) => dispatch(loadGeoUnits({ onlyActive: onlyActive }))}
                masterDataSection={geoUnits}
                itemFormatter={item => item.value}
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveGeoUnits}
                onlyActiveStateChange={val => setOnlyActiveGeoUnits(val)}
              />
            </div>
          }
          {shouldDisplayFilter("owningPlantsFilter") &&
            <div>
              <MasterDataInput
                label="Owning Plant"
                isMandatory={false}
                selectedItems={props.currentSWFilterValues.owningPlantsFilter}
                allowMultiple={false}
                onAddItem={item => props.onAddItem(item, "owningPlants")}
                onRemoveItem={item => props.onRemoveItem(item, "owningPlants")}
                loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchOwningPlants({ searchTerm, onlyActive }))}
                masterDataSection={owningPlants}
                itemFormatter={item =>
                  `${item.guid} - ${item.value}`
                }
                showStatusFilter={props.showStatusFilter}
                isAsyncSearch={true}
                onlyActiveState={onlyActiveStatusOwningPlants}
                onlyActiveStateChange={val => setOnlyActiveStatusOwningPlants(val)}
              />
            </div>
          }
          {shouldDisplayFilter("performingPlantsFilter") &&
            <div>
              <MasterDataInput
                label="Performing Plants"
                isMandatory={false}
                selectedItems={props.currentSWFilterValues.performingPlantsFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "performingPlants")}
                onRemoveItem={item => props.onRemoveItem(item, "performingPlants")}
                loadItems={(searchTerm: string, onlyActive: boolean) => dispatch(searchPerformingPlants({
                  searchTerm: searchTerm,
                  onlyActive: onlyActive,
                  plantSource: props.currentSWFilterValues.owningPlantsFilter.map(x => x.source)[0]
                }))}
                masterDataSection={performingPlants}
                itemFormatter={item =>
                  `${item.guid} - ${item.value}`
                }
                showStatusFilter={props.showStatusFilter}
                isAsyncSearch={true}
                onlyActiveState={onlyActiveStatusPerformingPlants}
                onlyActiveStateChange={val => setOnlyActiveStatusPerformingPlants(val)}
              />
            </div>
          }
          {shouldDisplayFilter("materialsFilter") &&
            <div>
              <MasterDataInput
                label={"Material"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.materialsFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "materials")}
                onRemoveItem={item => props.onRemoveItem(item, "materials")}
                loadItems={
                  (searchTerm: string, onlyActive: boolean) =>
                    dispatch(searchMaterials({
                      searchTerm: searchTerm,
                      plantCode: props.currentSWFilterValues.owningPlantsFilter.map(x => x.guid)[0],
                      plantSource: props.currentSWFilterValues.owningPlantsFilter.map(x => x.source)[0],
                      onlyActive: onlyActive
                    }))
                }
                masterDataSection={materials}
                itemFormatter={item =>
                  <span>
                    {item.code}
                    <br />
                    {item.value}
                  </span>
                }
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveStatusMaterials}
                onlyActiveStateChange={val => setOnlyActiveStatusMaterials(val)}
              />
            </div>
          }
          {shouldDisplayFilter("hazardCategoriesFilter") &&
            typesFilter.find(({ value }) => value === "TLMRC"
              || value === "MFGRC")
            && (rcType
              && rcType === RCTypes.Notice) && (
              <div>
                <MasterDataInput
                  label="Hazard Category"
                  selectedItems={props.currentSWFilterValues.hazardCategoriesFilter}
                  masterDataSection={hazardCategories}
                  loadItems={() => dispatch(loadHazardCategories({ onlyActive: true }))}
                  onAddItem={(item) => props.onAddItem(item, "hazardCategories")}
                  onRemoveItem={(item) =>
                    props.onRemoveItem(item, "hazardCategories")
                  }
                  itemFormatter={(item) => item.value}
                  allowMultiple={true}
                  showStatusFilter={props.showStatusFilter}
                  disabled={false}
                />
              </div>
            )}

          {shouldDisplayFilter("groupsFilter") && (
            <div>
              <MasterDataInput
                label="Group"
                selectedItems={props.currentSWFilterValues.groupsFilter}
                masterDataSection={groups}
                allowMultiple={false}
                loadItems={() => dispatch(loadGroups({
                  plantCode: props.currentSWFilterValues.owningPlantsFilter[0]?.code,
                  materialCode: props.currentSWFilterValues.materialsFilter[0]?.code,
                  plantSource: props.currentSWFilterValues.owningPlantsFilter[0]?.source
                }))}
                onAddItem={(item) => props.onAddItem(item, "groups")}
                onRemoveItem={(item) =>
                  props.onRemoveItem(item, "groups")
                }
                itemFormatter={(item) => item.value}
                showStatusFilter={props.showStatusFilter}
                disabled={false}
              />
            </div>
          )}

          {shouldDisplayFilter("groupCounterFilter") && (
            <div>
              <MasterDataInput
                label="Group Counter"
                selectedItems={props.currentSWFilterValues.groupCounterFilter}
                masterDataSection={groupCounter}
                loadItems={() => dispatch(loadGroupCounter({
                  plantCode: props.currentSWFilterValues.owningPlantsFilter[0]?.code,
                  materialCode: props.currentSWFilterValues.materialsFilter[0]?.code,
                  group: props.currentSWFilterValues.groupsFilter[0]?.code,
                  plantSource: props.currentSWFilterValues.owningPlantsFilter[0]?.source
                }))}
                onAddItem={(item) => props.onAddItem(item, "groupCounter")}
                onRemoveItem={(item) =>
                  props.onRemoveItem(item, "groupCounter")
                }
                itemFormatter={(item) => item.value}
                allowMultiple={false}
                showStatusFilter={props.showStatusFilter}
                disabled={false}
              />

            </div>
          )}

          {shouldDisplayFilter("workCentersFilter") && (
            <div>
              <MasterDataInput
                label={"Work Centers"}
                isMandatory={false}
                isAsyncSearch={true}
                selectedItems={props.currentSWFilterValues.workCentersFilter}
                allowMultiple={true}
                onAddItem={item => props.onAddItem(item, "workCenters")}
                onRemoveItem={item => props.onRemoveItem(item, "workCenters")}
                loadItems={
                  (searchTerm: string, onlyActive: boolean) =>
                    dispatch(searchWorkCenters({
                      searchTerm: searchTerm,
                      plantCode: props.currentSWFilterValues.owningPlantsFilter.map(x => x.guid)[0],
                      onlyActive: onlyActive,
                      plantSource: props.currentSWFilterValues.owningPlantsFilter.map(x => x.source)[0]
                    }))
                }
                masterDataSection={workCenters}
                itemFormatter={item =>
                  <span>
                    {item.code}
                    <br />
                    {item.value}
                  </span>
                }
                showStatusFilter={props.showStatusFilter}
                onlyActiveState={onlyActiveStatusWorkCenters}
                onlyActiveStateChange={val => setOnlyActiveStatusWorkCenters(val)}
              />
            </div>
          )}
        </>
      }
      {props.isRCFilters &&
        <>
          {filterData.filterFields.typesFilter[0]?.guid === SWTypes.TLMRC &&
            rednerTlmRcFilters()
          }
          {filterData.filterFields.typesFilter[0]?.guid === SWTypes.MFGRC &&
            rednerMfgRcFilters()
          }
        </>
      }
    </div>
  );

  return (
    <Modal
      isOpen={true}
      header={props.isRCFilters ? "Filter Reusable Content" : "Filter Standard Works"}
      controls={
        <>
          <button
            className="secondary-button"
            onClick={() => {
              props.clearFilter();
              props.setIsModalOpen(false);
            }}
          >
            Clear
          </button>
          <button
            className="primary-button"
            onClick={() => {
              props.applyFilter();
              props.setIsModalOpen(false);
            }}
          >
            Apply
          </button>
        </>
      }
    >
      {filterControls}
    </Modal>
  );
};

export default FilterModal;
