import React, { useRef, useEffect } from "react";
import { ImageType, INoticeComponent } from "interfaces/sw/SWInterfaces";
import BlockSpinner from "components/common/BlockSpinner";
import "./NoticeImageEditor.scoped.scss";
import { addGuidToFilename, getFileSizeError, isFilenameImage } from "utilities/fileUtilities";
import { showErrorToast } from "store/toast/toastActions";
import { useDispatch } from "react-redux";
import { getNoticeImageUrl, uploadNoticeImage } from "store/manageSW/manageSWActions";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";
import DeleteIcon from "media/icons/dls/delete.svg";
import useSelector from "store/useSelector";
import FileDropzone from "components/common/FileDropzone";

interface INoticeImageEditorProps {
  notice: INoticeComponent,
  stepGuid?: string,
  allowEdit?: boolean,
  swGuid: string,
  swVersion: number,
  imageDataDestination: ImageDataDestinations,
}

const NoticeImageEditor: React.FC<INoticeImageEditorProps> = ({
  notice,
  stepGuid,
  allowEdit,
  swGuid,
  swVersion,
  imageDataDestination,
}) => {
  const { fileUploadOperation } = useSelector(store => store.manageSW);
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!notice.image.filename
      || notice.image.imageType === ImageType.Time
      || notice.image.blobData?.isLoading
      || notice.image.filename === notice.image.blobData?.filename
      || fileUploadOperation?.isWorking) {
      // Blob url already loaded. Ignore.
      return;
    }

    dispatch(getNoticeImageUrl({
      swGuid,
      swVersion,
      noticeGuid: notice.guid,
      stepGuid,
      filename: notice.image.filename,
      destination: imageDataDestination,
      imageType: ImageType.Normal,
    }));
  }, [notice.image, notice.guid, stepGuid, swGuid, swVersion, imageDataDestination, fileUploadOperation, dispatch]);

  let img: JSX.Element | undefined;

  if (notice.image.blobData?.isLoading) {
    img = (
      <BlockSpinner />
    );
  } else if (notice.image.blobData?.errorMessage) {
    img = (
      <label className="error">
        {notice.image.blobData?.errorMessage}
      </label>
    );
  } else if (notice.image.blobData?.blobUrl && notice.image.imageType === ImageType.Normal) {
    img = (
      <>
        <img
          src={notice.image.blobData?.blobUrl}
          alt="Selected"
          className="chosen-image"
        />
        {allowEdit &&
          <img
            src={DeleteIcon}
            alt="Delete"
            title="Delete Image"
            className="delete-button icon-small"
            onClick={(e) => onDeleteImage(e)}
          />
        }
      </>
    );
  }

  const handleFile = (file : File) => {
    if (!isFilenameImage(file.name)) {
      dispatch(showErrorToast("Please select a valid image."));
      return;
    }

    const fileSizeError = getFileSizeError(file);
    if (fileSizeError) {
      dispatch(showErrorToast(fileSizeError));
      return;
    }

    try {
      addGuidToFilename(file.name);
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err));
      return;
    }

    dispatch(uploadNoticeImage({
      swGuid,
      swVersion: 0,
      file,
      noticeGuid: notice.guid,
      stepGuid,
    }));
  }

  const onFileChosen = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }

    handleFile(event.target.files[0]);
  }

  const onDeleteImage = (_event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    dispatch(uploadNoticeImage({
      swGuid,
      swVersion: 0,
      noticeGuid: notice.guid,
      stepGuid,
    }));
  }

  return (
    <>
      <div className="image-wrapper">
        {img}
        {allowEdit &&
          <>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={onFileChosen}
            />
            <div
              className="controls"
            >
              <FileDropzone onFilesDropped={([file]) => handleFile(file)} >
                <button
                  className="secondary-button choose-image"
                  onClick={() => fileInputRef.current?.click()}
                  >
                  Drop Image here or click to {notice.image.blobData?.blobUrl ? "replace" : "upload"}
                </button>
              </FileDropzone>
            </div>
          </>
        }
      </div>
    </>
  );
}

export default NoticeImageEditor;
