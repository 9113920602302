import { IApprovalRequest, ApprovalLevels, ApprovalStatuses, IApprovalResponseSubmission, IHistoricalApproval, IHistoricalApprovalResponse, ApprovalResponseTypes, IMyApprovals, ApprovalTypes, IBatchApprovalRequest, IHistoricalBatchApproval, IHistoricalBatchApprovalResponse, BatchApprovalStatuses, IApprovalHistoryData, HistoricalApprovalTypes, ApprovalSortFields, IRCUpdateApprovalRequest, RCUpdateTypes, IRCUpdateApprovalDetails, IHistoricalRCUpdateApproval, IHistoricalRCUpdateApprovalResponse, RCUpdateApprovalStatuses, Status, State, approvalPayload } from "interfaces/approvals/approvalInterfaces";
import { formatSWType, formatSWDto, formatGetSWList } from "apis/sw/swFormatters";
import { ISW } from "interfaces/sw/SWInterfaces";
import {
  IApprovalFilterFields,
  IRCUpdateApproval,
  IRCUpdateRCSWDto,
  IRCUpdateTimeMediaDto,
} from "store/approvals/approvalsTypes";
import { ISWFilterFields } from "store/swList/swListTypes";
import {
  BatchUpdateCommandTypes,
  BatchUpdateMetaTypes,
  IBatchUpdateApproval,
  IBatchUpdateCommand,
  IBatchUpdateSWMetaData,
  IBatchUpdateSWMetaDataCommand,
} from "interfaces/batchUpdates/batchUpdatesInterfaces";
import { SortDirection } from "interfaces/misc/miscInterfaces";

export function formatGetMyApprovalsResponse(response: any): IMyApprovals {
  if (!response.Approvals) {
    return {
      swApprovals: [],
      batchApprovals: [],
      rcUpdateApprovals: [],
    };
  }

  return {
    swApprovals:
      response.Approvals?.map(
        (app: any): IApprovalRequest => formatApproval(app)
      ) || [],
    batchApprovals:
      response.BatchUpdateApprovals?.map(
        (app: any): IBatchApprovalRequest => formatBatchApproval(app)
      ) || [],
    rcUpdateApprovals:
      response.RCUpdateApprovals?.map(
        (app: any): IRCUpdateApprovalRequest => formatRCUpdateApproval(app)
      ) || [],
  };
}

function formatRCUpdateApproval(app: any): IRCUpdateApprovalRequest {
  return {
    id: parseInt(app.Id, 10),
    rcId: app.RCId,
    rcGuid: app.RCGuid,
    rcVersion: app.RCVersion,
    rcName: app.RCName,
    rcDescription: app.RCDescription,
    mediaId: app.MediaId,
    mediaFilename: app.MediaFilename,
    updateType: formatRCUpdateType(app.UpdateType),
    currentLevel: formatApprovalLevel(app.CurrentLevel),
    isComplete: app.IsComplete,
    numberPending: app.NumberPending,
    owningOrgId: app.OwningOrgId,
    owningOrgCode: app.OwningOrgCode,
    owningOrgName: app.OwningOrgName,
    productCenters: app.ProductCenters,
    createdBy: app.CreatedBy,
    createdOn: new Date(app.CreatedOn),
    modifiedBy: app.ModifiedBy,
    modifiedOn: new Date(app.ModifiedOn),
    type: ApprovalTypes.RCUpdate,
    swType: formatSWType(app.SwType),
  };
}

function formatApproval(app: any): IApprovalRequest {
  return {
    id: parseInt(app.Id, 10),
    swId: app.SWId,
    swGuid: app.SWGuid,
    swVersion: parseInt(app.SWVersion, 10),
    currentLevel: formatApprovalLevel(app.CurrentLevel),
    status: formatApprovalStatus(app.Status),
    swType: formatSWType(app.SWType),
    swTitle: app.SWTitle,
    swDescription: app.SWDescription,
    swOwningOrgCode: app.SWOwningOrgCode,
    swOwningOrgName: app.SWOwningOrgName,
    swOwningPlantCode: app.SWOwningPlantCode,
    swOwningPlantName: app.SWOwningPlantName,
    createdBy: app.CreatedBy,
    createdOn: new Date(app.CreatedOn),
    modifiedBy: app.ModifiedBy,
    modifiedOn: new Date(app.ModifiedOn),
    type: ApprovalTypes.SW,
    isRCUpdate: app.IsRCUpdate,
    techContetApproval: app !== null ? getValuesFromApprovalPayload(app.UserSelected, 'techContetApproval') : null,
    techCommApproval: app !== null ? getValuesFromApprovalPayload(app.UserSelected, 'techCommApproval') : null,
    smeApproval: app !== null ? getValuesFromApprovalPayload(app.UserSelected, 'smeApproval') : null
  };
}

function getValuesFromApprovalPayload(payload: any, propName: string) {
  if (payload) {
    try {
      const data = JSON.parse(payload);
      if (data.hasOwnProperty(propName)) {
        return data[propName];
      }
    } catch (e) {
      return null;
    }
  }
  return null;
}

function formatBatchApproval(app: any): IBatchApprovalRequest {
  return {
    id: parseInt(app.Id, 10),
    swId: app.SWId,
    swGuid: app.SWGuid,
    swVersion: parseInt(app.SWVersion, 10),
    currentLevel: ApprovalLevels.Owner,
    swCount: parseInt(app.SWCount, 10),
    status: formatApprovalStatus(app.Status),
    createdBy: app.CreatedBy,
    createdOn: new Date(app.CreatedOn),
    modifiedBy: app.ModifiedBy,
    modifiedOn: new Date(app.ModifiedOn),
    type: ApprovalTypes.Batch,
    swOwningOrgCode: app.SWOwningOrgCode,
    swOwningOrgName: app.SWOwningOrgName,
  };
}

export function formatRCUpdateType(type: any): RCUpdateTypes {
  if (type === "RCSW") {
    return RCUpdateTypes.RCSW;
  } else if (type === "TIMEXml") {
    return RCUpdateTypes.TIMEXml;
  } else if (type === "TIMEMedia") {
    return RCUpdateTypes.TIMEMedia;
  }

  throw new Error(`Unrecognized RCUpdate Type: ${type}`);
}

export function formatApprovalLevel(level: any): ApprovalLevels {
  if (Object.values(ApprovalLevels).indexOf(level) > -1) {
    return ApprovalLevels[level as keyof typeof ApprovalLevels];
  }
  else {
    throw new Error(`Unrecognized ApprovalLevel: ${level}`);
  }
}

function formatApprovalStatus(status: any): ApprovalStatuses {
  if (status === "Active") {
    return ApprovalStatuses.Active;
  } else if (status === "Approved") {
    return ApprovalStatuses.Approved;
  } else if (status === "Rejected") {
    return ApprovalStatuses.Rejected;
  } else if (status === "Retracted") {
    return ApprovalStatuses.Retracted;
  }

  throw new Error(`Unrecognized ApprovalStatus: ${status}`);
}

function formatRCUpdateApprovalStatus(status: any): RCUpdateApprovalStatuses {
  if (status === "Active") {
    return RCUpdateApprovalStatuses.Active;
  } else if (status === "Approved") {
    return RCUpdateApprovalStatuses.Approved;
  } else if (status === "Rejected") {
    return RCUpdateApprovalStatuses.Rejected;
  } else if (status === "Retracted") {
    return RCUpdateApprovalStatuses.Retracted;
  } else if (status === "Completed") {
    return RCUpdateApprovalStatuses.Completed;
  }

  throw new Error(`Unrecognized ApprovalStatus: ${status}`);
}

function formatBatchApprovalStatus(status: any): BatchApprovalStatuses {

  if (status === "Active") {
    return BatchApprovalStatuses.Active;
  } else if (status === "Approved") {
    return BatchApprovalStatuses.Approved;
  } else if (status === "Rejected") {
    return BatchApprovalStatuses.Rejected;
  }

  throw new Error(`Unrecognized ApprovalStatus : ${status}`);
}

function formatApprovalResponseType(type: any): ApprovalResponseTypes {
  if (Object.values(ApprovalResponseTypes).indexOf(type) > -1) {
    return ApprovalResponseTypes[type as keyof typeof ApprovalResponseTypes];
  }
  else {
    throw new Error(`Unrecognized ApprovalResponseType: ${type}`);
  }
}

function formatAppHistories(
  serverResponse: any
): IHistoricalApprovalResponse[] {
  return serverResponse.map((a: any) => formatHistoricalApprovalResponse(a));
}

export function formatGetApprovalResponse(
  response: any
): [IApprovalRequest, ISW, IHistoricalApprovalResponse[], number, number] {
  const app = response.Approval;
  const sw = response.StandardWork;
  const his = response.ApprovalHistory;
  const totalSteps = response.TotalSteps;
  const currentTotalSteps = response.CurrentTotalSteps;
  return [
    formatApproval(app),
    formatSWDto(sw),
    formatAppHistories(his),
    totalSteps,
    currentTotalSteps,
  ];
}

export function formatGetRCUpdateApprovalResponse(
  response: any
): IRCUpdateApproval {
  return {
    reusableContentSW: formatRCUpdateRCSW(response.ReusableContentSW),
    timeMedia: formatRCUpdateTimeMedia(response.TimeMedia),
    details: formatRCUpdateDetails(response.Details),
  };
}

function formatRCUpdateTimeMedia(
  timeMedia: any
): IRCUpdateTimeMediaDto | undefined {
  if (!timeMedia) {
    return undefined;
  } else {
    return {
      fileNames: timeMedia.FileNames,
      mediaId: timeMedia.MediaId,
      originalVersionLink: timeMedia.OriginalVersionLink,
      updatedVersionLink: timeMedia.UpdatedVersionLink,
    };
  }
}

function formatRCUpdateRCSW(
  reusableContentSW: any
): IRCUpdateRCSWDto | undefined {
  if (!reusableContentSW) {
    return undefined;
  } else {
    return {
      guid: reusableContentSW.Guid,
      description: reusableContentSW.Description,
      id: reusableContentSW.Id,
      title: reusableContentSW.Title,
      version: reusableContentSW.Version,
    };
  }
}

export function formatRCUpdateDetails(details: any): IRCUpdateApprovalDetails[] {
  return details
    .map((a: any) => formatRCUpdateDetail(a));
}

export function formatRCUpdateDetail(detail: any): IRCUpdateApprovalDetails {
  return {
    requestId: detail.RequestId,
    swId: detail.SWId,
    swGuid: detail.SWGuid,
    swVersion: detail.SWVersion,
    swName: detail.SWName,
    swDescription: detail.SWDescription,
    currentLevel: formatApprovalLevel(detail.CurrentLevel),
    status: formatApprovalStatus(detail.Status),
    techContRev: detail.TechContRev,
    sMEApprover: detail.SMEApprover,
    createdBy: detail.CreatedBy,
    createdOn: new Date(detail.CreatedOn),
    modifiedBy: detail.ModifiedBy,
    modifiedOn: new Date(detail.ModifiedOn),
  };
}

export function formatGetBatchApprovalResponse(response: any,
  approvalId: number): IBatchUpdateApproval {
  return {
    id: approvalId,
    commands: formatBatchApprovalCommands(response.Commands),
    standardWorks: formatGetSWList(response).swList,
  };
}

export function formatBatchApprovalCommands(commands: any): IBatchUpdateCommand[] {
  if (!commands
    || !Array.isArray(commands)) {
    return [];
  }

  return commands
    .map((x: any): IBatchUpdateCommand => ({
      masterDataItem: {
        guid: x.MasterDataId,
        value: x.MasterDataValue,
      },
      sortOrder: x.SortOrder,
      command: x.Command as BatchUpdateCommandTypes,
      metaType: x.MasterDataType as BatchUpdateMetaTypes,
    }))
    .sort((a, b) => a.sortOrder < b.sortOrder
      ? -1
      : 1);
}

export function formatGetBatchUpdateSWMetaDataResponse(response: any): IBatchUpdateSWMetaData {
  return {
    approvalId: response.ApprovalId,
    swId: response.SWId,
    commands: formatBatchUpdateSWMetaDataCommands(response.SWMetadata),
  };
}

export function formatBatchUpdateSWMetaDataCommands(commands: any): IBatchUpdateSWMetaDataCommand[] {
  if (!commands
    || !Array.isArray(commands)) {
    return [];
  }

  return commands
    .map((x: any): IBatchUpdateSWMetaDataCommand => ({
      masterDataItem: {
        guid: x.MasterDataId,
        value: x.MasterDataValue,
      },
      sortOrder: x.SortOrder,
      command: x.Command as BatchUpdateCommandTypes,
      metaType: x.MasterDataType as BatchUpdateMetaTypes,
    }))
    .sort((a, b) => a.sortOrder < b.sortOrder
      ? -1
      : 1);
}

export function formatApprovalResponseSubmission(approvalResponse: IApprovalResponseSubmission): FormData {
  let formData = new FormData();
  if (approvalResponse.attachment) {
    formData.append("Attachment", approvalResponse.attachment);
  }
  formData.append("ApprovalRequestId", approvalResponse.approvalRequestId.toString());
  if (approvalResponse.comment) {
    formData.append("Comment", approvalResponse.comment);
  }
  if (approvalResponse.tecConRev) {
    formData.append("TechConRevEmail", approvalResponse.tecConRev);
  }
  if (approvalResponse.secondTecConRev) {
    formData.append("SecondTechConRevEmail", approvalResponse.secondTecConRev);
  }
  if (approvalResponse.smeRevUser) {
    formData.append("SMERevUser", approvalResponse.smeRevUser);
  }
  if (approvalResponse.swType) {
    formData.append("SWType", approvalResponse.swType);
  }
  formData.append("UserSelected", createUserSelected(approvalResponse.techContetApproval, approvalResponse.techCommApproval, approvalResponse.smeApproval));
  formData.append("ResponseType", approvalResponse.responseType);
  return formData;
}

export function createUserSelected(techContetApproval: Status | null, techCommApproval: Status | null, smeApproval: Status | null): string {
  var jsonObject = {
    techContetApproval: techContetApproval,
    techCommApproval: techCommApproval,
    smeApproval: smeApproval
  }
  return JSON.stringify(jsonObject);
}

export function formatBatchApprovalResponseSubmission(approvalResponse: IApprovalResponseSubmission): any {
  return {
    BatchApprovalRequestId: approvalResponse.approvalRequestId.toString(),
    Comment: approvalResponse.comment,
    ResponseType: approvalResponse.responseType.toString(),
  };
}

export function formatSwRetractSubmission(swId: number, approvalRequestId: number): FormData {
  let formData = new FormData();
  formData.append("SwId", swId.toString());
  formData.append("approvalRequestId", approvalRequestId.toString());
  return formData;
}

export function formatGetApprovalHistoryResponse(response: any): IApprovalHistoryData {
  return {
    approvals: Array.isArray(response.Approvals)
      ? response
        .Approvals
        .map((a: any) => formatHistoricalApproval(a))
      : [],
    batchApprovals: Array.isArray(response.BatchApprovals)
      ? response
        .BatchApprovals
        .map((a: any) => formatHistoricalBatchApproval(a))
      : [],
    rcUpdateApprovals: Array.isArray(response.RCUpdateApprovals)
      ? response.RCUpdateApprovals.map((a: any) => formatHistoricalRCUpdateApproval(a))
      : []
  };
}

export function formatHistoricalApproval(a: any): IHistoricalApproval {
  return {
    type: HistoricalApprovalTypes.Approval,
    id: a.Id,
    currentLevel: formatApprovalLevel(a.CurrentLevel),
    status: formatApprovalStatus(a.Status),
    createdBy: a.CreatedBy,
    createdOn: new Date(a.CreatedOn),
    modifiedBy: a.ModifiedBy,
    modifiedOn: new Date(a.ModifiedOn),
    responses: a.Responses
      && Array.isArray(a.Responses)
      ? a.Responses.map((r: any) => formatHistoricalApprovalResponse(r))
      : [],
    swId: a.SWId,
    swVersion: a.SWVersion,
    swGuid: a.SWGuid,
  };
}

export function formatHistoricalRCUpdateApproval(
  a: any
): IHistoricalRCUpdateApproval {
  return {
    type: HistoricalApprovalTypes.RCUpdateApproval,
    id: a.RequestId,
    currentLevel: formatApprovalLevel(a.CurrentLevel),
    status: formatRCUpdateApprovalStatus(a.Status),
    createdBy: a.CreatedBy,
    createdOn: new Date(a.CreatedOn),
    modifiedBy: a.ModifiedBy,
    modifiedOn: new Date(a.ModifiedOn),
    responses:
      a.Responses && Array.isArray(a.Responses)
        ? a.Responses.map((r: any) =>
          formatHistoricalRCUpdateApprovalResponse(r)
        )
        : [],
    swId: a.SWId,
    swVersion: a.SWVersion,
    swGuid: a.SWGuid,
    swName: a.SWName,
    swDescription: a.SWDescription,
    techContRev: a.TechContRev,
    smeApprover: a.smeApprover,
  };
}

export function formatHistoricalApprovalResponse(r: any): IHistoricalApprovalResponse {
  return {
    id: r.Id,
    responseType: formatApprovalResponseType(r.Type),
    comment: r.Comment,
    level: formatApprovalLevel(r.Level),
    attachmentFilename: r.AttachmentFilename,
    createdBy: r.CreatedBy,
    createdOn: new Date(r.CreatedOn),
  };
}

export function formatHistoricalRCUpdateApprovalResponse(
  r: any
): IHistoricalRCUpdateApprovalResponse {
  return {
    id: r.Id,
    responseType: formatApprovalResponseType(r.Type),
    comment: r.Comment,
    level: formatApprovalLevel(r.Level),
    attachmentFilename: r.AttachmentFilename,
    createdBy: r.CreatedBy,
    createdOn: new Date(r.CreatedOn),
    rcUpdateApprovalRequestId: r.RCUpdateApprovalRequestId,
    swId: r.SWId,
  };
}

export function formatSearchApprovalsRequest(swFilterFields: ISWFilterFields,
  approvalFilterFields: IApprovalFilterFields,
  sortBy: ApprovalSortFields,
  sortDir: SortDirection,): any {
  let mtTaskIds = (swFilterFields.maintenanceTaskIdsFilter
    && swFilterFields.maintenanceTaskIdsFilter?.trim() !== "") ?
    swFilterFields.maintenanceTaskIdsFilter?.split(",") : [];
  if (mtTaskIds.length !== 0) {
    mtTaskIds = mtTaskIds.map(r => r.trim());
  }
  return {
    FilterOptions: {
      SearchTerm: swFilterFields.searchText,
      ApprovalLevels: approvalFilterFields.approvalLevelsFilter.map(x => x.guid),
      Types: swFilterFields.typesFilter.map(x => x.guid),
      GlobalOrLocalTypes: swFilterFields.globalOrLocalTypesFilter.map(x => x.guid),
      TaskAlignments: swFilterFields.alignmentsFilter.map(x => x.guid),
      LanguageIds: swFilterFields.languagesFilter.map(x => x.guid),
      EquivalentSWIds: swFilterFields.equivalentSWsFilter.map(x => x.guid),
      OwningOrgIds: swFilterFields.owningOrgsFilter.map(x => x.guid),
      TaskIds: swFilterFields.tasksFilter.map(x => x.guid),
      ActivityWorkFlowIds: swFilterFields.activityWorkflowsFilter.map(x => x.guid),
      PerformingOrgIds: swFilterFields.performingOrgsFilter.map(x => x.guid),
      SubBusinessLines: swFilterFields.subBusinessLinesFilter.map(x => x.guid),
      ServiceTypeIds: swFilterFields.serviceTypesFilter.map(x => x.guid),
      OperatingEnvironmentIds: swFilterFields.operatingEnvironmentsFilter.map(x => x.guid),
      EquipmentIds: swFilterFields.equipmentFilter.map(x => x.guid),
      CountryIds: swFilterFields.countriesFilter.map(x => x.guid),
      CompetencyElementIds: swFilterFields.competencyElementsFilter.map(x => parseInt(x.guid, 10)),
      SafetyCategoryIds: swFilterFields.safetyCategoriesFilter.map(x => x.guid),
      BusinessRoleIds: swFilterFields.businessRolesFilter.map(x => x.guid),
      ProductCenterIds: swFilterFields.productCentersFilter.map(x => x.guid),
      MaintenanceTaskIds: mtTaskIds,
      FeedbackTypes: swFilterFields.feedbackTypesFilter.map(x => x.guid),
      EpicEquipmentIds: swFilterFields.epicEquipmentFilter.map(x => x.guid),
      GeoUnitIds: swFilterFields.geoUnitsFilter.map(x => x.guid),
      CustomerIds: swFilterFields.customersFilter.map(x => x.guid),
      OwningPlantIds: swFilterFields.owningPlantsFilter.map(x => x.guid),
      PerformingPlantIds: swFilterFields.performingPlantsFilter.map(x => x.guid),
      MaterialIds: swFilterFields.materialsFilter.map(x => x.guid),
      WorkCenterIds: swFilterFields.workCentersFilter.map(x => x.guid),
      RCType: swFilterFields.rcType,
      HazardCategoryIds: swFilterFields.hazardCategoriesFilter.map(x => x.guid),
      Groups: swFilterFields.groupsFilter.map(x => x.guid),
      GroupCounters: swFilterFields.groupCounterFilter.map(x => x.guid),
    },
    sortBy: sortBy,
    sortDir: sortDir,
  };
}

export function formatHistoricalBatchApproval(a: any): IHistoricalBatchApproval {
  return {
    type: HistoricalApprovalTypes.BatchApproval,
    id: a.Id,
    status: formatBatchApprovalStatus(a.Status),
    originalOwningOrgGuid: a.OriginalOwningOrgGuid,
    createdBy: a.CreatedBy,
    createdOn: new Date(a.CreatedOn),
    modifiedBy: a.ModifiedBy,
    modifiedOn: new Date(a.ModifiedOn),
    responses: a.Responses
      && Array.isArray(a.Responses)
      ? a.Responses.map((r: any) => formatHistoricalBatchApprovalResponse(r))
      : [],
    swId: a.SWId,
    swVersion: a.SWVersion,
    swGuid: a.SWGuid,
  };
}

export function formatHistoricalBatchApprovalResponse(r: any): IHistoricalBatchApprovalResponse {
  return {
    id: r.Id,
    responseType: formatApprovalResponseType(r.Type),
    comment: r.Comment,
    createdBy: r.CreatedBy,
    createdOn: new Date(r.CreatedOn),
  };
}
