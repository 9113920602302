import React from "react";
import "./StepListSelections.scoped.scss";
import { IStep } from "interfaces/sw/SWInterfaces";
import Nestable, { Item } from "react-nestable";
import dragIcon from "media/icons/dls/drag.svg";
import arrowRight from "media/icons/dls/arrow-right-3.svg";
import { useDispatch } from "react-redux";
import { addReusableCompItemChecked, removeReusableCompItemChecked, setReusableCompMode } from "store/manageSW/manageSWActions";
import useSelector from "store/useSelector";

interface IStepListSelectionsProps {
  steps: IStep[],
  ancestors: IStep[],
  isDisabled: boolean,
}

const StepListSelections: React.FC<IStepListSelectionsProps> = ({
  steps,
  ancestors,
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const parentOrders = ancestors.map(st => st.sortOrder);
  const {
    ReusableCompSortable,
  } = useSelector(store => store.manageSW);

  const onReorder = (items: Item[]) => {
    
  }

  const confirmChange = (dragItem: Item, destinationParent: Item | null) => {
    return false;
  };

  const handleCheckBoxClick = (e: React.ChangeEvent<HTMLInputElement>, item: Item) => {
    if (e.target.checked) {
     dispatch(addReusableCompItemChecked(item));
    }
    else {
      dispatch(removeReusableCompItemChecked(item));
    }
    dispatch(setReusableCompMode());
  }

  return (
    <>
    <ol
      className={!parentOrders.length
        ? "top-level"
        : ""}
    >
      <Nestable
        className="Nestable"
        items={ReusableCompSortable}
        renderItem={({ item, index, collapseIcon }) => (
          <div
            className={`Nestable ${item.hasUnResolvedComments ? "unresolvedComments" : undefined} ${item.hasMasterStep ? "hasMasterStep" : undefined}`}

          >
            {item.isEnabled ?
              <input type="Checkbox" checked={item.checked} onChange={e => handleCheckBoxClick(e, item)}></input>
              : <input type="Checkbox" disabled checked={item.checked} onChange={e => handleCheckBoxClick(e, item)}></input>}

            {collapseIcon === null ?
              <img
                className="icon-small"
                alt="drag"
                src={dragIcon} /> :
              collapseIcon}
            {item.parentIndex === undefined ?
              null :
              item.parentIndex}
            {(isNaN((index + 1)) ?
              "" :
              (index + 1).toString()) + ". " + item.text}
          </div>
        )}
        maxDepth={4}
        onChange={(arg) => onReorder(arg.items)}
        collapsed={false}
        renderCollapseIcon={({ isCollapsed }) => isCollapsed ? (
          <img
            className="icon-small"
            alt="drag"
            src={arrowRight} />
        ) : (
          <img
            className="icon-small"
            alt="drag"
            src={dragIcon} />
        )}
        confirmChange={(arg) => confirmChange(arg.dragItem, arg.destinationParent)} 
      />
    </ol></>
    
  );
}

export default StepListSelections;