import { createAction } from "@reduxjs/toolkit";
import { ISWListData, ISWCreateDraftConfirmData, ISWArchiveData, ISWDownloadPDFData, ISWDeleteDraftData, ISWUserFeedbacksData, ISWUserFeedbackAckData, ISWCreateLCLData, IGetRCMappingData, IZipList, ISWBulkDeleteDraftData, ITimeRCRequestData, ISWCreateDraftData } from "./swListTypes";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { MetaDataNames } from "store/masterData/masterDataTypes";
import { ISWUserFeedbackAck, RCTypes, SWSortFields } from "interfaces/sw/SWInterfaces";
import { SortDirection } from "interfaces/misc/miscInterfaces";

export const resetAllExceptFilters = createAction("swList/resetAllExceptFilters");
export const getData = createAction("swList/getData");
export const setData = createAction<ISWListData>("swList/setData");
export const setSearchText = createAction<string>("swList/setSearchText");
export const setNewIndex = createAction<number>("manageJob/setNewIndex");

// Filtering
export const clearList = createAction("swList/clearList");
export const toggleFilterModal = createAction<boolean>("swList/toggleFilterModal");
export const applyFilter = createAction<{ isRCFilter: boolean, onlyTask: boolean, isSourceSystemTIME: boolean, rcTypeButton?: RCTypes, searchSource: string }>("swList/applyFilter");
export const clearFilter = createAction("swList/clearFilter");
export const addFilterItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("swList/addFilterItem");
export const addFilterItems = createAction<{
  metaDataName: MetaDataNames,
  items: IMasterDataItem[],
}>("swList/addFilterItems");
export const removeFilterItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("swList/removeFilterItem");
export const setStringFilter = createAction<{
  attributeName: "maintenanceTaskIds",
  value: string
}>("swList/setStringFilter");
export const setSort = createAction<{
  sortBy: SWSortFields,
  sortDir: SortDirection
}>("swList/setSort");
export const setShowOnlyTasks = createAction<boolean | undefined>(
  "swList/setShowOnlyTasks"
);
export const setRCType = createAction<RCTypes | undefined>(
  "swList/setRCType"
);

// Create Draft
export const showCreateDraftConfirmation = createAction<ISWCreateDraftData>("swList/showCreateDraftConfirmation");
export const cancelCreateDraft = createAction("swList/cancelCreateDraft");
export const confirmCreateDraft = createAction<ISWCreateDraftData>("swList/confirmCreateDraft");
export const setCreateDraftData = createAction<ISWCreateDraftConfirmData>("swList/setCreateDraftData");

// Create LCL
export const showCreateLCLConfirmation = createAction<{ swGuid: string, swVersion: number }>("swList/showCreateLCLConfirmation");
export const cancelCreateLCL = createAction("swList/cancelCreateLCL");
export const confirmCreateLCL = createAction<{ swGuid: string, swVersion: number }>("swList/confirmCreateLCL");
export const setCreateLCLData = createAction<ISWCreateLCLData>("swList/setCreateLCLData");

// RC Mapping
export const getRCWhereUsedItems = createAction<{ rcID: number }>("swList/getRCWhereUsedItems");
export const setRCWhereUsedItems = createAction<IGetRCMappingData>("swList/setRCWhereUsedItems");
export const cancelRCMappingModal = createAction("swList/cancelRCMappingModal");
export const createRCDraft = createAction<{ rcID: number }>("swList/createRCDraft");

//Zip 
export const addToZipSWList = createAction<{ swId: number }>("swList/addToZipSWList");
export const removeFromZipSWList = createAction<{ swId: number }>("swList/removeFromZipSWList");
export const getDownloadZip = createAction<IZipList[]>("swList/getDownloadZip");
export const addDownloadZipData = createAction<{ requestID: string, swListZip: IZipList[] }>("swList/addDownloadZipData");
export const removeDownloadZipData = createAction<{ requestID: string }>("swList/removeDownloadZipData");
export const clearDownloadZip = createAction("swList/clearDownloadZip");
export const clearZipSWList = createAction("swList/clearZipSWList");

// Archive
export const showArchiveConfirmation = createAction<{ swGuid: string }>("swList/showArchiveConfirmation");
export const cancelArchive = createAction("swList/cancelArchive");
export const confirmArchive = createAction<{ swGuid: string }>("swList/confirmArchive");
export const setArchiveData = createAction<ISWArchiveData>("swList/setArchiveData");
export const addArchiveData = createAction<{ requestID: string, swListZip: IZipList[] }>("swList/addArchiveData");
export const removeArchiveData = createAction<{ requestID: string }>("swList/removeArchiveData");
export const getArchiveRequest = createAction<IZipList[]>("swList/getArchiveRequest");
export const clearArchiveRequest = createAction("swList/clearArchiveRequest");
export const getRefreshSearchData = createAction("swList/getRefreshSearchData");

// Download Field PDF
export const getPDF = createAction<{ swId: number, isTrainingPDF: boolean, isExecutablePdf: boolean, isReferencePdf: boolean, isCCQPdf: boolean }>("swList/getPDF");
export const setPDFData = createAction<ISWDownloadPDFData>("swList/setPDFData");

// Delete Draft
export const showDeleteDraftConfirmation = createAction<{ swGuid: string, currentEditor: string | undefined, currentEditorLockedDate: Date | undefined }>("swList/showDeleteDraftConfirmation");
export const cancelDeleteDraft = createAction("swList/cancelDeleteDraft");
export const confirmDeleteDraft = createAction<{ swGuid: string, currentEditor: string | undefined, currentEditorLockedDate: Date | undefined }>("swList/confirmDeleteDraft");
export const confirmBulkDeleteDraft = createAction<IZipList[]>("swList/confirmBulkDeleteDraft");
export const setDeleteDraftData = createAction<ISWDeleteDraftData>("swList/setDeleteDraftData");
export const setBulkDeleteDraftData = createAction<ISWBulkDeleteDraftData>("swList/setBulkDeleteDraftData");

// Standard work User feebacks
export const loadSWUserFeedbacks = createAction<{ swGuid: string }>("swList/loadSWUserFeedbacks");
export const setSWUserFeedbacks = createAction<ISWUserFeedbacksData>("swList/setSWUserFeedbacks");
export const saveSWUserFeedbackAcks = createAction<{ swGuid: string, swUserFeedbackAcks: ISWUserFeedbackAck[] }>("swList/saveSWUserFeedbackAcks");
export const setSWUserFeedbackAcks = createAction<ISWUserFeedbackAckData>("swList/setSWUserFeedbackAcks");
export const OnChangeSWUserFeedback = createAction<{ id: number, acknowledge: boolean, response: string }>("swList/OnChangeSWUserFeedback");
export const OnChangeSWUserFeedbackAck = createAction<{ id: number, acknowledge: boolean }>("swList/OnChangeSWUserFeedbackAck");

// Clear TIME RC updates in TLM SW data
export const clearTimeRCRequestData = createAction("swList/clearTimeRCRequestData");
export const getTimeRCProcessingRequestStatus = createAction<{ requestIds: string }>("swList/getTimeRCProcessingRequestStatus");
export const setTimeRCRequestData = createAction<ITimeRCRequestData>("swList/setTimeRCRequestData");
export const removeTimeRCRequestData = createAction<{ requestId: string }>("swList/removeTimeRCRequestData");
