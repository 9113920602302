import React, { useEffect, useRef } from "react";
import { IVideoComponent } from "interfaces/sw/SWInterfaces";
import "./VideoEditor.scoped.scss";
import BlockSpinner from "components/common/BlockSpinner";
import { addGuidToFilename, getVideoSizeError, isFilenameVideo, videoFilter } from "utilities/fileUtilities";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import { getVideoUrl, uploadVideo } from "store/manageSW/manageSWActions";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";
import useSelector from "store/useSelector";
import FileDropzone from "components/common/FileDropzone";

interface IVideoEditorProps {
    video: IVideoComponent,
    allowEdit: boolean,
    swGuid: string,
    swVersion: number,
    stepGuid: string,
    imageDataDestination: ImageDataDestinations,
}

const VideoEditor: React.FC<IVideoEditorProps> = ({
    video,
    allowEdit,
    swGuid,
    swVersion,
    stepGuid,
    imageDataDestination,
}) => {
    const { fileUploadOperation } = useSelector(store => store.manageSW);
    const dispatch = useDispatch();
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!video.filename
            || video.blobData?.isLoading
            || video.filename === video.blobData?.filename
            || fileUploadOperation?.isWorking) {
            // blobl url already loaded. ignore.
            return;
        }

        dispatch(getVideoUrl({
            swGuid,
            swVersion,
            stepGuid,
            componentGuid: video.guid,
            filename: video.filename,
            destination: imageDataDestination,
        }));
    }, [video, stepGuid, swGuid, swVersion, imageDataDestination, fileUploadOperation, dispatch]);

    const handleFile = (file : File) => {
        if (!isFilenameVideo(file.name)) {
            dispatch(showErrorToast("Please select a valid video."));
            return;
        }

        const fileSizeError = getVideoSizeError(file);
        if (fileSizeError) {
            dispatch(showErrorToast(fileSizeError));
            return;
        }

        try {
            addGuidToFilename(file.name);
        } catch (err: any) {
            dispatch(showErrorToast(err.message || err));
            return;
        }

        dispatch(uploadVideo({
            swGuid,
            swVersion: 0,
            file,
            stepGuid,
            componentGuid: video.guid,
        }));
    }

    const onFileChosen = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files?.length) {
            return;
        }

        handleFile(event.target.files[0]);
    }

    let vid: JSX.Element | undefined;

    if (video.blobData?.isLoading) {
        vid = (
            <BlockSpinner />
        );
    } else if (video.blobData?.errorMessage) {
        vid = (
            <label className="error">
                {video.blobData?.errorMessage}
            </label>
        );
    } else if (video.blobData?.blobUrl) {
        vid = (
            <video controls className="embed">
                <source src={video.blobData?.blobUrl} />
            </video>
        );
    }

    return (
        <>
            <div>
                {vid}
                {allowEdit &&
                    <>
                        <input
                            type="file"
                            accept="video/mp4,video/avi,video/quicktime"
                            ref={fileInputRef}
                            onChange={onFileChosen}
                        />
                        <div
                            className="controls"
                        >
                            <FileDropzone onFilesDropped={([file]) => handleFile(file)}>
                                <button
                                    className="secondary-button choose-video"
                                    onClick={() => fileInputRef.current?.click()}
                                    title={videoFilter}
                                >
                                    {video.blobData?.blobUrl ? "Drop Video here or click to replace" : "Drop Video here or click to upload"}
                                </button>
                            </FileDropzone>
                        </div>
                    </>
                }
            </div>
        </>
    );
}

export default VideoEditor;