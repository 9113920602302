import { SWTypes } from "interfaces/sw/SWInterfaces";

export interface IApprovalRequest {
  id: number,
  swId: number,
  swGuid: string,
  swVersion: number,
  currentLevel: ApprovalLevels,
  status: ApprovalStatuses,
  swType: SWTypes,
  swTitle: string,
  swDescription: string,
  swOwningOrgCode: string,
  swOwningOrgName: string,
  swOwningPlantCode: string,
  swOwningPlantName: string,
  createdBy: string,
  createdOn: Date,
  modifiedBy: string,
  modifiedOn: Date,
  type: ApprovalTypes.SW,
  isRCUpdate: boolean,
  techContetApproval: Status | null,
  techCommApproval: Status | null,
  smeApproval: Status | null
}

export type approvalPayload = {
  includeTechcom: boolean | null,
  techContetApproval: Status | null,
  techCommApproval: Status | null,
  smeApproval: Status | null
}

export enum ApprovalSortFields {
  Title = "Title",
  ModifiedOn = "ModifiedOn",
  Org = "Org",
}

export enum State {
  NotSelected = "NotSelected",
  Selected = "Selected"
}

export enum ApprovalLevels {
  Author = "Author",
  COE = "COE",
  TechContent = "TechContent",
  Owner = "Owner",
  TechComm = "TechComm",
  TechConRev = "TechConRev",
  LocalTechConApp = "LocalTechConApp",
  SME = "SME",
  None = "None",
}

export enum ApprovalTypes {
  SW,
  Batch,
  RCUpdate,
}

export enum ApprovalStatuses {
  Active = "Active",
  Approved = "Approved",
  Rejected = "Rejected",
  Retracted = "Retracted",
}

export enum RCUpdateApprovalStatuses {
  Active = "Active",
  Approved = "Approved",
  Rejected = "Rejected",
  Retracted = "Retracted",
  Completed = "Completed",
}

export enum Status {
  NotSelected = "NotSelected",
  Selected = "Selected"
}

export enum BatchApprovalStatuses {
  Active = "Active",
  Approved = "Approved",
  Rejected = "Rejected",
}

export interface IApprovalResponseSubmission {
  approvalRequestId: number,
  comment: string | null,
  responseType: ApprovalResponseTypes,
  attachment: File | null,
  tecConRev: string | null,
  secondTecConRev: string | null,
  smeRevUser: string | null,
  swType?: SWTypes,
  techContetApproval: Status | null,
  techCommApproval: Status | null,
  smeApproval: Status | null,
}

export enum ApprovalResponseTypes {
  Approved = "Approved",
  Rejected = "Rejected",
  Retracted = "Retracted",
  AutoApproved = "AutoApproved",
  Revert = "Revert",
  Active = "Active",
  NotCompleted = "NotCompleted",
  Skip = "Skip"
}

export interface IApprovalHistoryData {
  approvals: IHistoricalApproval[],
  batchApprovals: IHistoricalBatchApproval[],
  rcUpdateApprovals: IHistoricalRCUpdateApproval[],
}

export enum HistoricalApprovalTypes {
  Approval = "Approval",
  BatchApproval = "BatchApproval",
  RCUpdateApproval = "RCUpdateApproval"
}

export interface IHistoricalApproval {
  type: HistoricalApprovalTypes.Approval,
  id: number,
  currentLevel: ApprovalLevels,
  status: ApprovalStatuses,
  createdBy: string,
  createdOn: Date,
  modifiedBy: string,
  modifiedOn: Date,
  responses: IHistoricalApprovalResponse[],
  swId: number,
  swGuid: string,
  swVersion: number,
}

export interface IApprovalWorkFlowResponse{
  responseType: ApprovalResponseTypes,
  optional: boolean | null,
  level: ApprovalLevels,
  createdBy: string,
  createdOn: Date,
}

export interface IHistoricalApprovalResponse {
  id: number,
  responseType: ApprovalResponseTypes,
  comment: string,
  level: ApprovalLevels,
  attachmentFilename: string | null,
  createdBy: string,
  createdOn: Date,
}

export interface IHistoricalRCUpdateApproval {
  id: number,
  swId: number,
  swGuid: string,
  swVersion: number,
  swName: string,
  swDescription: string,
  currentLevel: ApprovalLevels,
  status: RCUpdateApprovalStatuses,
  techContRev: string,
  smeApprover: string,
  createdBy: string,
  createdOn: Date,
  modifiedBy: string,
  modifiedOn: Date,
  type: HistoricalApprovalTypes.RCUpdateApproval,
  responses: IHistoricalRCUpdateApprovalResponse[],
}

export interface IHistoricalRCUpdateApprovalResponse {
  id: number;
  rcUpdateApprovalRequestId: number;
  swId: number;
  responseType: ApprovalResponseTypes;
  comment: string;
  level: ApprovalLevels;
  attachmentFilename: string;
  createdBy: string;
  createdOn: Date;
}

export interface IBatchApprovalRequest {
  id: number,
  swId: number,
  swGuid: string,
  swVersion: number,
  currentLevel: ApprovalLevels,
  status: ApprovalStatuses,
  swCount: number,
  createdBy: string,
  createdOn: Date,
  modifiedBy: string,
  modifiedOn: Date,
  type: ApprovalTypes.Batch,
  swOwningOrgName: string,
  swOwningOrgCode: string,
}

export interface IRCUpdateApprovalRequest {
  id: number,
  rcId?: number,
  rcGuid: string,
  rcVersion?: number,
  rcName: string,
  rcDescription: string,
  mediaId: string,
  mediaFilename: string,
  updateType: RCUpdateTypes,
  currentLevel: ApprovalLevels,
  isComplete: boolean,
  numberPending: number,
  owningOrgId: string,
  owningOrgCode: string,
  owningOrgName: string,
  productCenters: string,
  createdBy: string,
  createdOn: Date,
  modifiedBy: string,
  modifiedOn: Date,
  type: ApprovalTypes.RCUpdate,
  swType: SWTypes,
}

export interface IRCUpdateApprovalDetails {
  requestId: number,
  swId: number,
  swGuid: string,
  swVersion: number,
  swName: string,
  swDescription: string,
  currentLevel: ApprovalLevels,
  status: ApprovalStatuses,
  techContRev: string,
  sMEApprover: string,
  createdBy: string,
  createdOn: Date,
  modifiedBy: string,
  modifiedOn: Date,
}

export enum RCUpdateTypes {
  RCSW,
  TIMEXml,
  TIMEMedia,
}

export interface IMyApprovals {
  swApprovals: IApprovalRequest[],
  batchApprovals: IBatchApprovalRequest[],
  rcUpdateApprovals: IRCUpdateApprovalRequest[],
}

export interface IHistoricalBatchApproval {
  type: HistoricalApprovalTypes.BatchApproval,
  id: number,
  status: BatchApprovalStatuses,
  originalOwningOrgGuid: string,
  createdBy: string,
  createdOn: Date,
  modifiedBy: string,
  modifiedOn: Date,
  responses: IHistoricalBatchApprovalResponse[],
  swId: number,
  swGuid: string,
  swVersion: number,
}

export interface IHistoricalBatchApprovalResponse {
  id: number,
  responseType: ApprovalResponseTypes,
  comment: string,
  createdBy: string,
  createdOn: Date,
}

export enum LockReasonTypes {
  BatchUpdateProcessing = "BatchUpdateProcessing",
  RCUpdateProcessing = "RCUpdateProcessing",
  TimeRCProcessing = "TimeRCProcessing"
}