import { ApprovalLevels, ApprovalResponseTypes, IApprovalWorkFlowResponse } from "interfaces/approvals/approvalInterfaces";

//for SWI, CL
export const psdApprovalWorkFlow: IApprovalWorkFlowResponse[] = [
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.Author,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
  },
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.TechContent,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
  },
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.SME,
    optional: true,
    createdBy: "",
    createdOn: new Date(),
  },
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.COE,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
  },
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.Owner,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
  }
]


export const LCLapprovalWorkFlow: IApprovalWorkFlowResponse[] = [
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.Author,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
  },
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.LocalTechConApp,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
  },
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.SME,
    optional: true,
    createdBy: "",
    createdOn: new Date(),
  }
]

export const MFGApprovalWorkflow: IApprovalWorkFlowResponse[] = [
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.Author,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
  },
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.SME,
    optional: true,
    createdBy: "",
    createdOn: new Date(),
  },
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.TechContent,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
  },
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.TechContent,
    optional: true,
    createdBy: "",
    createdOn: new Date(),
  }
]

export const MFGRCApprovalWorkFlow: IApprovalWorkFlowResponse[] = [
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.Author,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
  },
  {
    responseType: ApprovalResponseTypes.NotCompleted,
    level: ApprovalLevels.Owner,
    optional: false,
    createdBy: "",
    createdOn: new Date(),
  }
]
