import React, { useEffect, useState } from "react";
import Modal from '../../../common/Modal'
import searchIcon from "media/icons/dls/search.svg";
import filterIcon from "media/icons/dls/filter.svg";
import "./AddRCModal.scoped.scss";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import useDebounce from "utilities/useDebounce";
import { setSearchText } from "store/manageSW/manageSWActions";

import {
  addFilterItem,
  applyFilter,
  clearFilter,
  removeFilterItem,
  setRCType,
  setStringFilter,
} from "store/swList/swListActions";

import FilterModal from "components/common/FilterModal";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { MetaDataNames } from "store/masterData/masterDataTypes";
import { clearSearchedMasterDataItems } from "store/masterData/masterDataActions";
import RCList from "./RCList";
import RCListTime from "./RCListTime";
import { RCTypes, SWTypes } from "interfaces/sw/SWInterfaces";


interface IAddRCModal {
  onModalClick?(): void,
  header: string,
  onlyTask: boolean,
  AddRCStepsToSw(guid: string, version: number, source: string): void
  AddRCTimeStepsToSw(guid: string, version: number, source: string, rcType: string): void
  stepGuid?: string,
  allowedSourceSystems: string[],
  defaultSourceSystem: string,
  hideSourceSystems: boolean,
  rcTypeButton: RCTypes,
}

const AddRCModal: React.FC<IAddRCModal> = (props) => {
  const TimeSystem: string = "TIME";
  const WRSystem: string = "WR";
  const [searchTerm, setSearchTerm] = useState("");
  const [isFilterModalOpen, setFilterIsModalOpen] = useState(false);
  const [sourceSystem, setSourceSystem] = useState(props.defaultSourceSystem);
  const [filterStatus, setFilterStatus] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 750);
  const {
    swList: {
      swListData: {
        swList,
        loadOperation,
      },
      filterData,
    },
    manageSW: {
      SW,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();

  useEffect(() => {
      if (SW.type === SWTypes.TLMSWI) {   
      let item: IMasterDataItem = {
        guid: "TLMRC",
        code: "TLMRC",
        value: "",
      };
      dispatch(
        addFilterItem({
          metaDataName: "types",
          item
        })
        )
      // Passed MFGRC type
        if(props.rcTypeButton == RCTypes.Step ||
          props.rcTypeButton == RCTypes.SubStep) {
          let item1: IMasterDataItem = {
            guid: "MFGRC",
            code: "MFGRC",
            value: "",
          };
          dispatch(
            addFilterItem({
              metaDataName: "types",
              item: item1
            })
          )
        }
        
    } else if (SW.type === SWTypes.MFGSWI || SW.type === SWTypes.MFGRC) {
      let item: IMasterDataItem = {
        guid: "MFGRC",
        code: "MFGRC",
        value: "",
      };
      dispatch(
        addFilterItem({
          metaDataName: "types",
          item
        })
       )
       let owningPlantCode = "";
       if (SW !== undefined && SW.owningPlant !== undefined && SW.owningPlant.code !== undefined) {
          owningPlantCode = SW.owningPlant?.code
       }

       let item2: IMasterDataItem = {
        guid: owningPlantCode,
        code: SW.owningPlant?.code,
        value: "",
       };
       dispatch(
         addFilterItem({
           metaDataName: "currentOwningPlants",
           item: item2
         })
        )
    }
    else if (SW.type === SWTypes.TLMRC) {
      let item: IMasterDataItem = {
        guid: SW.type,
        code: SW.type,
        value: "",
      };
      dispatch(
        addFilterItem({
          metaDataName: "types",
          item
        })
      )
    }

    if (filterStatus || (searchTerm && searchTerm.trim() !== "")) {
      dispatch(setSearchText({
        searchText: debouncedSearchTerm.trim(),
        onlyTask: props.onlyTask,
        isSourceSystemTIME: (sourceSystem === TimeSystem),
        rcTypeButton: props.rcTypeButton,
        searchSource: "AddRCModal" + SW.type
      }));
    }
  }, [debouncedSearchTerm, dispatch, props.onlyTask, sourceSystem, filterStatus, SW.type]);

  const setIsModelOpenOnClick = () => {
    setFilterIsModalOpen(true);
    dispatch(clearSearchedMasterDataItems());

    if (SW.type === SWTypes.TLMSWI) {
      let item: IMasterDataItem = {
        guid: "TLMRC",
        code: "TLMRC",
        value: "",
      };
      dispatch(
        addFilterItem({
          metaDataName: "types",
          item
        })
      );
    } else if (SW.type === SWTypes.MFGSWI) {
      let item: IMasterDataItem = {
        guid: "MFGRC",
        code: "MFGRC",
        value: "",
      };
      dispatch(
        addFilterItem({
          metaDataName: "types",
          item
        })
        );
    }
  }

  const checkFilter = () => {
    if (filterData.filterFields.typesFilter.length !== 0
      || filterData.filterFields.owningOrgsFilter.length !== 0
      || filterData.filterFields.productCentersFilter.length !== 0
      || filterData.filterFields.epicEquipmentFilter.length !== 0
      || filterData.filterFields.hazardCategoriesFilter.length !== 0
      || filterData.filterFields.owningPlantsFilter.length !== 0
      || filterData.filterFields.performingOrgsFilter.length !== 0
      || filterData.filterFields.materialsFilter.length !== 0
      || filterData.filterFields.workCentersFilter.length !== 0) {
      return true;
    }
    return false;
  }

  const applyDipatchFilter = () => {
    if (checkFilter()) {
      setFilterStatus(true);
      dispatch(applyFilter({
        isRCFilter: true, onlyTask: props.onlyTask, isSourceSystemTIME: false,
        rcTypeButton: props.rcTypeButton,
        searchSource: "AddRCModal" + SW.type
      }));
      dispatch(clearSearchedMasterDataItems());
    }
  }

  const onRemoveFilter = (item: IMasterDataItem, name: MetaDataNames) => {
    if (name === "types" && (item.guid === SWTypes.TLMRC || item.guid === SWTypes.MFGRC)) {
      dispatch(clearFilter());
    } else {
      dispatch(
        removeFilterItem({
          metaDataName: name,
          item,
        })
      );
    }
  }

  return (
    <Modal
      header={props.header}
      width="70%"
      top="50%"
      controls={(
        <>
          <button
            className="primary-button ok-button"
            onClick={props.onModalClick}
          >
            Close
          </button>
        </>
      )}
      isOpen={true}
    >
      <div className="input-holder">
        {!props.hideSourceSystems && props.allowedSourceSystems.map(currentSourceSystem =>
          <span key={currentSourceSystem}>
            <input type="radio"
              name="SourceSystem"
              value={currentSourceSystem}
              checked={currentSourceSystem === sourceSystem}
              onChange={e => setSourceSystem(currentSourceSystem)} />
            {currentSourceSystem}
          </span>
        )}
        <input
          type="text"
          className="searchBar"
          id="sw-search"
          placeholder={"Begin typing to search..."}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          disabled={loadOperation?.isWorking}
        />
        <img
          src={searchIcon}
          alt="Search"
          title="Search"
          className="icon-small search-button"
        />
        {sourceSystem === WRSystem &&
          <img
            src={filterIcon}
            onClick={setIsModelOpenOnClick}
            alt="Filters"
            className="icon-small filter-button"
            title="Filters"
          />
        }
      </div>
      {(filterStatus || (debouncedSearchTerm
        && debouncedSearchTerm.trim() !== ""))
        && swList
        && swList.length > 0
        && sourceSystem === WRSystem &&
        <RCList swList={swList} AddRCStepsToSw={props.AddRCStepsToSw}  ></RCList>
      }
      {debouncedSearchTerm
        && debouncedSearchTerm.trim() !== ""
        && swList
        && swList.length > 0
        && sourceSystem === TimeSystem &&
        <RCListTime swList={swList} AddRCTimeStepsToSw={props.AddRCTimeStepsToSw} rcTypeButton={props.rcTypeButton}  ></RCListTime>
      }
      {swList.length <= 0 &&
        <h2>No Reusable Content found.</h2>}
      {isFilterModalOpen &&
        <FilterModal
              currentSWFilterValues={filterData.filterFields}
              onAddItem={(item: IMasterDataItem, name: MetaDataNames) => dispatch(
                  addFilterItem({
                      metaDataName: name,
                      item,
                  })
              )}
              onRemoveItem={(item: IMasterDataItem, name: MetaDataNames) => onRemoveFilter(item, name)}
              applyFilter={applyDipatchFilter}
              clearFilter={() => (setFilterStatus(false), dispatch(clearFilter()))}
              setSWStringFilter={(name: "maintenanceTaskIds", value: string) => dispatch(
                  setStringFilter({
                      attributeName: name,
                      value: value,
                  })
              )}
              setIsModalOpen={setFilterIsModalOpen}
              showStatusFilter={true}
              isRCFilters={true}
              setRCType={(value) => dispatch(setRCType(value))}
              rcTypeButton={props.rcTypeButton}
        />
      }
    </Modal>
  );
};

export default AddRCModal;