import SWMetadata from "components/common/SWMetadata";
import "./TLMSWIAttributes.scoped.scss";
import useSelector from "store/useSelector";
import React, { useState } from "react";
import MetadataTab from "./MetadataTab";
import { ManageSWMetadataTabs } from "store/manageSW/manageSWTypes";
import settingsIcon from "media/icons/dls/settings.svg";
import { ContentTypes, TLMSWIType, TemplateTypes } from "interfaces/sw/SWInterfaces";

interface ITLMSWIAttributesProps {
    isDisabled: boolean,
    enableGlobalLocal?: boolean,
}

const TLMSWIAttributes: React.FC<ITLMSWIAttributesProps> = ({ isDisabled, enableGlobalLocal }) => {
    const {
        manageSW: {
            SW,
        },
    } = useSelector(store => store);
    const [currentTab, setCurrentTab] = useState<ManageSWMetadataTabs>(ManageSWMetadataTabs.Tlm);
    const allowGeoUnitAndCountry = SW.TLMSWIType === TLMSWIType.Local;

    function showEvent(): boolean {
        if ((SW.contentType === ContentTypes.Executable.toString()
            && (SW.templateType === TemplateTypes.Standard.toString()
                || SW.templateType === TemplateTypes.Consolidated.toString()))
            || (SW.contentType === ContentTypes.Reference
                && SW.templateType === TemplateTypes.Standard)) {
            return true;
        }
        else {
            return false;
        }
    }

    function showLevel(): boolean {
        if ((SW.contentType === ContentTypes.Executable.toString()
            || SW.contentType === ContentTypes.Reference.toString())
            && SW.templateType === TemplateTypes.Standard.toString()) {
            return true;
        }
        else {
            return false;
        }
    }

    function showMaintenanceTask(): boolean {
        if (SW.contentType === ContentTypes.Executable.toString()            
            && SW.templateType === TemplateTypes.Standard.toString()) {
            return true;
        }
        else {
            return false;
        }
    }

    const TlmMetadata = () => {
        return (
            <>
                <div className="type-row">
                    <SWMetadata
                        metadataName="organizations"
                        isDisabled={isDisabled}
                    />
                    <SWMetadata
                        metadataName="globalOrLocal"
                        isDisabled={isDisabled}
                        enableGlobalLocal={enableGlobalLocal}
                    />
                </div>
                <div className="type-row">
                    <SWMetadata
                        metadataName="templateType"
                        isDisabled={isDisabled}
                    />
                    <SWMetadata
                        metadataName="contentType"
                        isDisabled={isDisabled}
                    />
                </div>
                <div className="type-row">
                    <SWMetadata
                        metadataName="dataClassification"
                        isDisabled={isDisabled}
                    />
                    <SWMetadata
                        metadataName="languages"
                        isDisabled={isDisabled}
                    />
                    {((SW.language
                        && SW.language.value !== "English")
                        || (SW.countries.length > 0)
                        || (SW.geoUnits.length > 0)) &&
                        <SWMetadata
                            metadataName="equivalentSWs"
                            isDisabled={isDisabled}
                        />
                    }
                </div>
                <SWMetadata
                    metadataName="title"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="description"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="introduction"
                    isDisabled={isDisabled}
                />
                <div className="double-wide">
                    <SWMetadata
                        metadataName="productCenters"
                        isDisabled={isDisabled}
                    />
                    {allowGeoUnitAndCountry &&
                        <SWMetadata
                            metadataName="geoUnits"
                            isDisabled={isDisabled}
                        />}

                    <SWMetadata
                        metadataName="epicEquipment"
                        isDisabled={isDisabled}
                    />
                    {showEvent() &&
                        <SWMetadata
                            metadataName="events"
                            isDisabled={isDisabled}
                        />
                    }
                    {showLevel() &&
                        <SWMetadata
                            metadataName="levels"
                            isDisabled={isDisabled}
                        />
                    }
                    {showMaintenanceTask() &&
                        <SWMetadata
                            metadataName="maintenanceTask"
                            isDisabled={isDisabled}
                        />
                    }
                    <SWMetadata
                        metadataName="competencyElements"
                        isDisabled={isDisabled}
                    />
                    {allowGeoUnitAndCountry &&
                        <SWMetadata
                            metadataName="customers"
                            isDisabled={isDisabled}
                        />}
                </div>
                <SWMetadata
                    metadataName="CEMarking"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="foreword"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="versionChanges"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="harc"
                    isDisabled={isDisabled}
                />
            </>
        );
    }

    const PsdMetadata = () => {
        return (
            <>
                <SWMetadata
                    metadataName="subBusinessLines"
                    isDisabled={isDisabled}
                />
                {allowGeoUnitAndCountry &&
                    <SWMetadata
                        metadataName="countries"
                        isDisabled={isDisabled}
                    />
                }
                <SWMetadata
                    metadataName="businessRoles"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="equipment"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="operatingEnvironments"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="safetyCategories"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="tasks"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                        metadataName="performingOrganization"
                        isDisabled={isDisabled}
                />
                <SWMetadata
                        metadataName="serviceTypes"
                        isDisabled={isDisabled}
                />
            </>
        );
    }

    const MfgMetadata = () => {
        return (
            <>
                <SWMetadata
                    metadataName="owingPlants"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="performingPlants"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="workCenters"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="materials"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="groups"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="groupCounter"
                    isDisabled={isDisabled}
                />
            </>
        );
    }

    return (
        <>
            <fieldset>
                <legend>Standard Work Metadata</legend>
                <div className="tabs">
                    <MetadataTab
                        isActive={currentTab === ManageSWMetadataTabs.Tlm}
                        label="TLM"
                        icon={settingsIcon}
                        onClick={() => setCurrentTab(ManageSWMetadataTabs.Tlm)}
                    />
                    <MetadataTab
                        isActive={currentTab === ManageSWMetadataTabs.Psd}
                        label="PSD"
                        icon={settingsIcon}
                        onClick={() => setCurrentTab(ManageSWMetadataTabs.Psd)}
                    />
                    <MetadataTab
                        isActive={currentTab === ManageSWMetadataTabs.Mfg}
                        label="MFG"
                        icon={settingsIcon}
                        onClick={() => setCurrentTab(ManageSWMetadataTabs.Mfg)}
                    />
                </div>
            </fieldset>
            <br />
            {
                <div className="inputs">
                    {currentTab === ManageSWMetadataTabs.Tlm &&
                        TlmMetadata()
                    }
                    {currentTab === ManageSWMetadataTabs.Psd &&
                        PsdMetadata()
                    }
                    {currentTab === ManageSWMetadataTabs.Mfg &&
                        MfgMetadata()
                    }
                </div>
            }
        </>
    );
}

export default TLMSWIAttributes;