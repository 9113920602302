export class ResponseError extends Error {
  constructor(message: string, public errorType: string) {
    super(message);
    this.name = "ResponseError";
  }

  isUnauthorized(): boolean {
    return (this.message != undefined &&
      this.errorType != undefined &&
      this.errorType == "Unauthorized")
  }
}