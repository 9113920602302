import React from "react";
import "./SWRowPDF.scoped.scss";
import pdfIcon from "media/icons/dls/pdf.svg";
import { ISWSummaryItem,  SWTypes } from "interfaces/sw/SWInterfaces";
import formatDate from "utilities/formatDate";
import { useDispatch } from "react-redux";
import {
    getPDF,
} from "store/swList/swListActions";
import SWTypeIcon from "components/sw/search/SWTypeIcon";
import { AuthFilter } from "auth/AuthFilter";
import { Roles } from "interfaces/user/UserInterfaces";
import useSelector from "store/useSelector";

interface ISWRowProps {
    sw: ISWSummaryItem;
    checkedSW: boolean,
}

const SWRowPDF: React.FC<ISWRowProps> = ({ sw, checkedSW }) => {
    const currentUser = useSelector(store => store.auth.currentUser);
    const dispatch = useDispatch();


    const handleDownloadPDFClick = () => {
        dispatch(
            getPDF({
                swId: sw.id,
                isTrainingPDF: false,
                isExecutablePdf: false,
                isReferencePdf: false,
                isCCQPdf: false,
            })
        );
    }

    return (
        <>
            {sw.type !== SWTypes.MFGRC && sw.version > 0
                && sw.dataClassification === "Private"
                && (
                <div className="row">
                    <div className="cell sw-name">
                        <div className="title-holder">
                            <SWTypeIcon type={sw.type} />
                            <div className="sw-title">
                                {sw.title}
                                <span className="description">
                                    {sw.description}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="cell execution-count">
                        <div className="swcount">
                            {sw.guid}
                        </div>
                    </div>
                    <div className="cell pub swcount">
                        {sw.latestPublishedCreatedOn &&
                            (
                                <>
                                    {formatDate(sw.latestPublishedCreatedOn, false)}
                                    <br />(
                                    {sw.latestPublishedVersion
                                        ? `version ${sw.latestPublishedVersion}`
                                        : ""}
                                    )
                                </>
                            )}
                    </div>
                    <div className="cell buttons">
                        {(() => {
                            const isAuth = AuthFilter.isLoggedIn()
                                .hasAnyRoleIn([Roles.ReportViewer])
                                .isMatch(currentUser);
                            if (isAuth) {
                                return (
                                    <div className="cell buttons">
                                        <img src={pdfIcon} className="icon-medium hover-image" alt="Download PDF" onClick={() => handleDownloadPDFClick()} />
                                    </div>
                                );
                            }
                        })()}
                    </div>

                </div >
            )}
        </>


    );
};

export default SWRowPDF;
