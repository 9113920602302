import React, { useMemo } from "react";
import Modal from "components/common/Modal";
import { setApprovalHistory } from "store/approvals/approvalsActions";
import { useDispatch } from "react-redux";
import useSelector from "store/useSelector";
import {BatchApprovalStatuses, HistoricalApprovalTypes, IHistoricalBatchApproval } from "interfaces/approvals/approvalInterfaces";
import Banner, { BannerType } from "components/common/Banner";
import Collapsible from "components/common/Collapsible";
import ApprovalHistoryList from "./ApprovalHistoryList";
import ApprovalRCUpdateHistoryList from "./ApprovalRCUpdateHistoryList";
import ApprovalBatchUpdateHistoryList from "./ApprovalBatchUpdateHistoryList";

const ApprovalHistory: React.FC = () => {
  const dispatch = useDispatch();
  const {
    approvals,
    batchApprovals,
    rcApprovals,
    loadOperation,
  } = useSelector(store => store.approvals.approvalHistory);
  const {
    activeApprovalRequestId,
    approvalType,
    version
  } = useSelector(store => store.manageSW.SW);

  const isActiveBatchApproval = (!activeApprovalRequestId
    && approvalType === HistoricalApprovalTypes.BatchApproval);
  
  const pendingStateBatchList = () => {
    let activeApprovalList: IHistoricalBatchApproval[]= [];
    if (isActiveBatchApproval){
      const activeApproval: IHistoricalBatchApproval = {
        type: HistoricalApprovalTypes.BatchApproval,
        id: 0,
        status: BatchApprovalStatuses.Active,
        originalOwningOrgGuid: "",
        createdBy: "",
        createdOn: new Date(),
        modifiedBy: "",
        modifiedOn: new Date(),
        responses: [],
        swId: 0,
        swGuid: "",
        swVersion: 0
      }
      activeApprovalList.push(activeApproval);
    }
      return activeApprovalList;
  }
  // Combine lists.
  const list = [
    ...approvals,
    ...batchApprovals,
    ...pendingStateBatchList(),
    ...rcApprovals
  ]
    .sort((a, b) => a.swId < b.swId ? -1 : 1)
    .sort((a, b) => a.swVersion < b.swVersion ? -1 : 1)
    .sort((a, b) => a.createdOn < b.createdOn ? -1 : 1);

  const autoSelectedVersion =
    typeof version === "number"
      ? version
      : Math.max(...list.map((approval) => approval.swVersion), 0);

  const disableRcTaskStep = (checked: boolean) => {
    return;
  }

  const getHeader = (swVersion: number) =>
    swVersion === 0 ? "Active Draft" : "SW Version # " + swVersion.toString();

  if (loadOperation?.wasSuccessful) {
    return (
      <Modal
        header="Approval History"
        isOpen={true}
        controls={(
          <button
            className="primary-button ok-button"
            onClick={() => dispatch(setApprovalHistory({
              swId: 0,
              approvals: [],
              batchApprovals: [],
              rcApprovals : [],
            }))}
          >
            Close
          </button>
        )}
      >
        {list
          .map((approval) => approval.type === HistoricalApprovalTypes.Approval
            ? (
              <Collapsible
                header={getHeader(approval.swVersion)}
                isInitiallyOpen={approval.swVersion === autoSelectedVersion}
                isStepDisabled={false}
                disableRcTaskStep={disableRcTaskStep}
                hideControls={true}
                key={approval.type + approval.id}
              >
                <ApprovalHistoryList key={approval.type + approval.id} {...approval}/>
              </Collapsible>
            ) : approval.type === HistoricalApprovalTypes.BatchApproval ? (
              <Collapsible
                header={getHeader(approval.swVersion)}
                isInitiallyOpen={approval.swVersion === autoSelectedVersion}
                isStepDisabled={false}
                disableRcTaskStep={disableRcTaskStep}
                hideControls={true}
                key={approval.type + approval.id}
              >
                <ApprovalBatchUpdateHistoryList
                  key={approval.type + approval.id}
                  {...approval} />
              </Collapsible>
            ) : <Collapsible
            key={approval.type + approval.id}
            header={getHeader(approval.swVersion)}
            isInitiallyOpen={approval.swVersion === autoSelectedVersion}
            isStepDisabled={false}
            disableRcTaskStep={disableRcTaskStep}
            hideControls={true}
          >
            <ApprovalRCUpdateHistoryList
              key={approval.type + approval.id}
              {...approval} />
          </Collapsible>)
        }
        {list.length === 0 &&
          <Banner
            type={BannerType.Info}
          >
            This standard work has never been submitted for approval.
          </Banner>
        }
      </Modal>
    );
  }

  return null;
}

export default ApprovalHistory;