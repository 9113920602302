import React, { useEffect, useState } from "react";
import { IDateInputComponent, IDateTimeInputComponent, SWTypes, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import { useDispatch } from "react-redux";
import { updateDateInput, updateDateTimeInput, updateTextInput } from "store/manageSW/manageSWActions";
import "./ComponentEditor.scss";
import useSelector from "store/useSelector";
import { loadMeters } from "store/masterData/masterDataActions";
import SelectMeter from "./SelectMeter";
import { IMasterDataSection } from "store/masterData/masterDataTypes";

interface IDateInputEditorProps {
  dateInput: IDateInputComponent,
  allowEdit: boolean,
  stepGuid: string,
  labelText: string,
  swType: string,
  stepLocation?: string,
}

const DateInputEditor: React.FC<IDateInputEditorProps> = ({
  dateInput,
  allowEdit,
  stepGuid,
  labelText,
  swType,
  stepLocation,
}) => {

  const {
    masterData: {
      meters,
    },
    manageSW: {
      enableMetersValue,
      SW: {
        epicEquipment,
      }
    },   
  } = useSelector(store => store);
  const dispatch = useDispatch();

  useEffect(() => {
    if (epicEquipment.length > 0) {
      dispatch(loadMeters({ onlyActive: true, equipmentCodes: epicEquipment, location: "1:Global" }));
    }
  }, [epicEquipment]);
  const filterMeters = stepLocation == undefined || stepLocation == null || stepLocation === "" || stepLocation === "1:Global" ? meters.items : meters.items.filter(t => t.parentGuid === stepLocation);

  const onLabelBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    dispatch(updateDateInput({
      stepGuid,
      component: {
        ...dateInput,
        label: event.target.value,
      },
    }));
  };

  return (
    <div className="component-editor">
      <div className="row">
        <label className="mandatory block">{labelText}</label>
              {((enableMetersValue) && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC || swType === SWTypes.MFGCL || swType === SWTypes.MFGRC || swType === SWTypes.MFGSWI)) &&
                  <label className="meter">Meter</label>}
      </div>
      <div className="row">
        {allowEdit &&
          <>
            <input
              type="text"
              defaultValue={dateInput.label}
              onBlur={onLabelBlur}
            />
                  {((enableMetersValue) && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC
                      || swType === SWTypes.MFGCL || swType === SWTypes.MFGRC || swType === SWTypes.MFGSWI))
                      &&
                      <div className="meter">
                        <SelectMeter
                        inputComponent={dateInput}
                        stepGuid={stepGuid}
                        meters={filterMeters} />
                      </div>
            }
          </>
        }
        {!allowEdit &&
          <>
            <span>{dateInput.label}</span>
                  {((enableMetersValue) && (swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC 
                      || swType === SWTypes.MFGCL || swType === SWTypes.MFGRC || swType === SWTypes.MFGSWI))
                      &&
                      <span className="meter">{dateInput.meterCode}</span>}
          </>
        }
      </div>
    </div>
  );
}

export default DateInputEditor;