import { createReducer } from "@reduxjs/toolkit";
import { SortDirection } from "interfaces/misc/miscInterfaces";
import { SWSortFields } from "interfaces/sw/SWInterfaces";
import { cloneDeep } from "lodash";
import { handleAddFilterItem, removeFilterItemHandler } from "store/swList/swListReducer";

import {
  setSort,
  addCommand,
  addFilterItem,
  addFilterItems,
  addSWs,
  applyFilter,
  clearFilter,
  removeCommand,
  removeFilterItem,
  removeSWs,
  resetAll,
  setAllowSearching,
  setCommandManagerData,
  setCurrentTab,
  setData,
  setSearchText,
  setStringFilter,
  setSubmissionErrorResponse,
  setSubmitOperation,
  toggleFilterModal,
  setRCType,
} from "./batchUpdatesActions";

import { BatchUpdatesTab, IBatchUpdatesState } from "./batchUpdatesTypes";

const initialState: IBatchUpdatesState = {
  currentTab: BatchUpdatesTab.SW,
  swListData: {
    swList: [],
    loadOperation: undefined,
    totalCount: 0,
    skipIndex: 0,
  },
  filterData: {
    isModalOpen: false,
    sortBy: SWSortFields.ModifiedOn,
    sortDir: SortDirection.Desc,
    filterFields: {
      searchText: "",
      typesFilter: [],
      globalOrLocalTypesFilter: [],
      alignmentsFilter: [],
      languagesFilter: [],
      equivalentSWsFilter: [],
      tasksFilter: [],
      activityWorkflowsFilter: [],
      owningOrgsFilter: [],
      performingOrgsFilter: [],
      subBusinessLinesFilter: [],
      serviceTypesFilter: [],
      operatingEnvironmentsFilter: [],
      equipmentFilter: [],
      countriesFilter: [],
      competencyElementsFilter: [],
      safetyCategoriesFilter: [],
      maintenanceTaskIdsFilter: "",
      businessRolesFilter: [],
      epicEquipmentFilter: [],
      productCentersFilter: [],
      geoUnitsFilter: [],
      customersFilter: [],
      feedbackTypesFilter: [],
      owningPlantsFilter: [],
      performingPlantsFilter: [],
      materialsFilter: [],
      workCentersFilter: [],
      hazardCategoriesFilter: [],
      groupsFilter: [],
      groupCounterFilter: [],
      currentOwningPlantsFilter:[]
    },
  },
  allowSearching: false,
  addedSWs: [],
  commands: [],
  commandManagerData: {
    isOpen: false,
    managedCommandSortOrder: null,
  },
  submitOperation: undefined,
  submitErrorResponse: undefined,
};

const approvalsReducer = createReducer(initialState, builder => {
  // Set Current Tab
  builder.addCase(setCurrentTab, (state, { payload }) => {
    state.currentTab = payload;
  });

  // Reset All
  builder.addCase(resetAll, state => {
    Object.assign(state, cloneDeep(initialState));
  });

  // Set Allow Searching
  builder.addCase(setAllowSearching, (state, action) => {
    state.allowSearching = action.payload;
  })

  // Set List Data
  builder.addCase(setData, (state, action) => {
    state.swListData = action.payload;
  });

  // Set SW List Search Text
  builder.addCase(setSearchText, (state, action) => {
    state.filterData.filterFields.searchText = action.payload;
  });

  // Toggle Filter Modal Visibility
  builder.addCase(toggleFilterModal, (state, action) => {
    state.filterData.isModalOpen = action.payload;
  });

  //sort
  builder.addCase(setSort, (state, action) => {
    state.filterData.sortBy = action.payload.sortBy;
    state.filterData.sortDir = action.payload.sortDir;
  });

  // Apply Filter
  builder.addCase(applyFilter, state => {
    state.filterData.isModalOpen = false;
  });

  // Clear Filter
  builder.addCase(clearFilter, state => {
    state.filterData.filterFields = initialState.filterData.filterFields;
    state.filterData.isModalOpen = false;
    state.allowSearching = false;
  });

  // Add String Filter Item
  builder.addCase(setStringFilter, (state, action) => {
    switch (action.payload.attributeName) {
      case "maintenanceTaskIds": {
        state.filterData.filterFields.maintenanceTaskIdsFilter = action.payload.value;
        break;
      }
    }
  });

  // Add Filter Item
  builder.addCase(addFilterItem, (state, action) => {
    handleAddFilterItem(state.filterData,
      action.payload.metaDataName,
      action.payload.item);
  });

  // Add Filter Items
  builder.addCase(addFilterItems, (state, action) => {
    action.payload.items.forEach(item => {
      handleAddFilterItem(state.filterData,
        action.payload.metaDataName,
        item);
    });
  });

  // Remove Filter Item
  builder.addCase(removeFilterItem, (state, action) => {
    removeFilterItemHandler(state.filterData,
      action.payload.metaDataName,
      action.payload.item);
  });

  // Add SWs
  builder.addCase(addSWs, (state, action) => {
    action.payload.forEach(newSW => {
      const oldIx = state.addedSWs.findIndex(x => x.guid === newSW.guid);
      if (oldIx === -1) {
        state.addedSWs.push({
          ...newSW
        });
      } else {
        state.addedSWs[oldIx] = {
          ...newSW
        };
      }
    });
  });

  // Remove SWs
  builder.addCase(removeSWs, (state, action) => {
    action.payload.guids.forEach(guid => {
      const ix = state.addedSWs.findIndex(x => x.guid === guid);

      if (ix > -1) {
        state.addedSWs.splice(ix, 1);
      }
    });
  });

  // Add Command
  builder.addCase(addCommand, (state, action) => {
    state.commands.push({
      ...cloneDeep(action.payload),
      sortOrder: state.commands.length + 1,
    });
  });

  // Remove Command
  builder.addCase(removeCommand, (state, action) => {
    state.commands = state.commands.filter(x => x.sortOrder !== action.payload.commandSortOrder);

    state.commands.forEach((item, ix) => {
      item.sortOrder = ix + 1;
    });
  });

  // Set Command Manager Data
  builder.addCase(setCommandManagerData, (state, action) => {
    state.commandManagerData = {
      ...action.payload,
    };
  });

  // Set Submit Operation
  builder.addCase(setSubmitOperation, (state, action) => {
    state.submitOperation = action.payload;
  });

  builder.addCase(setSubmissionErrorResponse, (state, action) => {
    state.submitErrorResponse = action.payload;
  });


  // Set rcType filter variable
  builder.addCase(setRCType, (state, action) => {
    state.filterData.filterFields.rcType = action.payload;
  });
});

export default approvalsReducer;
