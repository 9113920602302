import React, { useState } from "react";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import "./MasterDataInput.scoped.scss";
import MasterDataModal from "./MasterDataModal";
import { IMasterDataSection } from "store/masterData/masterDataTypes";
import infoIcon from "media/icons/dls/info.svg";

interface IMasterDataInput {
  label: string,
  helpText?: string,
  isMandatory?: boolean | "submitOnly",
  selectedItems: IMasterDataItem[],
  disabled?: boolean,
  allowMultiple?: boolean,
  isAsyncSearch?: boolean,
  masterDataSection: IMasterDataSection,
  loadItems(searchTerm: string | undefined, activeOnly?: boolean): void,
  onAddItem(item: IMasterDataItem): void,
  onRemoveItem(item: IMasterDataItem): void,
  itemFormatter(item: IMasterDataItem): string | JSX.Element,
  itemFilter?(item: IMasterDataItem): boolean,
  showStatusFilter?: boolean,
  onlyActiveState?: boolean,
  onlyActiveStateChange?(stateValue: boolean): void,
  showNoDataMessage?: boolean,
  hideLabel?: boolean,
  showNoRoutingDataMsg?: boolean,
}

const MasterDataInput: React.FC<IMasterDataInput> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    showStatusFilter,
    isMandatory
  } = props;

  const mandatoryClassName =
    isMandatory ?
      isMandatory === "submitOnly" ?
        "mandatory-alternative" :
        "mandatory" :
      undefined;

  return (
    <>
      {!props.hideLabel &&
        <span className="label-holder">
          <label className={mandatoryClassName}>
            {props.label}
          </label>
          {props.helpText &&
            <img
              src={infoIcon}
              className="icon-small help-cursor mandatory"
              title={props.helpText}
              alt={props.helpText}
            />
          }
        </span>
      }
      <div
        className="input-holder"
      >
        <div
          className={`tag-holder input ${props.disabled ? "disabled" : ""}`}
        >
          {props.selectedItems.map(x =>
            <span
              className={`tag no-text-select ${!x.active ? "inactive" : ""}`}
              key={x.guid}
            >
              <span
                className={`text ${!(!showStatusFilter || x.active)
                  ? "inactive"
                  : "active"}`
                }
              >
                {props.itemFormatter(x)}
              </span>
              {!props.disabled &&
                <span
                  className="remove-button-holder"
                  onClick={() => props.onRemoveItem(x)}
                >
                  <span
                    className="remove-button"
                  >
                    X
                  </span>
                </span>
              }
            </span>
          )}
        </div>
        <button
          className="add-button primary-button"
          onClick={() => setIsModalVisible(true)}
          disabled={props.disabled}
          title={`Add ${props.label}`}
        >
          +
        </button>
      </div>
      {isModalVisible &&
        <MasterDataModal
          label={props.label}
          isAsyncSearch={props.isAsyncSearch}
          allowMultiple={props.allowMultiple}
          onAddItem={props.onAddItem}
          onClose={() => setIsModalVisible(false)}
          onLoad={props.loadItems}
          masterDataSection={props.masterDataSection}
          selectedItems={props.selectedItems}
          itemFormatter={props.itemFormatter}
          itemFilter={props.itemFilter}
          showStatusFilter={props.showStatusFilter}
          onlyActiveState={props.onlyActiveState}
          onlyActiveStateChange={props.onlyActiveStateChange}
          showNoDataMessage={props.showNoDataMessage}
          showNoRoutingDataMsg={props.showNoRoutingDataMsg}
        />
      }
    </>
  );
}

export default MasterDataInput;