import { createReducer } from "@reduxjs/toolkit";
import { FilterNames, IUserFilterFields, IUserManagementState } from "./userManagementTypes";
import { toggleAddUserModal, setAddUserModalData, setAddUserSearchText, setManageUsersData, setSearchManageUsersText, setEditUserData, toggleRole, toggleOrg, resetManageUsersData, resetAddUserModalData, resetEditUserData, addMetaDataItem, removeMetaDataItem, setUserDetails, setUserDetailEditUserData, setIsRcEditor, setSearchManageTechContentUsersText, setManageTechContentUsersData, addFilterItem, removeFilterItem, setUserGroupFilter, setSearchLDAPManageUsersText, setSearchLDAPManageTechContentUsersText, clearUserFilters, } from "./userManagementActions";
import { cloneDeep } from "lodash";
import { Roles } from "interfaces/user/UserInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";

const initialState: IUserManagementState = {
  manageUsersData: {
    userList: [],
    ldapUserList: [],
    searchText: "",
    getUsersOperation: undefined,
  },
  manageTechContentUsersData: {
    userList: [],
    ldapUserList: [],
    searchText: "",
    getUsersOperation: undefined,
  },
  editUserData: {
    isDirty: false,
    email: "",
    name: "",
    user: undefined,
    saveOperation: undefined,
    loadOperation: undefined,
  },
  userDetail: {
    email: "",
    name: "",
    userDetails: [],
  },
  addUserModalData: {
    isOpen: false,
    searchText: "",
    searchResults: [],
    createOperation: undefined,
    loadOperation: undefined,
  },
  filterData: {
    owningPlantsFilter: [],
    userRolesFilter: [],
    countriesFilter: [],
    epicEquipmentsFilter: [],
    equipmentsFilter: [],
    geoUnitsFilter: [],
    owningOrgsFilter: [],
    productCentersFilter: [],
    serviceTypesFilter: [],
  },
}

const userManagementReducer = createReducer(initialState, builder => {
  // Reset Manage Users Data
  builder.addCase(resetManageUsersData, state => {
    state.manageUsersData = cloneDeep(initialState.manageUsersData);
  });

  // Reset Add User Modal Data
  builder.addCase(resetAddUserModalData, state => {
    state.addUserModalData = cloneDeep(initialState.addUserModalData);
  });

  // Reset Edit User Data
  builder.addCase(resetEditUserData, state => {
    state.editUserData = cloneDeep(initialState.editUserData);
  });

  // Toggle Add User Modal Visibility
  builder.addCase(toggleAddUserModal, (state, action) => {
    state.addUserModalData.isOpen = action.payload;
  });

  // Set Add User Search Text
  builder.addCase(setAddUserSearchText, (state, action) => {
    state.addUserModalData.searchText = action.payload;
  });

  // Set Add User Modal Data
  builder.addCase(setAddUserModalData, (state, action) => {
    state.addUserModalData = action.payload;
  });

  // Set Manage Users Data
  builder.addCase(setManageUsersData, (state, action) => {
    state.manageUsersData = action.payload;
  });

  // Set Manage Users Data
  builder.addCase(setManageTechContentUsersData, (state, action) => {
    state.manageTechContentUsersData = action.payload;
  });

  // Set Manage Users Search Text
  builder.addCase(setSearchManageUsersText, (state, action) => {
    state.manageUsersData.searchText = action.payload;
  });

  // Set Manage Users Search Text (LDAP)
  builder.addCase(setSearchLDAPManageUsersText, (state, action) => {
    state.manageUsersData.searchText = action.payload;
  });

  // Set Manage Tech content Users Search Text
  builder.addCase(setSearchManageTechContentUsersText, (state, action) => {
    state.manageTechContentUsersData.searchText = action.payload;
  });

  // Set Manage Tech content Users Search Text (LDAP)
  builder.addCase(setSearchLDAPManageTechContentUsersText, (state, action) => {
    state.manageTechContentUsersData.searchText = action.payload;
  });

  // Set Edit User Data
  builder.addCase(setEditUserData, (state, action) => {
    state.editUserData = action.payload;
    state.editUserData.user = action.payload.user;
  });

  // Set User Detail
  builder.addCase(setUserDetails, (state, action) => {
    state.userDetail = action.payload;
  })

  //Toggle Role
  builder.addCase(toggleRole, (state, action) => {
    if (!state.editUserData.user) {
      return;
    }
    if (action.payload.role === Roles.ApproverCOE) {
      state.editUserData.isDirty = true;
    }
  });

  // Toggle Org Id
  builder.addCase(toggleOrg, (state, action) => {
    if (!state.editUserData.user) {
      return;
    }

    state.editUserData.isDirty = true;

    if (!!state.editUserData.user.orgs.find(o => o.guid === action.payload.guid)) {
      state.editUserData.user.orgs =
        state.editUserData.user.orgs.filter(x => x.guid !== action.payload.guid);
    } else {
      state.editUserData.user.orgs.push(action.payload);
    }
  });

  builder.addCase(addMetaDataItem, (state, action) => {
    state.editUserData.isDirty = true;

    if (action.payload.metaDataName === "serviceTypes") {
      state.editUserData.user?.serviceType.push(action.payload.item);
    } else if (action.payload.metaDataName === "equipment") {
      state.editUserData.user?.equipment.push(action.payload.item);
    }
    else if (action.payload.metaDataName === "epicEquipment") {
      state.editUserData.user?.epicEquipment.push(action.payload.item);
    }
    else if (action.payload.metaDataName === "geoUnits") {
      state.editUserData.user?.geoUnit.push(action.payload.item);
    }
    else if (action.payload.metaDataName === "productCenters") {
      state.editUserData.user?.productCenter.push(action.payload.item);
    }
    else if (action.payload.metaDataName === "countries") {
      state.editUserData.user?.country.push(action.payload.item);
    }
  });

  builder.addCase(removeMetaDataItem, (state, action) => {
    state.editUserData.isDirty = true;
    if (action.payload.metaDataName === "serviceTypes") {
      if (state.editUserData.user) {
        state.editUserData.user.serviceType =
          state.editUserData.user.serviceType
            .filter(x => x.guid !== action.payload.item.guid);
      }
    } else if (action.payload.metaDataName === "equipment") {
      if (state.editUserData.user) {
        state.editUserData.user.equipment =
          state.editUserData.user.equipment
            .filter(x => x.guid !== action.payload.item.guid);
      }
    }
    else if (action.payload.metaDataName === "epicEquipment") {
      if (state.editUserData.user) {
        state.editUserData.user.epicEquipment =
          state.editUserData.user.epicEquipment
            .filter(x => x.guid !== action.payload.item.guid);
      }
    }
    else if (action.payload.metaDataName === "geoUnits") {
      if (state.editUserData.user) {
        state.editUserData.user.geoUnit =
          state.editUserData.user.geoUnit
            .filter(x => x.guid !== action.payload.item.guid);
      }
    }
    else if (action.payload.metaDataName === "productCenters") {
      if (state.editUserData.user) {
        state.editUserData.user.productCenter =
          state.editUserData.user.productCenter
            .filter(x => x.guid !== action.payload.item.guid);
      }
    }
    else if (action.payload.metaDataName === "countries") {
      if (state.editUserData.user) {
        state.editUserData.user.country =
          state.editUserData.user.country
            .filter(x => x.guid !== action.payload.item.guid);
      }
    }
  });

  // Set Edit User Data User Detail
  builder.addCase(setUserDetailEditUserData, (state, action) => {
    state.editUserData.user = action.payload;
  });

  // Set Is RC
  builder.addCase(setIsRcEditor, (state, action) => {
    if (state.editUserData.user) {
      state.editUserData.user.isRCEditor = action.payload;
    }
  });

  // Add Filter Item
  builder.addCase(addFilterItem, (state, action) => {
    handleAddFilterItem(state.filterData,
      action.payload.filterName,
      action.payload.filterItem);
  });

  // Remove filter item.
  builder.addCase(removeFilterItem, (state, action) => {
    removeFilterItemHandler(state.filterData,
      action.payload.filterName,
      action.payload.filterItem);
  });

  // set user group filter
  builder.addCase(setUserGroupFilter, (state, action) => {
    state.filterData.IsPsd = action.payload.isPsd;
    state.filterData.IsTech = action.payload.isTech;
    state.filterData.IsMfg = action.payload.isMfg;
  });

  //clear user filters
  builder.addCase(clearUserFilters, (state) => {
    state.filterData.IsMfg = undefined;
    state.filterData.IsPsd = undefined;
    state.filterData.IsTech = undefined;
    state.filterData.userRolesFilter = [];
    state.filterData.owningOrgsFilter = [];
    state.filterData.owningPlantsFilter = [];
    state.filterData.serviceTypesFilter = [];
    state.filterData.equipmentsFilter = [];
    state.filterData.epicEquipmentsFilter = [];
    state.filterData.countriesFilter = [];
    state.filterData.geoUnitsFilter = [];
    state.filterData.productCentersFilter = [];
  });
});

export default userManagementReducer;

export function handleAddFilterItem(filterData: IUserFilterFields,
  filterName: FilterNames,
  item?: IMasterDataItem) {
  let mdArr: IMasterDataItem[] | undefined = [];

  switch (filterName) {
    case "owningPlant": {
      mdArr = filterData.owningPlantsFilter;
      break;
    }
    case "userRole": {
      mdArr = filterData.userRolesFilter;
      break;
    }
    case "owningOrgs": {
      mdArr = filterData.owningOrgsFilter;
      break;
    }
    case "serviceTypes": {
      mdArr = filterData.serviceTypesFilter;
      break;
    }
    case "equipment": {
      mdArr = filterData.equipmentsFilter;
      break;
    }
    case "epicEquipment": {
      mdArr = filterData.epicEquipmentsFilter;
      break;
    }
    case "countries": {
      mdArr = filterData.countriesFilter;
      break;
    }
    case "geoUnits": {
      mdArr = filterData.geoUnitsFilter;
      break;
    }
    case "productCenters": {
      mdArr = filterData.productCentersFilter;
      break;
    }
  }

  if (item) {
    if (!mdArr.find(x => x.guid === item.guid)) {
      mdArr.push({
        ...item,
      });
    }
  }
}

export function removeFilterItemHandler(filterData: IUserFilterFields,
  filterName: FilterNames,
  item?: IMasterDataItem) {

  if (item) {
    switch (filterName) {
      case "owningPlant": {
        filterData.owningPlantsFilter =
          filterData.owningPlantsFilter
            .filter(x => x.guid !== item.guid);
        break;
      }
      case "userRole": {
        filterData.userRolesFilter =
          filterData.userRolesFilter
            .filter(x => x.guid !== item.guid);
        break;
      }
      case "owningOrgs": {
        filterData.owningOrgsFilter =
          filterData.owningOrgsFilter
            .filter(x => x.guid !== item.guid);
        break;
      }
      case "serviceTypes": {
        filterData.serviceTypesFilter =
          filterData.serviceTypesFilter
            .filter(x => x.guid !== item.guid);
        break;
      }
      case "equipment": {
        filterData.equipmentsFilter =
          filterData.equipmentsFilter
            .filter(x => x.guid !== item.guid);
        break;
      }
      case "epicEquipment": {
        filterData.epicEquipmentsFilter =
          filterData.epicEquipmentsFilter
            .filter(x => x.guid !== item.guid);
        break;
      }
      case "countries": {
        filterData.countriesFilter =
          filterData.countriesFilter
            .filter(x => x.guid !== item.guid);
        break;
      }
      case "geoUnits": {
        filterData.geoUnitsFilter =
          filterData.geoUnitsFilter
            .filter(x => x.guid !== item.guid);
        break;
      }
      case "productCenters": {
        filterData.productCentersFilter =
          filterData.productCentersFilter
            .filter(x => x.guid !== item.guid);
        break;
      }
    }
  }
}