import React from "react";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import NotFound from "components/NotFound";
import SWSearch from "components/sw/search/SWSearch";
import UserProfile from "components/user/UserProfile";
import ManageSW from "components/sw/manage/ManageSW";
import UserManagement from "components/user/management/UserManagement";
import EditUser from "components/user/management/EditUser";
import UserDetail from "components/user/management/UserDetail";
import MyApprovals from "components/approvals/mine/MyApprovals";
import ViewApproval from "components/approvals/view/ViewApproval";
import ViewBatchApproval from "components/approvals/view/batch/ViewBatchApproval";
import ViewRCUpdateApproval from "components/approvals/view/rcupdate/ViewRCUpdateApproval";
import { AuthFilter } from "auth/AuthFilter";
import { Roles } from "interfaces/user/UserInterfaces";
import AuthRoute from "./AuthRoute";
import BatchUpdatesCreator from "components/batchUpdates/BatchUpdatesCreator";
import ManageSwReports from "components/sw/reports/ManageSwReports";

export enum Routes {
  NewStandardWork = "/sw/new/:mode",
  EditStandardWork = "/sw/:guid/:version/edit",
  ViewStandardWork = "/sw/:guid/:version/view",
  MyProfile = "/user/profile",
  Root = "/",
  SearchStandardWork = "/sw/search",
  UserManagement = "/users/manage",
  EditUser = "/users/manage/getUserEditInfo/:email/:role/:isTechUser/:isPsdUser/:isMfgUser/:isRCEditor",
  UserDetail = "/users/detail/:email",
  MyApprovals = "/approvals",
  ViewApproval = "/approvals/:id",
  ViewBatchApproval = "/approvals/batch/:id",
  ViewRCUpdateApproval = "/approvals/rcupdate/:id",
  BatchUpdatesCreator = "/batchUpdates/new",
  Reports = "/Reports",
}

const Routing: React.FC = () =>
  <Switch>
    <Route exact path={Routes.Root}>
      <Redirect to={Routes.SearchStandardWork} />
    </Route>
    <Route path={Routes.SearchStandardWork}>
      <SWSearch />
    </Route>
    <Route path={Routes.NewStandardWork} component={ManageSW} />
    <Route path={Routes.EditStandardWork} component={ManageSW} />
    <Route path={Routes.ViewStandardWork} component={ManageSW} />
    <Route path={Routes.MyProfile} component={UserProfile} />
    <AuthRoute
      path={Routes.UserManagement}
      component={UserManagement}
      exact
      authFilter={AuthFilter.isLoggedIn()
        .hasRole(Roles.OrgAdmin)
        .includeSysAdmins()
      }
    />
    <Route path={Routes.EditUser} component={EditUser} exact />
    <Route path={Routes.UserDetail} component={UserDetail} exact />
    <Route path={Routes.MyApprovals} exact>
      <MyApprovals />
    </Route>
    <Route path={Routes.ViewApproval} component={ViewApproval} exact />
    <Route path={Routes.ViewBatchApproval} component={ViewBatchApproval} exact />
    <Route path={Routes.ViewRCUpdateApproval} component={ViewRCUpdateApproval} exact />
    <AuthRoute
      path={Routes.BatchUpdatesCreator}
      component={BatchUpdatesCreator}
      exact
      authFilter={AuthFilter.isLoggedIn()
        .hasAnyRoleIn([
          Roles.Author,
          Roles.OrgAdmin,
        ]).includeSysAdmins()
      }
    />
    <Route path={Routes.Reports}>
      <ManageSwReports />
    </Route>
    <Route component={NotFound} />
  </Switch>;

export default Routing;