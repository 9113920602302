import { SWTypes } from "interfaces/sw/SWInterfaces";
import { UserGroups } from "interfaces/user/UserInterfaces";
import { ISWFilterFields } from "store/swList/swListTypes";

export function getSWFilterCount(swFilterFields: ISWFilterFields,
  extraFilterCount: number) {
  return (swFilterFields.activityWorkflowsFilter.length ? 1 : 0)
    + (swFilterFields.alignmentsFilter.length ? 1 : 0)
    + (swFilterFields.maintenanceTaskIdsFilter ? 1 : 0)
    + (swFilterFields.feedbackTypesFilter.length ? 1 : 0)
    + (swFilterFields.competencyElementsFilter.length ? 1 : 0)
    + (swFilterFields.countriesFilter.length ? 1 : 0)
    + (swFilterFields.equipmentFilter.length ? 1 : 0)
    + (swFilterFields.equivalentSWsFilter.length ? 1 : 0)
    + (swFilterFields.languagesFilter.length ? 1 : 0)
    + (swFilterFields.operatingEnvironmentsFilter.length ? 1 : 0)
    + (swFilterFields.owningOrgsFilter.length ? 1 : 0)
    + (swFilterFields.performingOrgsFilter.length ? 1 : 0)
    + (swFilterFields.subBusinessLinesFilter.length ? 1 : 0)
    + (swFilterFields.safetyCategoriesFilter.length ? 1 : 0)
    + (swFilterFields.serviceTypesFilter.length ? 1 : 0)
    + (swFilterFields.tasksFilter.length ? 1 : 0)
    + (swFilterFields.typesFilter.length ? 1 : 0)
    + (swFilterFields.globalOrLocalTypesFilter.length ? 1 : 0)
    + (swFilterFields.businessRolesFilter.length ? 1 : 0)
    + (swFilterFields.epicEquipmentFilter.length ? 1 : 0)
    + (swFilterFields.productCentersFilter.length ? 1 : 0)
    + (swFilterFields.geoUnitsFilter.length ? 1 : 0)
    + (swFilterFields.customersFilter.length ? 1 : 0)
    + (swFilterFields.owningPlantsFilter.length ? 1 : 0)
    + (swFilterFields.performingPlantsFilter.length ? 1 : 0)
    + (swFilterFields.materialsFilter.length ? 1 : 0)
    + (swFilterFields.workCentersFilter.length ? 1 : 0)
    + (swFilterFields.hazardCategoriesFilter.length ? 1 : 0)
    + extraFilterCount;
}

/**
 * Gets the user groups filters
 * @returns 
 */
export function getUserGroupFilters(): { [key: string]: Array<string> } {
  let userGroupFilters: { [key: string]: Array<string> } = {};

  userGroupFilters[UserGroups.PSD] = [
    "businessRolesFilter",
    "competencyElementsFilter",
    "countriesFilter",
    "customersFilter",
    "equipmentFilter",
    "equivalentSWsFilter",
    "feedbackTypesFilter",
    "languagesFilter",
    "operatingEnvironmentsFilter",
    "owningOrgsFilter",
    "performingOrgsFilter",
    "safetyCategoriesFilter",
    "serviceTypesFilter",
    "subBusinessLinesFilter",
    "alignmentsFilter",
    "tasksFilter",
    "typesFilter"];

  userGroupFilters[UserGroups.TLM] = [
    "businessRolesFilter",
    "competencyElementsFilter",
    "countriesFilter",
    "customersFilter",
    "epicEquipmentFilter",
    "equipmentFilter",
    "equivalentSWsFilter",
    "geoUnitsFilter",
    "globalOrLocalTypesFilter",
    "languagesFilter",
    "maintenanceTaskIdsFilter",
    "operatingEnvironmentsFilter",
    "owningOrgsFilter",
    "performingOrgsFilter",
    "productCentersFilter",
    "rcTypesFilter",
    "safetyCategoriesFilter",
    "serviceTypesFilter",
    "subBusinessLinesFilter",
    "tasksFilter",
    "feedbackTypesFilter",
    "typesFilter",
    "hazardCategoriesFilter"
  ];

  userGroupFilters[UserGroups.MFG] = [
    "languagesFilter",
    "materialsFilter",
    "owningPlantsFilter",
    "performingPlantsFilter",
    "typesFilter",
    "workCentersFilter",
    "groupsFilter",
    "groupCounterFilter",
  ];

  return userGroupFilters;
}

const userGroupSWTypes = {
  [UserGroups.PSD]: [
    SWTypes.SWI,
    SWTypes.CL,
    SWTypes.ECL,
    SWTypes.LCL
  ],
  [UserGroups.MFG]: [
    SWTypes.MFGSWI,
    SWTypes.MFGRC,
    SWTypes.MFGCL,
  ],
  [UserGroups.TLM]: [
    SWTypes.TLMSWI,
    SWTypes.TLMRC
  ]
};

export function getUserGroupSWTypes() {
  return userGroupSWTypes;
}