import React from "react";
import "./SWRow.scoped.scss";
import archiveIcon from "media/icons/dls/archive.svg";
import pdfIcon from "media/icons/dls/pdf.svg";
import newIcon from "media/icons/dls/new-doc.svg";
import deleteIcon from "media/icons/dls/delete.svg";
import commentsIcon from "media/icons/dls/comments.svg";
import duplicateIcon from "media/icons/dls/duplicate.svg";
import downArrowIcon from "media/icons/dls/arrow-down-2.svg";
import searchIcon from "media/icons/dls/search.svg";
import thumbUp from "media/icons/dls/thumbs-up.svg";
import thumbDown from "media/icons/dls/thumbs-down.svg";
import { ISWSummaryItem, RCTypes, SWTypes } from "interfaces/sw/SWInterfaces";
import formatDate from "utilities/formatDate";
import { useDispatch } from "react-redux";
import {
  showCreateDraftConfirmation,
  showArchiveConfirmation,
  getPDF,
  showDeleteDraftConfirmation,
  loadSWUserFeedbacks,
  showCreateLCLConfirmation,
  getRCWhereUsedItems,
  addToZipSWList,
  removeFromZipSWList,
} from "store/swList/swListActions";
import { Link } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import SWTypeIcon from "./SWTypeIcon";
import { AuthFilter } from "auth/AuthFilter";
import { Roles } from "interfaces/user/UserInterfaces";
import EllipsesDropdown, { IEllipsesDropdownItem } from "components/common/EllipsesDropdown";
import useSelector from "store/useSelector";
import SWStepCommentStatusIndicator from "../manage/comments/SWStepCommentStatusIndicator";
import { ApprovalLevels } from "interfaces/approvals/approvalInterfaces";

interface ISWRowProps {
  sw: ISWSummaryItem;
  checkedSW: boolean,
}

const SWRow: React.FC<ISWRowProps> = ({ sw, checkedSW }) => {
  const currentUser = useSelector(store => store.auth.currentUser);
  const dispatch = useDispatch();

  const handleCreateDraftClick = () => {
    dispatch(
      showCreateDraftConfirmation({
        guid: sw.guid,
        version: null,
        duplicate: false,
        thumbsDownCount: sw.thumbsDownCount,
        thumbsUpCount: sw.thumbsUpCount,
        swType: SWTypes.Unspecified,
        newOwningOrgId: undefined,
        newOwningPlantId: undefined,
      })
    );
  };

  const handleCreateDuplicateClick = () => {
    dispatch(
      showCreateDraftConfirmation({
        guid: sw.guid,
        version: sw.version,
        duplicate: true,
        thumbsDownCount: sw.thumbsDownCount,
        thumbsUpCount: sw.thumbsUpCount,
        swType: sw.type,
        newOwningOrgId: sw.owningOrgId,
        newOwningPlantId: sw.owningPlantId,
      })
    );
  };

  const handleArchiveClick = () => {
    dispatch(
      showArchiveConfirmation({
        swGuid: sw.guid,
      })
    );
  }

  const handleWhereUsedClick = () => {
    dispatch(
      getRCWhereUsedItems({
        rcID: sw.id,
      })
    );
  }

  const handleUserFeedbackClick = () => {
    dispatch(
      loadSWUserFeedbacks({
        swGuid: sw.guid
      })
    );
  }

  const handleDeleteClick = () => {
    dispatch(
      showDeleteDraftConfirmation({
        swGuid: sw.guid,
        currentEditor: sw.currentEditor,
        currentEditorLockedDate: sw.currentEditorLockedDate,
      })
    );
  }

  const handleDownloadPDFClick = () => {
    dispatch(
      getPDF({
        swId: sw.id,
        isTrainingPDF: false,
        isExecutablePdf: false,
        isReferencePdf: false,
        isCCQPdf: false,
      })
    );
  }

  const handleDownloadCCQPDFClick = () => {
    dispatch(
      getPDF({
        swId: sw.id,
        isTrainingPDF: false,
        isExecutablePdf: false,
        isReferencePdf: false,
        isCCQPdf: true,
      })
    );
  }

  const handleDownloadTrainingPDFClick = (isExecutablePdf: boolean) => {
    dispatch(
      getPDF({
        swId: sw.id,
        isTrainingPDF: true,
        isExecutablePdf,
        isReferencePdf: false,
        isCCQPdf: false,
      })
    );
  }

  const handlDownloadReferencePDFClick = () => {
    dispatch(
      getPDF({
        swId: sw.id,
        isTrainingPDF: false,
        isExecutablePdf: false,
        isReferencePdf: true,
        isCCQPdf: false,
      })
    );
  }

  const handleCreateLCLClick = () => {
    dispatch(
      showCreateLCLConfirmation({
        swGuid: sw.guid,
        swVersion: sw.version,
      })
    );
  }

  const handleCheckBoxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch(
        addToZipSWList({
          swId: sw.id,
        })
      );
    }
    else {
      dispatch(
        removeFromZipSWList({
          swId: sw.id,
        })
      );
    }
  }

  return (
    <div className="row" >
      <div className="cell sw-checkbox">
        <div className="swcheckbox">

          <input
            type="checkbox"
            checked={checkedSW}
            onChange={handleCheckBoxClick}
            value={sw.id}
          />
        </div>
      </div>
      <Link
        className="sw-header linkRow hover-gray-bg"
        to={
          sw.version === 0
            ? Routes.EditStandardWork.replace(":guid", sw.guid).replace(
              ":version",
              sw.version.toString()
            )
            : Routes.ViewStandardWork.replace(":guid", sw.guid).replace(
              ":version",
              sw.version.toString()
            )
        }
      >
        <div className="cell sw-name">
          <div className="title-holder">
            <SWTypeIcon type={sw.type} />
            <div className="sw-title">
              {sw.title}
              <span className="description">
                {sw.description}
              </span>
            </div>
          </div>
        </div>
        <div className="cell rating">
          {sw.type !== SWTypes.TLMRC
            && (sw.version !== 0 ||
              sw.latestPublishedVersion) &&
            <div className="feedback">
              <div className="thumb">
                <div className="icon">
                  <img className="icon-small"
                    src={thumbUp}
                    alt="like" />
                </div>
                <div className="count">
                  {sw.thumbsUpCount ? sw.thumbsUpCount?.toString() : "0"}
                </div>
              </div>
              <div className="thumb">
                <div className="icon">
                  <img className="icon-small"
                    src={thumbDown}
                    alt="dislike" />
                </div>
                <div className="count">
                  {sw.thumbsDownCount ? sw.thumbsDownCount?.toString() : "0"}
                </div>
              </div>
            </div>
          }
        </div>
        <div className="cell execution-count">
          <div className="swcount">
            {sw.type !== SWTypes.TLMRC
              && sw.executionCount}
          </div>
        </div>
        <div className="cell comments">
          <SWStepCommentStatusIndicator
            resolved={!sw.hasActiveComments}
            showText={false}
          />
        </div>
        <div className="cell pub">
          {sw.latestPublishedCreatedOn && (
            <>
              {formatDate(sw.latestPublishedCreatedOn, false)}
              <br />(
              {sw.latestPublishedVersion
                ? `version ${sw.latestPublishedVersion}`
                : ""}
              )
            </>
          )}
        </div>
        <div className="cell status">
          {sw.version === 0 &&
            !sw.inReview &&
            <span className="draft-label">DRAFT </span>
          }
          {sw.version === 0 &&
            sw.inReview &&
            <>
              <span className="inreview-label">IN-REVIEW</span>
              <span> - Pending </span>
              {sw.approvalLevel ? sw.approvalLevel?.toString() : ""}
              {sw.approvalLevel === ApprovalLevels.TechConRev &&
                sw.techContRev ? " by " + sw.techContRev?.toString() : ""}
              {sw.approvalLevel === ApprovalLevels.SME &&
                sw.smeApprover ? " by " + sw.smeApprover?.toString() : ""}
              <span> - </span>
            </>
          }
          Last Edited: {formatDate(sw.modifiedOn, false)}
          <br />
          by {sw.modifiedBy}
        </div>
        <div className="cell buttons">
          {(() => {
            const isAuth = AuthFilter.isLoggedIn()
              .hasAnyRoleIn([Roles.Author, Roles.OrgAdmin, Roles.TechComm])
              .hasOrg(sw.owningOrgId)
              .includeSysAdmins()
              .isMatch(currentUser);

            let itemList: (IEllipsesDropdownItem | undefined)[] = [];

            if (isAuth) {
              itemList = itemList.concat([
                sw.version !== 0 ? ({
                  key: "Create Draft",
                  label: "Create Draft",
                  toolTip: "Create Draft will create a new version of the existing SW with the same document ID.",
                  icon: newIcon,
                  onClick: handleCreateDraftClick,
                }) : undefined,
                (![SWTypes.LCL, SWTypes.MFGSWI, SWTypes.MFGCL].includes(sw.type)) ? ({
                  key: "Create Duplicate Draft",
                  label: "Create Duplicate Draft",
                  toolTip: "Create Duplicate Draft will create a new document ID and copy the existing content to the new document.",
                  icon: duplicateIcon,
                  onClick: handleCreateDuplicateClick,
                }) : undefined,
                (sw.type === SWTypes.CL
                  && sw.version !== 0) ? ({
                    key: "Create Local Checklist",
                    label: "Create Local Checklist",
                    icon: newIcon,
                    onClick: handleCreateLCLClick,
                  }) : undefined,
                sw.version !== 0 ? ({
                  key: "Archive",
                  label: "Archive",
                  icon: archiveIcon,
                  onClick: handleArchiveClick,
                }) : undefined,
                sw.version === 0 ? ({
                  key: "Delete",
                  label: "Delete",
                  icon: deleteIcon,
                  onClick: handleDeleteClick,
                }) : undefined,
                (sw.type !== SWTypes.TLMRC && sw.type !== SWTypes.MFGRC) ? ({
                  key: "User Feedback",
                  label: "User Feedback",
                  icon: commentsIcon,
                  onClick: handleUserFeedbackClick,
                }) : undefined,
                sw.type === SWTypes.SWI ? ({
                  key: "Download Field PDF",
                  label: "Download Field PDF",
                  icon: pdfIcon,
                  onClick: handleDownloadPDFClick,
                }) :
                  sw.type !== SWTypes.MFGRC ? ({
                    key: sw.type === SWTypes.MFGSWI ? "Full training PDF" : sw.type === SWTypes.TLMSWI ? "Full PDF" : "Download PDF",
                    label: sw.type === SWTypes.MFGSWI ? "Full training PDF" : sw.type === SWTypes.TLMSWI ? "Full PDF" : "Download PDF",
                    icon: pdfIcon,
                    onClick: handleDownloadPDFClick,
                  }) : undefined,
                (sw.type === SWTypes.SWI) ? ({
                  key: "Download Full Training PDF",
                  label: "Download Full Training PDF",
                  icon: pdfIcon,
                  onClick: () => handleDownloadTrainingPDFClick(false),
                }) : undefined,
                (sw.type === SWTypes.TLMSWI) ? ({
                  key: "Standard PDF",
                  label: "Standard PDF",
                  icon: pdfIcon,
                  onClick: () => handlDownloadReferencePDFClick(),
                }) : undefined,
                (sw.type === SWTypes.MFGSWI) ? ({
                  key: "Reference PDF",
                  label: "Reference PDF",
                  icon: pdfIcon,
                  onClick: () => handlDownloadReferencePDFClick(),
                }) : undefined,
                (sw.type === SWTypes.TLMSWI) ?
                  {
                    key: "Executable PDF",
                    label: "Executable PDF",
                    icon: pdfIcon,
                    onClick: () => handleDownloadTrainingPDFClick(true),
                  } : undefined,
                (sw.type === SWTypes.CL ||
                  sw.type === SWTypes.LCL ||
                  sw.type === SWTypes.SWI ||
                  (sw.type === SWTypes.TLMRC && sw.rcType !== RCTypes.Notice) ||
                  sw.type === SWTypes.TLMSWI ||
                  sw.type === SWTypes.MFGSWI ||
                  sw.type === SWTypes.MFGCL) ? ({
                    key: sw.type === SWTypes.TLMSWI || sw.type === SWTypes.TLMRC ? "Competency - CPA" : "CCQ PDF",
                    label: sw.type === SWTypes.TLMSWI || sw.type === SWTypes.TLMRC ? "Competency - CPA" : "CCQ PDF",
                    icon: pdfIcon,
                    onClick: handleDownloadCCQPDFClick,
                  }) : undefined,
                (((sw.type === SWTypes.TLMRC || sw.type === SWTypes.MFGRC) && sw.version > 0) || sw.isRCUsed) ? ({
                  key: "Where Used",
                  label: "Where Used",
                  icon: searchIcon,
                  toolTip: "It will show you the list of SW's using this Reusable Content",
                  onClick: handleWhereUsedClick,
                }) : undefined,
              ]);
            }

            return (
              <EllipsesDropdown
                label="Actions"
                icon={downArrowIcon}
                itemTextAlign="left"
                items={itemList}
              />
            );
          })()}
        </div>
      </Link>
    </div>
  );
};

export default SWRow;
