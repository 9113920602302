import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from "./components/layout/Layout";
import GlobalComponents from 'components/common/GlobalComponents';
import TCCModal from 'components/common/TCCModal';
import ProcessZipModal from 'components/common/ProcessZipModal';
import ProcessTimeRCModal from 'components/common/ProcessTimeRCModal';
import { TooltipContainer, TooltipArea } from "components/common/Tooltip";

const App: React.FC = () => {
  return (
    <TooltipArea>
      <BrowserRouter>
        <Layout />
        <TCCModal />
        <GlobalComponents />
        <ProcessZipModal />
        <ProcessTimeRCModal />
      </BrowserRouter>
      <TooltipContainer />
    </TooltipArea>
  );
};

export default App;
