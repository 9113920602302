import React, { useEffect } from "react";
import useSelector from "store/useSelector";
import { setPDFData } from "store/swList/swListActions";
import { useDispatch } from "react-redux";

const GeneratePDF: React.FC = () => {
  const {
    swList: {
      getPDFData: {
        getUrlOperation,
        pdfUrl,
      }
    }
  } = useSelector(store => store);

  const dispatch = useDispatch();

  useEffect(() => {
    if (getUrlOperation?.wasSuccessful) {
      window.open(pdfUrl);
      dispatch(
        setPDFData({
          getUrlOperation: undefined,
          pdfUrl: "",
        })
      );       
    }
  }, [getUrlOperation, pdfUrl, dispatch]);

  return null;
}

export default GeneratePDF