import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import "./EditUser.scoped.scss";
import { IManageUserDetail, Roles, UserGroups } from "interfaces/user/UserInterfaces";
import { useDispatch } from "react-redux";
import {
  toggleRole,
  updateUser,
  toggleOrg,
  resetEditUserData,
  addMetaDataItem,
  removeMetaDataItem,
  getUserForEditWithRole,
  setIsRcEditor
} from "store/userManagement/userManagementActions";
import useSelector from "store/useSelector";
import BlockSpinner from "components/common/BlockSpinner";
import { Routes } from "components/routing/Routing";
import {
  loadCountries,
  loadGeoUnits,
  loadOrganizations,
  loadOwningPlants,
  searchEpicEquipment,
  searchEquipment,
  searchProductCenters,
  searchServiceTypes
} from "store/masterData/masterDataActions";
import FlowLayout from "components/layout/FlowLayout";
import MasterDataInput from "components/sw/manage/attributes/MasterDataInput";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { showErrorToast } from "store/toast/toastActions";
import UserGroupIcon from "./UserGroupIcon";
import { isEqual, sortBy } from "lodash";

// When assigning these roles, Save button should be enabled
// even if there are no changes in organizations.
const rolesWithoutOrgs : string[] = [
  Roles.SysAdmin,
  Roles.ApproverCOE,
];

const EditUser: React.FC<RouteComponentProps<{ email: string, role: string, isTechUser: string, isPsdUser: string, isMfgUser: string, isRCEditor: string }>> = (props) => {
  const email1 = window.decodeURIComponent(props.match.params.email);
  const requestorRole = window.decodeURIComponent(props.match.params.role);
  const techUser = window.decodeURIComponent(props.match.params.isTechUser);
  const psdUser = window.decodeURIComponent(props.match.params.isPsdUser);
  const mfgUser = window.decodeURIComponent(props.match.params.isMfgUser);
  const rcUser = window.decodeURIComponent(props.match.params.isRCEditor);
  const [selectedUserGroup, setSelectedUserGroup] = useState(techUser === "S" ? UserGroups.TLM : (mfgUser === "S" ? UserGroups.MFG : UserGroups.PSD));
  const [selectedRole, setSelectedRole] = useState(requestorRole);
  const [selectedTechUser, setSelectedTechUser] = useState(techUser === "S");
  const [selectedPsdUser, setSelectedPsdUser] = useState(psdUser === "S");
  const [selectedMfgUser, setSelectedMfgUser] = useState(mfgUser === "S");
  const [selectedRcEditor, setSelectedRcEditor] = useState(rcUser === "S");
  const {
    userManagement: {
      editUserData: {
        loadOperation,
        saveOperation,
        user,
        isDirty,
        originalUser,
        email,
        name,
      },
    },
    masterData: {
      organizations,
      serviceTypes,
      equipment,
      epicEquipment,
      geoUnits,
      productCenters,
      countries,
      owningPlants,
    }
  } = useSelector(store => store);

  const metadataHasChanged = useMemo(
    () =>
      user &&
      originalUser &&
      !userMetadataIsEqual(user, originalUser, [
        (user) => user.orgs,

        // Make sure to include any metadata that may change in this interface:
        (user) => user.serviceType,
        (user) => user.equipment,
        (user) => user.country,
        (user) => user.productCenter,
        (user) => user.epicEquipment,
        (user) => user.geoUnit,
      ]),
    [user, originalUser]
  );

  const enableSave = useMemo(
    () =>
      user &&
      originalUser &&
      isDirty &&
      // Only enable save if there's been a change in user's metadata
      (metadataHasChanged || rolesWithoutOrgs.includes(selectedRole)),
    [isDirty, user, originalUser, metadataHasChanged, selectedRole]
  );

  const dispatch = useDispatch();
  const pageTitle = (!loadOperation?.isWorking
    && !loadOperation?.errorMessage
    && email1 === email)
    ? `${name} (${email})`
    : email;

  useEffect(() => {
    dispatch(getUserForEditWithRole({
      email: email1,
      role: selectedRole,
      isTechUser: selectedTechUser,
      isMfgUser: selectedMfgUser,
      isPsdUser: selectedPsdUser,
      isRCEditor: requestorRole === 'NoRole' && selectedRole === Roles.Author && selectedUserGroup === UserGroups.MFG
        ? true
        : selectedRcEditor,
    }));

    if (selectedUserGroup === UserGroups.MFG) {
      dispatch(loadOwningPlants({ onlyActive: true }));
    } else {
      dispatch(loadOrganizations({ onlyActive: true }));
    }

    return () => {
      dispatch(resetEditUserData());
    };
  }, [email1, selectedMfgUser, selectedPsdUser, selectedRcEditor, selectedRole, selectedTechUser, selectedUserGroup, dispatch]);

  const save = () => {
    if (user) {
      dispatch(updateUser({
        user: user,
        email: email1,
        name: name,
      }));
    }
  }

  const onUserGroupChanged = (userGroup: UserGroups) => {
    setSelectedUserGroup(userGroup);
    setSelectedTechUser(userGroup === UserGroups.TLM);
    setSelectedPsdUser(userGroup === UserGroups.PSD);
    setSelectedMfgUser(userGroup === UserGroups.MFG);
    dispatch(toggleRole({
      email: email1,
      role: selectedRole,
      isTechUser: userGroup === UserGroups.TLM,
      isPsdUser: userGroup === UserGroups.PSD,
      isMfgUser: userGroup === UserGroups.MFG,
      isRCEditor: requestorRole === 'NoRole' && selectedRole === Roles.Author && userGroup === UserGroups.MFG
        ? true
        : selectedRcEditor,
    }));

    if (userGroup === UserGroups.MFG) {
      dispatch(loadOwningPlants({ onlyActive: true }));
    } else {
      dispatch(loadOrganizations({ onlyActive: true }));
    }
  }

  const onRoleChanged = (role: string) => {
    if (role === selectedRole) {
      // do nothing this role is already selected
      return;
    }

    setSelectedRole(role);
    dispatch(toggleRole({
      email: email1,
      role,
      isTechUser: role === Roles.ApproverCOE ||
        role === Roles.SysAdmin
        ? false
        : selectedTechUser,
      isPsdUser: selectedPsdUser,
      isMfgUser: selectedMfgUser,
      isRCEditor: requestorRole === 'NoRole' && role === Roles.Author && selectedUserGroup === UserGroups.MFG
        ? true
        : selectedRcEditor,
    }));
  }

  const onRCEditorChanged = (isChecked: boolean) => {
    if (selectedRole === Roles.Author) {
      setSelectedRcEditor(isChecked);
      dispatch(toggleRole({
        email: email1,
        role: selectedRole,
        isTechUser: selectedTechUser,
        isPsdUser: selectedPsdUser,
        isMfgUser: selectedMfgUser,
        isRCEditor: isChecked,
      }));
    }
    else {
      setSelectedRcEditor(false);
      dispatch(setIsRcEditor(false));
      dispatch(showErrorToast("RC Editor must be an author."));
    }
  }

  const showMd = (role: string) => {
    return ((selectedUserGroup === UserGroups.TLM ||
      selectedUserGroup === UserGroups.PSD) &&
      role === Roles.ApproverTechContent) ||
      (selectedUserGroup === UserGroups.TLM &&
        role === Roles.TechComm) ||
      (selectedUserGroup === UserGroups.TLM &&
        role === Roles.ApproverOwner)
  }

  const userGroupDescriptions: Record<string, string> = {
    [UserGroups.PSD]: "PSD",
    [UserGroups.TLM]: "TLM",
    [UserGroups.MFG]: "MFG",
  };

  let component: JSX.Element | undefined;
  let footer: JSX.Element | undefined;

  if (loadOperation?.isWorking
    || saveOperation?.isWorking) {
    component = <BlockSpinner />;
  } else if (loadOperation?.errorMessage
    || saveOperation?.errorMessage) {
    component = (
      <label
        className="error"
      >
        {loadOperation?.errorMessage
          || saveOperation?.errorMessage}
      </label>
    );
  } else if (user) {
    component = (
      <div>
        <fieldset className="roles">
          <legend>Group Name</legend>
          <div className="userGroup-container">
            {Object.values(UserGroups)
              .map(r =>
                <div
                  key={"div-" + r}
                  className={`icon ${selectedUserGroup === r ? "selected" : ""}`}
                  onClick={() => onUserGroupChanged(r)}
                  title={userGroupDescriptions[r]}>
                  <UserGroupIcon
                    key={r}
                    userGroup={r}
                  />
                </div>
              )}
          </div>
        </fieldset>
        <fieldset className="roles">
          <legend>Role Type</legend>
          {Array.from(roleDisplays)
            .filter(key => filterRoleByUserGroup(key[0], selectedUserGroup))
            .map(([role, display]) =>
              <button
                key={role}
                className={`primary-button ${user.role
                  .indexOf(display.get(role) as Roles) > -1
                  ? "selected"
                  : ""}`}
                onClick={() => onRoleChanged(role)}
                title={display.get(role)}
              >
                {user.role
                  .indexOf(role as Roles) > -1
                  ? "☒"
                  : "☐"} {role}
              </button>
            )}
        </fieldset>
        {((selectedUserGroup === UserGroups.TLM ||
          selectedUserGroup === UserGroups.MFG) &&
          user.role === Roles.Author) &&
          <fieldset className="roles">
            <legend>Additional Permissions</legend>
            <label className="org btn" title="Can create and update reusable content for the assigned organization/plant">
              <input
                type="checkbox"
                checked={user.isRCEditor}
                onChange={e => onRCEditorChanged(e.target.checked)}
              />
              RC Editor
            </label>
          </fieldset>
        }
        <fieldset className="orgs">
          <legend>{selectedUserGroup === UserGroups.MFG ? "Plants" : "Organizations"}</legend>
          <div className="orgs-holder">
            {(selectedUserGroup === UserGroups.MFG ? owningPlants : organizations).items
              .slice()
              .sort((a, b) => (a.code || "") < (b.code || "") ? -1 : 1)
              .map(org =>
                <label
                  key={org.guid}
                  className="org"
                >
                  <input
                    value={org.guid}
                    type="checkbox"
                    checked={!!user.orgs.find(o => o.guid === org.guid)}
                    onChange={() => dispatch(toggleOrg(org))}
                  />
                  {selectedUserGroup === UserGroups.MFG ? `${org.code} - ${org.value}` : org.code}
                </label>
              )}
          </div>
        </fieldset>
        {showMd(user.role) &&
          <fieldset className="roles">
            <legend>Metadata Assignments</legend>
            {selectedUserGroup === UserGroups.PSD &&
              user.role === Roles.ApproverTechContent &&
              <>
                <div
                  className="input-container"
                >
                  <MasterDataInput
                    label={"Service Types"}
                    isMandatory={false}
                    disabled={false}
                    isAsyncSearch={true}
                    selectedItems={user.serviceType}
                    allowMultiple={true}
                    onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                      metaDataName: "serviceTypes",
                      item,
                    }))}
                    onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                      metaDataName: "serviceTypes",
                      item,
                    }))}
                    loadItems={(searchTerm: string) => dispatch(searchServiceTypes({ searchTerm: searchTerm, onlyActive: true }))}
                    masterDataSection={serviceTypes}
                    itemFormatter={(item: IMasterDataItem) => item.value}
                  />
                </div>
                <div
                  className="input-container"
                >
                  <MasterDataInput
                    label={"Equipment"}
                    isMandatory={false}
                    disabled={false}
                    isAsyncSearch={true}
                    selectedItems={user.equipment}
                    allowMultiple={true}
                    onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                      metaDataName: "equipment",
                      item,
                    }))}
                    onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                      metaDataName: "equipment",
                      item,
                    }))}
                    loadItems={(searchTerm: string) => dispatch(searchEquipment({ searchTerm, onlyActive: true }))}
                    masterDataSection={equipment}
                    itemFormatter={(item: IMasterDataItem) => item.value}
                  />
                </div>
                <div
                  className="input-container"
                >
                  <MasterDataInput
                    label={"Country"}
                    isMandatory={false}
                    disabled={false}
                    selectedItems={user.country}
                    allowMultiple={true}
                    onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                      metaDataName: "countries",
                      item,
                    }))}
                    onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                      metaDataName: "countries",
                      item,
                    }))}
                    loadItems={() => dispatch(loadCountries({ onlyActive: true }))}
                    masterDataSection={countries}
                    itemFormatter={(item: IMasterDataItem) => item.value}
                  />
                </div>
              </>
            }
            {selectedUserGroup === UserGroups.TLM &&
              <>
                <div
                  className="input-container"
                >
                  <MasterDataInput
                    label="Product Center"
                    isMandatory={false}
                    isAsyncSearch={true}
                    disabled={false}
                    selectedItems={user.productCenter}
                    allowMultiple={true}
                    onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                      metaDataName: "productCenters",
                      item,
                    }))}
                    onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                      metaDataName: "productCenters",
                      item,
                    }))}
                    loadItems={(searchTerm: string) => dispatch(searchProductCenters({ searchTerm: searchTerm, onlyActive: true }))}
                    masterDataSection={productCenters}
                    itemFormatter={(item: IMasterDataItem) => item.value}
                  />
                </div>
                {user.role === Roles.ApproverTechContent &&
                  <div
                    className="input-container"
                  >
                    <MasterDataInput
                      label={"EPIC Equipment"}
                      isMandatory={false}
                      disabled={false}
                      isAsyncSearch={true}
                      selectedItems={user.epicEquipment}
                      allowMultiple={true}
                      onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                        metaDataName: "epicEquipment",
                        item,
                      }))}
                      onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                        metaDataName: "epicEquipment",
                        item,
                      }))}
                      loadItems={(searchTerm: string) => dispatch(searchEpicEquipment({ searchTerm, onlyActive: true }))}
                      masterDataSection={epicEquipment}
                      itemFormatter={(item: IMasterDataItem) => item.value}
                    />
                  </div>
                }
                {user.role === Roles.ApproverOwner &&
                  <div
                    className="input-container"
                  >
                    <MasterDataInput
                      label="GeoUnit"
                      isMandatory={false}
                      disabled={false}
                      selectedItems={user.geoUnit}
                      allowMultiple={true}
                      onAddItem={(item: IMasterDataItem) => dispatch(addMetaDataItem({
                        metaDataName: "geoUnits",
                        item,
                      }))}
                      onRemoveItem={(item: IMasterDataItem) => dispatch(removeMetaDataItem({
                        metaDataName: "geoUnits",
                        item,
                      }))}
                      loadItems={() => dispatch(loadGeoUnits({ onlyActive: true }))}
                      masterDataSection={geoUnits}
                      itemFormatter={(item: IMasterDataItem) => item.value}
                    />
                  </div>
                }
              </>
            }
          </fieldset>
        }
      </div>
    );
  }

  footer = (
    <div className="buttons">
      <button
        className="tertiary-button cancel-button"
      >
        <Link
          to={Routes.UserDetail.replace(":email", window.encodeURIComponent(email1))}
        >
          {enableSave ? "Cancel" : "Back"}
        </Link>
      </button>
      <button
        className="primary-button"
        disabled={!enableSave
          || loadOperation?.isWorking
          || saveOperation?.isWorking}
        onClick={save}
      >
        Save
      </button>
    </div>
  );

  return (
    <FlowLayout
      header={(
        <h2 className="page-title">
          {pageTitle}
        </h2>
      )}
      footer={footer}
    >
      {component}
    </FlowLayout>
  );
}

export default EditUser;

const roleSupport: Map<UserGroups, Roles[]> = new Map();
roleSupport.set(UserGroups.PSD, [
  Roles.Author,
  Roles.ApproverTechContent,
  Roles.ApproverCOE,
  Roles.ApproverOwner,
  Roles.OrgAdmin,
  Roles.SysAdmin,
]);
roleSupport.set(UserGroups.TLM, [
  Roles.Author,
  Roles.ApproverTechContent,
  Roles.TechComm,
  Roles.ApproverOwner,
  Roles.OrgAdmin,
  Roles.SysAdmin,
]);
roleSupport.set(UserGroups.MFG, [
  Roles.Author,
  Roles.ApproverOwner,
  Roles.OrgAdmin,
  Roles.SysAdmin,
  Roles.ApproverTechContent,
]);

const roleDisplays: Map<Roles, Map<string, string>> = new Map();
roleDisplays.set(Roles.Author, new Map<string, string>([["Author", "Can create and update SW"]]));
roleDisplays.set(Roles.ApproverTechContent, new Map<string, string>([["ApproverTechContent", "Completes technical content review on the SW for the assigned organization/plant"]]));
roleDisplays.set(Roles.ApproverCOE, new Map<string, string>([["ApproverCOE", " Acts as SW Center of Excellence reviewer"]]));
roleDisplays.set(Roles.ApproverOwner, new Map<string, string>([["ApproverOwner", "Acts as a content owner and reviews SW for the assigned organization/plant"]]));
roleDisplays.set(Roles.OrgAdmin, new Map<string, string>([["OrgAdmin", "Provisions Authors, Owners, Technical Content reviewers, and other Org Admins for the assigned organization/plant"]]));
roleDisplays.set(Roles.SysAdmin, new Map<string, string>([["SysAdmin", "System administrator privileges"]]));
roleDisplays.set(Roles.TechComm, new Map<string, string>([["TechComm", "Acts as the Technical Communications reviewer"]]));

const filterRoleByUserGroup = (key: Roles, userGroup: UserGroups) => {
  const support = roleSupport.get(userGroup);

  const ix = support
    ?.indexOf(key);

  return ix !== undefined && ix > -1;
}

const metadataItemsAreEqual = (a: IMasterDataItem[], b: IMasterDataItem[]) => {
  const aGuids = a.map((i) => i.guid.toUpperCase());
  const bGuids = b.map((i) => i.guid.toUpperCase());

  return isEqual(sortBy(aGuids), sortBy(bGuids));
};

const userMetadataIsEqual = (
  user1: IManageUserDetail,
  user2: IManageUserDetail,
  selectors: ((u: IManageUserDetail) => IMasterDataItem[])[]
) => {
  for (const selector of selectors) {
    if (!metadataItemsAreEqual(selector(user1), selector(user2))) {
      return false;
    }
  }

  return true;
};