import SWMetadata from "components/common/SWMetadata";
import "./MFGAttributes.scoped.scss";
import React, { useState } from "react";
import useSelector from "store/useSelector";
import MetadataTab from "./MetadataTab";
import { ManageSWMetadataTabs } from "store/manageSW/manageSWTypes";
import settingsIcon from "media/icons/dls/settings.svg";
import { DataClassificationTypes } from "interfaces/sw/SWInterfaces";

interface IMFGAttributesProps {
    isDisabled: boolean,
}

const MFGAttributes: React.FC<IMFGAttributesProps> = ({ isDisabled }) => {
    const {
        manageSW: {
            SW,
        },
    } = useSelector(store => store);
    const [currentTab, setCurrentTab] = useState<ManageSWMetadataTabs>(ManageSWMetadataTabs.Mfg);

    const MfgMetadata = () => {
        return (
            <>
                <div className="type-row">
                    <SWMetadata
                        metadataName="dataClassification"
                        isDisabled={isDisabled}
                    />
                    {(SW.dataClassification && SW.dataClassification === DataClassificationTypes.Confidential) && 
                        <SWMetadata
                            metadataName="tcc"
                            isDisabled={isDisabled}
                        />
                    }                  
                    <SWMetadata
                        metadataName="languages"
                        isDisabled={isDisabled}
                    />
                </div>
                <SWMetadata
                    metadataName="title"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="description"
                    isDisabled={isDisabled}
                />
                {(SW.language
                    && SW.language.value !== "English") &&
                    <SWMetadata
                        metadataName="equivalentSWs"
                        isDisabled={isDisabled}
                    />
                }
                <SWMetadata
                    metadataName="owingPlants"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="performingPlants"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="workCenters"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="materials"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="groups"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="groupCounter"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="versionChanges"
                    isDisabled={isDisabled}
                />
            </>
        );
    }

    const TlmMetadata = () => {
        return (
            <>
                <SWMetadata
                    metadataName="performingOrganization"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="productCenters"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="epicEquipment"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="maintenanceTask"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="competencyElements"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="tasks"
                    isDisabled={isDisabled}
                />
                <SWMetadata
                    metadataName="serviceTypes"
                    isDisabled={isDisabled}
                />
            </>
        );
    }

    return (
        <>
            <fieldset>
                <legend>Standard Work Metadata</legend>
                <div className="tabs">
                    <MetadataTab
                        isActive={currentTab === ManageSWMetadataTabs.Mfg}
                        label="MFG"
                        icon={settingsIcon}
                        onClick={() => setCurrentTab(ManageSWMetadataTabs.Mfg)}
                    />
                    <MetadataTab
                        isActive={currentTab === ManageSWMetadataTabs.Tlm}
                        label="TLM"
                        icon={settingsIcon}
                        onClick={() => setCurrentTab(ManageSWMetadataTabs.Tlm)}
                    />
                </div>
            </fieldset>
            <br />
            {
                <div className="inputs">
                    {currentTab === ManageSWMetadataTabs.Mfg &&
                        MfgMetadata()
                    }
                    {currentTab === ManageSWMetadataTabs.Tlm &&
                        TlmMetadata()
                    }
                </div>
            }
        </>
    );
}

export default MFGAttributes;