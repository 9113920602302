import { ISW, StepBooleanMetaPropNames, StepStringMetaPropNames, IImageComponent, IComment, IStepComment, ISWRevisionHistory, ITIMEImageComponent, IGetTimeMediaFileMapping, ImageType, ServiceLevel, Usage, ISiteLocation } from "interfaces/sw/SWInterfaces";
import { IOperation } from "interfaces/operations/Operations"
import { Item } from "react-nestable";
import { IConfidentialSWUser } from "interfaces/user/UserInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";

export interface IManageSWState {
  SW: ISW,
  ReusableCompFromTLMSWI: ISW,
  ReusableCompSortable: Item[],
  reusableCompSelectedType?: ReusableCompType,
  isCreateReusableContentOpen: boolean,
  isCreateRCNoticeOpen: boolean,
  isSearchRCNoticeOpen: boolean,
  rcNoticeStep: string,
  isDirty?: boolean,
  currentTab: ManageSWTabs,
  loadOperation?: IOperation,
  createOperation?: IOperation,
  updateOperation?: IOperation,
  publishOperation?: IOperation,
  fileUploadOperation?: IOperation,
  stepVisibilities: IStepVisibility[],
  noticeVisibilities: INoticeVisibility[],
  scrollToStepGuid?: string,
  commentData: ISWCommentData,
  stepCommentData: ISWStepCommentData,
  currentLocation: IExecutionPreviewLocation | null,
  rcPublishData: IRCPublishData,
  revisionHistories: ISWRevisionHistory[],
  timeImageData: ITimeImageData,
  timeMediaFilesMappingData: IGetTimeMediaFilesMappingData,
  redirectToSearchPageFromTimeRC: boolean,
  submitForReviewFlag: boolean,
  confidentialSWData: IConfidentialSWData,
  siteLocations: ISiteLocation[],
  enableMetersValue: boolean,
  enableFormulaComponentValue: boolean,
  enablePhotoInputComponentMFGValue: boolean,
  enableTimeImageComponentMFGValue: boolean,
  enableNoticeComponentMFGValue: boolean,
  enableTableComponentMFGValue: boolean,
  enableSingleListComponentMFGValue: boolean,
  enableMultiListComponentMFGValue: boolean,
  disableMFGInputComponentValues: string[],
  disableMFGContentComponentValues: string[],
}

export interface IConfidentialSWData {
  changeOperation: IOperation;
  loadOperation: IOperation;
  confidentialEnabledUsers: IConfidentialSWUser[];
}

export enum ReusableCompType {
  Task,
  Step,
  SubStep,
  Notice
}

export interface ISWCommentData {
  comments?: IComment[],
  loadOperation?: IOperation,
}

export interface IRCPublishData {
  dependentSWsCount: number,
  loadOperation?: IOperation,
}

export interface IMergeMenuItem {
  title: string,
  submenu?: IMergeMenuItem[],
}

export interface ISWStepCommentData {
  stepID?: number,
  comments?: IStepComment[],
  loadOperation?: IOperation,
}

export enum ManageSWTabs {
  Attributes,
  Steps,
  RefDocs,
  Comments,
  RevisionHistory,
}

export enum ManageSWMetadataTabs {
  Tlm,
  Psd,
  Mfg,
}

export interface IStepBooleanPayload {
  stepGuid: string,
  value: boolean,
  propName: StepBooleanMetaPropNames,
}

export interface IStepStringPayload {
  stepGuid: string,
  value: string,
  propName: StepStringMetaPropNames,
}

export interface IServiceLevelPayload {
  stepGuid: string,
  value: ServiceLevel,
}
export interface ISiteLocationPayload {
  stepGuid: string,
  value: string,
}

export interface IUsagePayload {
  stepGuid: string,
  value: Usage,
}

export interface IStepSortOrderPayload {
  oldIndex: number,
  newIndex: number,
  parentStepGuid?: string,
}

export interface IRefDocSortOrderPayload {
  oldIndex: number,
  newIndex: number,
}

export interface ISWAttachment {
  swGuid: string,
  swVersion: number,
  file: File,
  type: SWAttachmentTypes,
  refDocGuid?: string,
  imageComponent?: IImageComponent,
  stepGuid?: string,
}

export interface ITimeImageAttachment {
  swGuid: string,
  swVersion: number,
  label: string,
  link: string,
  imageNumber: number,
  type: SWAttachmentTypes,
  timeImageComponent?: ITIMEImageComponent,
  stepGuid?: string,
}

export enum SWAttachmentTypes {
  RefDoc,
  ImageComponent,
  TIMEImageComponent,
}

export interface ITimeImageData {
  imageNumber: number,
  link: string,
  label: string,
  loadOperation?: IOperation,
}

export interface IGetTimeMediaFilesMappingData {
  timeMediaFileMapping: IGetTimeMediaFileMapping[],
  loadTimeImagesOperation?: IOperation
}

export interface IStepComponentImageRequest {
  swGuid: string,
  swVersion: number,
  filename: string,
  imageComponent: IImageComponent,
  stepGuid: string,
  destination: ImageDataDestinations,
}

export interface ITableCellImagesRequest {
  stepGuid: string,
  componentGuid: string,
  rowIndex: number,
  colIndex: number,
  swGuid: string,
  swVersion: number,
  filename: string,
  destination: ImageDataDestinations,
}

export interface IRefDocTableCellImagesRequest {
  refDocGuid: string,
  rowIndex: number,
  colIndex: number,
  swGuid: string,
  swVersion: number,
  filename: string,
  destination: ImageDataDestinations,
}

export interface IStepComponentTIMEImageRequest {
  swGuid: string,
  swVersion: number,
  filename: string,
  timeImageComponent: ITIMEImageComponent,
  stepGuid: string,
  destination: ImageDataDestinations,
}

export enum ImageDataDestinations {
  ManageSW,
  Approval,
}

export interface IStepVisibility {
  stepGuid: string,
  isOpen: boolean,
}

export interface INoticeVisibility {
  noticeGuid: string,
  isOpen: boolean,
}

export interface INoticeImageRequest {
  swGuid: string,
  swVersion: number,
  noticeGuid: string,
  stepGuid?: string,
  filename: string,
  isDirty?: boolean,
  destination: ImageDataDestinations,
  imageType: ImageType,
}

export interface INoticeAttachment {
  swGuid: string,
  swVersion: number,
  file?: File,
  noticeGuid: string,
  stepGuid?: string,
}

export interface INoticeTimeImageAttachment {
  swGuid: string,
  swVersion: number,
  label: string,
  link: string,
  imageNumber: number,
  noticeGuid: string,
  stepGuid?: string,
}

export interface ITableTimeImageAttachment {
  swGuid: string,
  swVersion: number,
  label: string,
  link: string,
  imageNumber: number,
  componentGuid: string,
  stepGuid?: string,
  rowIndex: number,
  colIndex: number,
  value: string,
  altTagValue: string
}

export interface IVideoAttachment {
  swGuid: string,
  swVersion: number,
  file: File,
  stepGuid: string,
  componentGuid: string,
}

export interface IAudioAttachment {
  swGuid: string,
  swVersion: number,
  file: File,
  stepGuid: string,
  componentGuid: string,
}

export interface ITableComponentImage {
  swGuid: string,
  swVersion: number,
  imageObject: ITableImageFile,
  stepGuid?: string,
  componentGuid: string,
  rowIndex: number,
  colIndex: number,
}

export interface ITableImageFile {
  file: File,
  localName: string,
}

export interface IVideoRequest {
  swGuid: string,
  swVersion: number,
  stepGuid: string,
  componentGuid: string,
  filename: string,
  isDirty?: boolean,
  destination: ImageDataDestinations,
}

export interface IAudioRequest {
  swGuid: string,
  swVersion: number,
  stepGuid: string,
  componentGuid: string,
  filename: string,
  isDirty?: boolean,
  destination: ImageDataDestinations,
}

export interface IExecutionPreviewLocation {
  stepGuid: string,
}

export interface IOOSPayload {
  stepGuid: string,
  item?: IMasterDataItem,
  isAdding?: boolean
}