import { SWTypes, IAllowedRCControlsConfig, StepTypes } from "interfaces/sw/SWInterfaces";
import React, { useEffect, useState } from "react";
import searchIcon from "media/icons/dls/search.svg";
import newIcon from "media/icons/dls/new-doc.svg";
import delinkIcon from "media/icons/dls/delink.svg";
import DeleteIcon from "media/icons/dls/delete.svg";
import commentIcon from "media/icons/dls/comments.svg";
import availabilityIcon from "media/icons/dls/availability.svg";
import DuplicateIcon from "media/icons/dls/duplicate.svg";
import "./Collapsible.scoped.scss";
import { useDispatch } from "react-redux";
import { createRCDraft, getRCWhereUsedItems } from "store/swList/swListActions";
import { ImageDataDestinations } from "store/manageSW/manageSWTypes";
import ToggleButton from "./ToggleButton";
import useSelector from "store/useSelector";
import { Roles } from "interfaces/user/UserInterfaces";

interface ICollapsibleProps {
  header: string,
  maxHeight?: number,
  isInitiallyOpen?: boolean,
  isExternallyControlled?: boolean,
  externalIsOpen?: boolean,
  allowDuplicate?: boolean,
  stepGuid?: string,
  hasUnresolvedComments?: boolean,
  swType?: SWTypes,
  rcID?: number | undefined,
  isRCStep?: boolean,
  isRCContainer?: boolean,
  isTask?: boolean,
  isRCNotice?: boolean,
  externalOnToggle?(isOpen: boolean): void,
  clickDeleteStep?: any,
  clickDeleteNotice?: any,
  clickRCDelinkStep?: any,
  clickRCDelinkNotice?: any,
  clickDuplicateSteps?: any,
  clickPutComment?: any,
  draftPage?: boolean,
  stepId?: number | undefined,
  imageDataDestination?: ImageDataDestinations,
  isStepDisabled: boolean,
  isNoticeDisabled?: boolean
  disableRcTaskStep(disabled: boolean): void,
  disableRcNotice?(disabled: boolean): void,
  hideRCControls?: boolean,
  hideControls?: boolean
  allowedRCControlsConfig?: IAllowedRCControlsConfig | undefined,
  stepType?: StepTypes,
  isStale?: boolean,
  children: any,
}

const Collapsible: React.FC<ICollapsibleProps> = ({
  isInitiallyOpen,
  header,
  children,
  maxHeight,
  isExternallyControlled,
  externalIsOpen,
  hasUnresolvedComments,
  swType,
  rcID,
  isRCStep,
  isRCContainer,
  isTask,
  externalOnToggle,
  clickDeleteStep,
  clickDeleteNotice,
  clickRCDelinkStep,
  clickRCDelinkNotice,
  clickDuplicateSteps,
  clickPutComment,
  draftPage,
  stepId,
  imageDataDestination,
  isStepDisabled,
  isNoticeDisabled,
  disableRcTaskStep,
  disableRcNotice,
  hideRCControls = false,
  hideControls = false,
  allowedRCControlsConfig,
  isRCNotice,
  isStale = false
}) => {
  const dispatch = useDispatch();
  const [internalIsOpen, internalSetIsOpen] = useState(!!isInitiallyOpen);
  useEffect(() => {
    if (!isInitiallyOpen) {
      internalSetIsOpen(false);
    }
  }, [isInitiallyOpen]);

  const isStepMetadata = header === "Step Metadata";
  const isNotice = header === "Notices";
  const isPPE = header === "PPE";
  const isApprovalPage = imageDataDestination === ImageDataDestinations.Approval;
  const {
    manageSW: {
      SW: {
        activeApprovalLevel,
        version,
      }
    },
    auth: {
      currentUser,
    }
  } = useSelector(store => store);

  let isOpen = isExternallyControlled
    ? (externalIsOpen || false)
    : internalIsOpen;

  const setIsOpen = (isOpen: boolean) => {
    if (isExternallyControlled
      && externalOnToggle) {
      externalOnToggle(isOpen);
    } else if (!isExternallyControlled) {
      internalSetIsOpen(isOpen);
    }
  }

  const dispatchGetRCWhereUsedItems = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(getRCWhereUsedItems({ rcID: rcID ? rcID : 0 }))
  }

  const dispatchGetRCDraft = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(createRCDraft({ rcID: rcID ? rcID : 0 }));
  }

  function collapsibleHeaderStyle() {
    let style: string = "header";

    if (isRCStep || isRCContainer || isRCNotice) {
      if (isStepDisabled || isNoticeDisabled) {
        style = style + " rcDisabled";
      }
      else {
        style = style + " rc";
      }
    }

    return style;
  }

  let canTechCommDisableRc = (swType === SWTypes.TLMSWI &&
    currentUser.roles.find(x => x === Roles.TechComm)?.length &&
    version === 0 &&
    (isRCStep || isRCNotice) &&
    !isRCContainer &&
    !isApprovalPage &&
    draftPage);

  let canDisableRc = (swType &&
    allowedRCControlsConfig &&
    (allowedRCControlsConfig?.canDisableStep || allowedRCControlsConfig?.canDisableNotice) &&
    version === 0 &&
    (isRCStep || isRCNotice) &&
    !isApprovalPage &&
    draftPage &&
    activeApprovalLevel === undefined);

  let allowedRCControl = (link: string) => (swType &&
    allowedRCControlsConfig &&
    allowedRCControlsConfig[link as keyof IAllowedRCControlsConfig] === true &&
    isRCContainer &&
    !isStepMetadata &&
    !isNotice &&
    !isPPE &&
    !isApprovalPage
  );

  function getDeleteButtonText() {
    if (isRCContainer) {
      return "Delete Reusable Content";
    }
    else if (isTask) {
      return "Delete Task";
    }
    else {
      return "Delete Step";
    }
  }

  return (
    <div
      className={`collapsible ${isOpen ? "open" : "closed"}`}
    >
      <div
        className={collapsibleHeaderStyle()}
        onClick={() => setIsOpen(!isOpen)}
      >
        <label className={hasUnresolvedComments ? "unresolvedComments" : undefined}>{header}</label>

        {isRCContainer && isStale && (
          <img
            src={availabilityIcon}
            alt="Stale Reusable Content"
            title="This Reusable Content is not the latest published version."
            className="rc-button icon-small stale-rc-icon"
          />
        )}

        {stepId &&
          draftPage &&
          (!isStepMetadata &&
            !isNotice &&
            !isPPE) &&
          < img
            src={commentIcon}
            alt="Comments"
            title={`${(swType === SWTypes.TLMSWI || swType === SWTypes.TLMRC || swType === SWTypes.MFGRC || swType === SWTypes.MFGSWI) && isTask === true ? "Task" : "Step"}  Comments`}
            className={`rc-button step-comment${hasUnresolvedComments ? "-full" : ""}  icon-small`}
            onClick={clickPutComment} />
        }

        {(!internalIsOpen && !isRCContainer) &&
          (!isStepMetadata &&
            !isNotice &&
            !isPPE
          ) &&
          !isApprovalPage &&
          !hideControls &&
          !isRCNotice &&
          < img
            src={DuplicateIcon}
            alt={`Duplicate ${isTask ? "Task" : "Step"}`}
            title={`Duplicate ${isTask ? "Task" : "Step"}`}
            className="rc-button duplicate-button icon-small"
            onClick={clickDuplicateSteps} />
        }

        {!hideRCControls && (
          <>
            {allowedRCControl("createDraft") && (
              <img
                src={newIcon}
                alt="Create Draft"
                title="Create Draft"
                className={`rc-button icon-small`}
                onClick={(e) => dispatchGetRCDraft(e)}
              />
            )}

            {allowedRCControl("whereUsed") && (
              <img
                src={searchIcon}
                alt="Where Used"
                title="Where Used"
                className={`where-used-button rc-button icon-small`}
                onClick={(e) => dispatchGetRCWhereUsedItems(e)}
              />
            )}

            {allowedRCControl("delink") &&
              clickRCDelinkStep && (
                <img
                  src={delinkIcon}
                  alt={`Delink Reusable Content`}
                  title={`Delink Reusable Content`}
                  className="rc-button delink icon-small"
                  onClick={clickRCDelinkStep}
                />
              )}

            {allowedRCControl("delink") &&
              clickRCDelinkNotice && (
                <img
                  src={delinkIcon}
                  alt={`Delink Reusable Content`}
                  title={`Delink Reusable Content`}
                  className="rc-button delink icon-small"
                  onClick={clickRCDelinkNotice}
                />
              )}

            {(!internalIsOpen || isRCStep || isRCContainer) &&
              !isStepMetadata &&
              !isNotice &&
              !isPPE &&
              !isApprovalPage &&
              !hideControls &&
              (
                isRCNotice ?
                  <img
                    src={DeleteIcon}
                    alt={getDeleteButtonText()}
                    title={getDeleteButtonText()}
                    className="rc-button delete-button icon-small"
                    onClick={clickDeleteNotice}
                  />
                  :
                  <img
                    src={DeleteIcon}
                    alt={getDeleteButtonText()}
                    title={getDeleteButtonText()}
                    className="rc-button delete-button icon-small"
                    onClick={clickDeleteStep}
                  />
              )}
          </>
        )}

        {((canDisableRc ||
          canTechCommDisableRc)
          && isRCStep) &&
          <ToggleButton
            checked={!isStepDisabled}
            onChange={(e) => disableRcTaskStep(e)}
            tooltip={"Disable RC Task/Step"}
          />
        }
        {(canDisableRc && isRCNotice) &&
          <ToggleButton
            checked={!isNoticeDisabled}
            onChange={(e) => disableRcNotice !== undefined ? disableRcNotice(e) : undefined}
            tooltip={"Disable RC Notice"}
          />
        }

      </div>
      {isOpen &&
        <div
          className={`${isRCContainer ? "rc-body" : "body"}`}
          style={maxHeight ? { maxHeight } : undefined}
        >
          {children}
        </div>
      }
    </div>
  )
};

export default Collapsible;